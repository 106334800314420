import { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import img1 from "../../assets/careGiverImages/Frame 427319332.svg";
import img2 from "../../assets/careGiverImages/Frame 427319333.svg";
import img3 from "../../assets/careGiverImages/Frame 427319334.svg";
import img4 from "../../assets/careGiverImages/Frame 427319336.svg";
import img5 from "../../assets/careGiverImages/Group 35638.svg";
import img6 from "../../assets/careGiverImages/Frame 427319335.svg";
import CareGiverServicePage from "../../components/CareGiverServicePage/CareGiverServicePage";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img7 from "../../assets/careGiverImages/Box 1.png";
import img8 from "../../assets/careGiverImages/Box 2.png";
import img9 from "../../assets/careGiverImages/Box 3.png";
import img10 from "../../assets/careGiverImages/Box 4.png";
import img11 from "../../assets/careGiverImages/Box 5.png";
import img12 from "../../assets/careGiverImages/Box 6.png";
import img13 from "../../assets/careGiverImages/Rectangle 2574.png";
import img14 from "../../assets/careGiverImages/Rectangle 2574 (1).png";
import img15 from "../../assets/careGiverImages/Rectangle 2574 (2).png";
import img16 from "../../assets/careGiverImages/Rectangle 2574 (3).png";
import img17 from "../../assets/careGiverImages/Rectangle 2574 (4).png";
import img18 from "../../assets/careGiverImages/Rectangle 2574 (5).png";
import img19 from "../../assets/careGiverImages/mobile/Frame 427319372.png";
import img20 from "../../assets/careGiverImages/mobile/Frame 427319373.png";
import img21 from "../../assets/careGiverImages/mobile/Frame 427319374.png";
import img22 from "../../assets/careGiverImages/mobile/Frame 427319373.png";
import img23 from "../../assets/careGiverImages/mobile/Frame 427319375.png";
import img24 from "../../assets/careGiverImages/mobile/Frame 427319376.png";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import CareModel from "../../shared/components/CareModel/CareModel";
import Testimonials from "../../components/Testimonials/Testimonials";

const CareGiverServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "7";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr1 = [
    {
      arrImg: img1,
      arrMobImg: img19,
      head: "Quick hire",
      text: "Swift time to-hire ensures prompt assistance, matching you with qualified caregivers efficiently in receiving crucial support.",
    },
    {
      arrImg: img2,
      arrMobImg: img20,
      head: "Comprehensive Screening",
      text: "Rigorous screening process assures trustworthy caregivers ensuring safety and reliability for your loved ones.",
    },

    {
      arrImg: img3,
      arrMobImg: img21,
      head: "Expertise of caregivers",
      text: "Caregivers possess specialised expertise, skils and training to address diverse needs and provide quality care.",
    },
    {
      arrImg: img4,
      arrMobImg: img23,
      head: "Emergency Preparedness",
      text: "This service ensure caregivers are trained in first aid and basic life support(BLS), ready to respond in effectively in critical situations.",
    },
    {
      arrImg: img5,
      arrMobImg: img21,
      head: "Ease of Replacement",
      text: "Hassle-free replacement, hence seamless continuity of care, with prompt replacement of caregivers when needed.",
    },
    {
      arrImg: img6,
      arrMobImg: img24,
      head: "24/7 Health Support",
      text: "Access 24/7 support from our health manager, ensuring continuous assistance, guidance, and care, enhancing customer satisfaction.",
    },
  ];

  const bgArr = [
    {
      head: "IV/Immunization Care",
      textArr: [
        "Skilled professionals",
        "Intravenous treatments and immunizations",
        "Accurate dosages",
        "Patient comfort",
      ],
      bgImage1: img13,
      bgImage: img7,
      price: 499,
    },
    {
      head: "Wound Care",
      textArr: [
        "Trained caregivers",
        "Wound care",
        "Promotes healing",
        "Preventing any problems or complications",
      ],
      bgImage1: img14,
      bgImage: img8,
      price: 499,
    },
    {
      head: "Respite Care",
      textArr: [
        "Dedicated caregivers",
        "Continuous support",
        "Supervision for individuals with special needs",
        "Disability care",
      ],
      bgImage1: img15,
      bgImage: img9,
      price: 999,
    },
    {
      head: "Transportation Care",
      textArr: [
        "Reliable caregivers",
        "Safe & timely transportation",
        "Medical appointments, errands, or outings",
        "Comfort and assistance throughout",
      ],
      bgImage1: img16,
      bgImage: img10,
      price: 1199,
    },
    {
      head: "Day/Night Care (12 hours)",
      textArr: [
        "Supportive caregivers",
        "Round-the-clock assistance",
        "Comfort and Safety",
        "12 hour Supervision",
      ],
      bgImage1: img17,
      bgImage: img11,
      price: 999,
    },
    {
      head: "Full day care (24 hours)",
      textArr: [
        "Compassionate caregivers",
        "Comprehensive care ",
        "Support around the clock",
        "Addressing daily needs",
      ],
      bgImage1: img18,
      bgImage: img12,
      price: 1199,
    },
  ];

  const dropDownArr = [
    {
      label: "What types of caregivers do you offer?",
      text: "We provide Attendants for daily activities, Nurses including ANMs and senior nurses proficient in basic medical tasks like medication administration and equipment handling, and ICU Trained Nurses specialised in intensive care management such as ventilation and critical medication administration.",
    },
    {
      label: "How do you ensure caregiver quality?",
      text: "Through Heka Assurance, we maintain rigorous standards in qualification and skills to build trust and provide choices for our clients. Regular audits of all caregivers further ensure consistent quality.",
    },
    {
      label: "How are caregivers selected and trained?",
      text: "Caregivers undergo stringent selection processes including interviews and police background checks. They receive comprehensive in-office training covering emergency response, medical procedures, and non-medical care skills. We also provide them with relevant qualification certificates.",
    },
    {
      label: "What types of care services are offered?",
      text: "Our caregivers provide a wide range of services including transportation, respite care, immunisation assistance, IV therapy, wound care, medication management, vital signs monitoring, post-surgical care, tracheostomy care, and catheterization care, among others.",
    },
    {
      label: "What does specialised home care include?",
      text: "Specialised care tailored for conditions like heart disease, kidney disease, dementia, and other serious illnesses includes health assessments, education, prescription management, wound care, physical therapy, emotional support, and healthcare navigation assistance.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <CareGiverServicePage
        isMobile={selectIsMobile(state)}
        arr1={arr1}
        bgArr={bgArr}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <CareModel isMobile={selectIsMobile(state)} />

      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default CareGiverServiceContainer;
