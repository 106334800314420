import {
  FETCH_LABELS,
  SET_CITY,
  SET_LABELS,
  SET_MOBILE_DEVICE,
} from "./Global.constants";

export const fetchLabelsAction = () => ({
  type: FETCH_LABELS,
});

export const setLabels = (payload) => ({
  type: SET_LABELS,
  payload,
});

export const setMobileDevice = (payload) => ({
  type: SET_MOBILE_DEVICE,
  payload,
});

export const setCity = (payload) => ({
  type: SET_CITY,
  payload,
});
