import React from "react";
import ElderlyPlans from "../../shared/components/ElderlyCarePlansBasePage/ElderlyPlans";
import images from "../../commons/ImagesExport";

function ComprehensiveCare() {
  return (
    <ElderlyPlans
    image= {images.EldPlanImage}
      title="Comprehensive Care"
      features="These plans are designed in consultation with experts to support, motivate and keep you healthy. The plan mentioned is on a monthly basis."
    />
  );
}

export default ComprehensiveCare;
