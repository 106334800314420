import React from "react";
import img2 from "./pregnancy BG Image.svg";
import "./PregnancyCareServicePage.css";
import whyChooseUsImg from "./Why Choose Us 1.svg";
import whyChooseUsImg1 from "../../assets/elderlyCareImages/why choose us final mobile.svg";
import mobileImgMain from "../../assets/pregnancyCareImgages/Frame 427319564.jpg";
const PregnancyCareServicePage = (props) => {
  const { arr, isMobile, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Pregnancy Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our comprehensive pregnancy care <br /> supports
              maternal and fetal health with medical and <br /> emotional
              support throughout.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Pregnancy Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our comprehensive pregnancy care supports maternal
              and <br />
              fetal health with medical and emotional support throughout.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img
        src={isMobile ? whyChooseUsImg1 : whyChooseUsImg}
        style={
          isMobile
            ? { display: "block", margin: "auto" }
            : { width: "100%", margin: "6rem 0rem" }
        }
      />

      <h4 className="homePageContainer4-headMain">Our Services</h4>

      <div className="pregnancyCarePageContainer3">
        {arr.map((item, index) => {
          return (
            <div key={index} className="pregnancyCarePageContainer3-card">
              <img
                src={item.arrImg}
                className="pregnancyCarePageContainer3-cardImg"
              />
              <div className="pregnancyCarePageContainer3-cardDiv2">
                <img
                  src={isMobile ? item.mobileBg : item.bgImage}
                  style={{
                    position: "absolute",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
                <h5 className="pregnancyCarePageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="pregnancyCarePageContainer3-cardText1">
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PregnancyCareServicePage;
