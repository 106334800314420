import React, { useContext, useEffect } from "react";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import AboutUsPage from "../../components/AboutUsPage/AboutUsPage";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
const AboutUsContainer = () => {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropDownArr = [
    {
      label: "What services does Heka Health offer?",
      text: "We provide expert home healthcare administered by trained professionals, catering to elderly, post-operative, pregnancy care, and more.",
    },
    {
      label: "How does Heka Health operate?",
      text: "Our approach involves patient-engaged, personalized care enhanced by progressive technology, ensuring comfort, empathy, and comprehensive medical expertise for optimal outcomes.",
    },
    {
      label: "How can I access your services?",
      text: "To start your journey towards better health, please call us at 8010-711-010. Our team will assist you through every step of the process.",
    },
    {
      label: "Where is Heka Health located?",
      text: "Our office is situated at Galleria Tower, DLF Phase 4, Gurugram, Haryana (122002).",
    },
    {
      label: "In which cities does Heka Health operate?",
      text: "We offer Caregiver at Home and Safety Training services across India. Additional services are available specifically in Delhi/NCR.",
    },
  ];

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <AboutUsPage isMobile={selectIsMobile(state)} />
      <MobileFloatingButton isMobile={selectIsMobile(state)} />
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default AboutUsContainer;
