import React, { useContext, useEffect, useState } from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { APIGetRequest, APIPostRequest } from "../../config/HttpClient";
import OurTeam from "../../components/OurTeamPage/OurTeam";
import images from "../../commons/ImagesExport";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

const OurTeamPageContainer = () => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    setIsFetching(true);
    APIGetRequest(
      "/fetchAllAbouts.php",
      (response) => {
        console.log("response", response);
        setTeamData(response);
        setIsFetching(false);
      },
      (err) => {
        setIsFetching(false);
        if (err.code === "404") {
          console.log("error 404", err);
        }
        console.log( err);
      },
    );
  }, []);

  return (
    <>
      {isFetching ? (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader}  />
        </Backdrop>
      ) : (
        <OurTeam teamData={teamData} />
      )}
    </>
  );
};

export default OurTeamPageContainer;
