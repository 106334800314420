import React from "react";
import HeaderContainer from "../../../shared/containers/HeaderContainer/HeaderContainer";
import images from "../../../commons/ImagesExport";
import OurPartners from "../../../shared/components/Parteners/OurPartners";
import NewsRoom from "../../../shared/components/NewsRoom/NewsRoom";
import Testimonials from "../../../shared/components/Testimonials/Testimonials";
import Footer from "../../../shared/components/Footer/Footer";
import "./Physiotherapy.css";
import CallBackPopUp from "../../../shared/components/CallBackPopUpPage/CallBackPopUp";
import { APIPostRequest } from "../../../config/HttpClient";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

function Physiotherapy() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const classes = useStyles();
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  const handleSubmit = () => {
    setIsFetching(true);
    const data = {
      name: document.getElementById("callPopUp").querySelectorAll("input")[0]
        .value,
      email: document.getElementById("callPopUp").querySelectorAll("input")[1]
        .value,
      mobileNumber: document
        .getElementById("callPopUp")
        .querySelectorAll("input")[2].value,
      categoryName: "Elderly Care",
      subCategoryName: "Physiotherapy",
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    APIPostRequest(
      "/addCallSchedule.php",
      formdata,
      (res) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Your request has been submitted successfully");
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
      }
    );
  };
  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  return (
    <>
      <HeaderContainer />
      {isFetching && (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader}  />
        </Backdrop>
      )}
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {window.scrollTo(0, 0)}
      <section className="parentalcontent">
        <div className="container">
          <div className="row paren-row">
            <div className="col-md-6">
              <div className="left-parental">
                <h4 className="parental-heading">Physiotherapy</h4>
                <ul className="parental-list">
                  {[
                    "Helps regaining your stability and mobility",
                    "Helps with pain mainagement",
                    "Geratic Reconditioning",
                    "After Joint Replacement physiotheraphy",
                    "Improves improves your balance and coordination.",
                  ].map((item, index) => {
                    return (
                      <li key={index} className="flex items-center">
                        <span>
                          <img
                            className="sign-img"
                            src={images.checkMark}
                            alt=""
                          />
                        </span>{" "}
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-parental">
                <div className="req-call-div" id="callPopUp">
                  <h6 className="call-back-heading">Let's Talk</h6>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        required
                        type="text"
                        placeholder="Name"
                        className="call-input"
                        minLength={3}
                        maxLength={26}
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                        placeholder="Email"
                        className="call-input"
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="[0-9]{10}"
                        placeholder="Input Phone Number"
                        minLength={10}
                        maxLength={10}
                        className="call-input"
                      />
                    </div>
                    <button type="submit" className="callbackbtn">
                      Request a Call Back
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pagesupport">
        <div className="container">
          <div className=" items-center row">
            <div className="col-md-3">
              <div className="w-full left-spport">
                <img
                  src={images.physioSupport}
                  className="xs:m-auto md:m-0 w-[60%] h-full"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="right-spport">
                <h4 class="elderphysio">Physiotherapy for Elders</h4>
                <p>
                  Geriatric physiotherapy addresses issues concerning the
                  elderly. Our metabolism, physical movements, and strength
                  decline as we age, resulting in a variety of problems.
                  Geriatric physiotherapy is concerned with the health,
                  mobility, and strength of the elderly as they age. This type
                  of physiotherapy is also beneficial in the treatment of
                  several health conditions in the elderly, such as arthritis
                  and Alzheimer's disease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <h2 className="main-heading">GERIATRIC RECONDITIONING</h2>
        <p className="safety-para">
          The changes associated with old age comprise both physical changes and
          mental changes.
        </p>
        <p className="safety-para">
          There is often a general physical decline, and people become less
          active. Old age can cause, amongst other things:
        </p>
        <ul className="roadsafety-list">
          <li>Impaired muscle strength/muscle power of lower extremities</li>
          <li>Impaired balance/postural control</li>
          <li>Impaired gait</li>
          <li>Impaired vision</li>
          <li>Fall-associated medications/ number of medications</li>
          <li>Impaired cognitive functions</li>
        </ul>
        <p className="safety-para">
          Our therapist design the program to bridge the gaps to maintain
          flexibility and correct postures for daily functions and activities.
          Treating diseases and preventing or minimising disease-related and
          age-related functional decline and reinstate functional independence
          after acute illnesses is also important. Strength training helps
          significantly reduce the signs and symptoms of many diseases and
          chronic conditions in the following ways:{" "}
        </p>
      </div>
      <div className="container">
        <div className="physiocircle-main">
          {[
            {
              image: images.physio1,
              text: "Arthritis—Reduces pain and stiffness, and increases strength and flexibility.",
            },
            {
              image: images.physio2,
              text: "Diabetes—Improves glycemic control.",
            },
            {
              image: images.physio3,
              text: "Osteoporosis—Builds bone density and reduces risk for falls.",
            },
            {
              image: images.physio4,
              text: "Heart disease—Reduces cardiovascular risk by improving lipid profile and overall fitness.",
            },
          ].map((item, index) => {
            return (
              <div key={index} className="engage-main">
                <div className="engageimg-div">
                  <img src={item.image} alt="" />
                </div>
                <h6>{item.text}</h6>
              </div>
            );
          })}
        </div>

        <div className="physiocircle-main-1">
          {[
            {
              image: images.physio5,
              text: "Obesity—Increases metabolism, which helps burn more calories and helps with long-term weight control.",
            },
            {
              image: images.physio6,
              text: "Back pain—Strengthens back and abdominal muscles to reduce stress on the spine",
            },
            {
              image: images.physio7,
              text: "Reduces Depression and boosts self-confidence.",
            },
          ].map((item, index) => {
            return (
              <div key={index} className="engage-main">
                <div className="engageimg-div">
                  <img src={item.image} alt="" />
                </div>
                <h6>{item.text}</h6>
              </div>
            );
          })}
        </div>
      </div>
      <section className="booknewawssion">
        <div className="container">
          <p className="sessioncall-para">What are you waiting for?</p>
          <h4 className="sessioncall-heading">
            Talk to our experts today to enrol your loved ones
          </h4>
          <button
            onClick={() => setDisplayPopUp(true)}
            className="sessioncall-btn"
          >
            Schedule A Call
          </button>
        </div>
      </section>
      <OurPartners />
      <NewsRoom
        images={[
          images.ElderNews1,
          images.ElderNews2,
          images.ElderNews3,
          images.ElderNews4,
          images.ElderNews1,
        ]}
      />
      <Testimonials />
      <Footer />
      <CallBackPopUp
        displayPopUp={displayPopUp}
        setDisplayPopUp={setDisplayPopUp}
      />
    </>
  );
}

export default Physiotherapy;
