import { useContext } from "react";
// import "./CareGiverThankYou.css";
import logo1 from "../AdEnquiry/Frame 427320487.svg";
import logo from "../AdEnquiry/Main Logo Right_Tagline.svg";
import { Context } from "../../../store/Store";
import { selectIsMobile } from "../../../store/global/Global.selectors";
import tick from "./tick.svg";
import { useHistory } from "react-router-dom";

const PhysioTherapyThankYou = () => {
  const { state } = useContext(Context);
  const isMobile = selectIsMobile(state);
  const history = useHistory();

  return (
    <>
      <div className="cgT">
        <img
          src={isMobile ? logo1 : logo1}
          style={isMobile ? {} : { width: "200px", height: "116px" }}
        />

        <img
          src={tick}
          style={
            isMobile
              ? { height: "85px", width: "85px", margin: "120px 0px 16px 0px" }
              : {
                  height: "130px",
                  width: "130px",
                  margin: "72px 0px 32px 0px",
                }
          }
        />

        <h1 className="cgTHead">THANK YOU!</h1>
        {isMobile ? (
          <p className="cgTText">
            We have received your query. Someone from <br />
            our team will get back to you shortly.
          </p>
        ) : (
          <p className="cgTText">
            We have received your query. Someone from our team will <br /> get
            back to you shortly.
          </p>
        )}

        <button onClick={() => history.push("/")} className="cgTBtn">
          Visit our Website!
        </button>
      </div>
    </>
  );
};

export default PhysioTherapyThankYou;
