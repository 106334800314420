import React, { useContext, useEffect, useState } from "react";

import { APIGetRequest, APIPostRequest } from "../../config/HttpClient";
import GalleryPage from "../../components/GalleryPage/GalleryPage";



const GalleryPageContainer = () => {
 
  const [isFetching, setIsFetching] = useState(false);
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    setIsFetching(true);
    APIGetRequest(
      "/fetchAllGalleries.php",
      (response) => {
        setGalleryData(response.galleries);
        setTimeout(() => {
          setIsFetching(false);
        }, 4000);
      },
      (err) => {
        setIsFetching(false);
        if (err.code === "404") {
          console.log("error 404", err);
        }
        console.log( err);
      },
      {}
    );
  }, []);

  return (
    <>
        <GalleryPage galleryData={galleryData} setIsFetching={setIsFetching} isFetching={isFetching} />
    </>
  );
};

export default GalleryPageContainer;
