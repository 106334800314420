import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import { Context } from "../../../store/Store";
import { selectIsMobile } from "../../../store/global/Global.selectors";
import {
  ADD_ENQUIRY,
  CUSTOMER_LOGIN,
  DOWNLOAD_BROCHURE,
  VERIFY_OTP,
} from "./ContactUsContainer.constants";
import { APIPostRequest } from "../../../config/HttpClient";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const ContactUsContainer = (props) => {
  const { pageIndex } = props;
  const { state, dispatch } = useContext(Context);
  const slotRef = useRef();
  const calendarRef = useRef();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showDateDialog, setShowDateDialog] = useState(false);
  const [showTimeDialog, setShowTimeDialog] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showFailureSnackbar, setShowFailureSnackbar] = useState(false);

  const [dateToSend, setDateToSend] = useState("");

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  console.log("PAGE INDEX", pageIndex);

  const handleDateDialog = () => {
    setShowDateDialog(true);
  };

  const handleTimeDialog = () => {
    setShowTimeDialog(true);
  };

  const handleDateChange = (newDate) => {
    const date = new Date(newDate);
    const setNewData = date.toISOString().substring(0, 10)
    setDateToSend(setNewData)
    const formattedDate = dayjs(newDate);
    setSelectedDate(formattedDate);
    setShowDateDialog(false);
  };

  const timeSlot = [
    "9 - 12 PM",
    "12 - 3 PM",
    "3 - 6 PM",
    "6 - 9 PM",
  ];

  const [selectedSlot, setSelectedSlot] = useState("9 - 12 PM");
  const [mobileErr, setMobileError] = useState("");

  const handleSlot = (i) => {
    setSelectedSlot(i);
    setShowTimeDialog(false);
  };

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const handleForm = () => {};

  const handleClickOutside = (event) => {
    if (slotRef.current && !slotRef.current.contains(event.target)) {
      setShowTimeDialog(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside1 = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowDateDialog(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [timeLeft, setTimeLeft] = useState(90);

  const handleSendOtp = () => {
    var formData = new FormData();
    formData.append("data", `{"mobileNumber":"${mobileNumber}"}`);
    APIPostRequest(
      CUSTOMER_LOGIN,
      formData,
      (response) => {
        console.log("Customer logged in", response);
        setCustomerId(response.customerId);
        setShowDialog(true);
        setTimeLeft(90); // Reset timer
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => {
            if (prevTime === 0) {
              clearInterval(timer);
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, value) => {
    if (!isNaN(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const verifyOTP = () => {
    const enteredOTP = otp.join("");
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "otp": ${enteredOTP}}`
    );
    APIPostRequest(
      VERIFY_OTP,
      formData,
      (response) => {
        console.log("OTP VERIFIED RESPONSE", response);
        setShowSuccessMsg(true);
      },
      (error) => {
        console.log(error);
        console.log("Incorrect OTP, please try again.");
      }
    );
  };

  const handleSendEnquiry = () => {
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "name": "${name}", "mobileNumber": "${mobileNumber}", "lookingFor": "${pageIndex}", "enquiryFrom": "${
        selectIsMobile(state) ? "Mobile-web" : "Website"
      }","preferredDate": "${dateToSend}","preferredTime": "${selectedSlot}"}`
    );
    APIPostRequest(
      ADD_ENQUIRY,
      formData,
      (response) => {
        console.log("OTP VERIFIED RESPONSE", response);
        setShowSuccessSnackbar(true);
        setShow(false);
        setMobileNumber(" ");
        setName(" ");
      },
      (error) => {
        console.log(error);
        setShowFailureSnackbar(true);
      }
    );
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleProceed = () => {
    setShowDialog(false);
    setShow(true);
  };

  const handleDownloadBrochure = (pageIndex) => {
    if (
      pageIndex === "6" ||
      pageIndex === "7" ||
      pageIndex === "8" ||
      pageIndex === "9" ||
      pageIndex === "10"
    ) {
      pageIndex = "3";
    }
    var formData = new FormData();
    formData.append("data", `{"brochureFlag":${pageIndex}}`);
    APIPostRequest(
      DOWNLOAD_BROCHURE,
      formData,
      (response) => {
        window.open(response?.brochureLink, "_blank");
        console.log("DPOWNLOAD BROCHURE RESPONSE", response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <Snackbar open={showSuccessSnackbar} autoHideDuration={1000}>
        <MuiAlert
          onClose={() => setShowSuccessSnackbar(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Enquiry Added Successfully!
        </MuiAlert>
      </Snackbar>
      <Snackbar open={showFailureSnackbar} autoHideDuration={1000}>
        <MuiAlert
          onClose={() => setShowFailureSnackbar(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Sorry, something went wrong!
        </MuiAlert>
      </Snackbar>
      <ContactUs
        isMobile={selectIsMobile(state)}
        slotRef={slotRef}
        calendarRef={calendarRef}
        handleDateDialog={handleDateDialog}
        timeSlot={timeSlot}
        selectedSlot={selectedSlot}
        handleSlot={handleSlot}
        selectedDate={selectedDate}
        showDateDialog={showDateDialog}
        handleDateChange={handleDateChange}
        handleTimeDialog={handleTimeDialog}
        showTimeDialog={showTimeDialog}
        name={name}
        setName={setName}
        mobileNumber={mobileNumber}
        handleMobile={handleMobile}
        mobileErr={mobileErr}
        show={show}
        handleSendOtp={handleSendOtp}
        showDialog={showDialog}
        handleClose={handleClose}
        inputRefs={inputRefs}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        otp={otp}
        formatTime={formatTime}
        timeLeft={timeLeft}
        handleSubmitOpt={verifyOTP}
        handleSendEnquiry={handleSendEnquiry}
        showSuccessMsg={showSuccessMsg}
        handleProceed={handleProceed}
        pageIndex={pageIndex}
        handleDownloadBrochure={handleDownloadBrochure}
      />
    </>
  );
};

export default ContactUsContainer;
