import React from 'react'
import ElderlyPlans from '../../shared/components/ElderlyCarePlansBasePage/ElderlyPlans'
import images from '../../commons/ImagesExport'

function NineMonthsPlan() {
  return (
    <ElderlyPlans
    image = {images.PregnancyPlan1}
      title="9 Months Plan"
      features="These plans are designed in consultation with experts to support, motivate and keep you healthy. The plan mentioned is on a monthly basis."
    />
  )
}

export default NineMonthsPlan