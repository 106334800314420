import React from "react";
import images from "../../../commons/ImagesExport";
import "./ElderlyPlans.css";
import HeaderContainer from "../../containers/HeaderContainer/HeaderContainer";
import Footer from "../Footer/Footer";
import CallBackPopUp from "../CallBackPopUpPage/CallBackPopUp";

function ElderlyPlans(props) {
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  return (
    <>
      <HeaderContainer />
      {window.scrollTo(0, 0)}
      <section className="plancontent">
        <div className="main-paln">
          <div className="left-pan">
            <img src={props.image} alt="" />
          </div>
          <div className="right-pan">
            <h5>{props.title} </h5>
            <p>Review</p>
            <ul className="review-start">
              <li>
                <a href="javascript:void(0)">
                  <img src={images.StarRev} alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src={images.StarRev} alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src={images.StarRev} alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src={images.StarRev} alt="" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <img src={images.StarRev} alt="" />
                </a>
              </li>
            </ul>
            <button
              onClick={() => setDisplayPopUp(true)}
              className="plan-signup"
            >
              Signup
            </button>
            <hr />
            <h6>Features</h6>
            <p>{props.features}</p>
          </div>
        </div>
      </section>
      <Footer />
      <CallBackPopUp displayPopUp={displayPopUp} setDisplayPopUp={setDisplayPopUp} />
    </>
  );
}

export default ElderlyPlans;
