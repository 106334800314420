import React from "react";
import images from "../../../commons/ImagesExport";
import Slider from "react-slick";

function NewsRoom(props) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: false,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    ...settings,
    slidesToShow: 4,
    autoplaySpeed: 2000,
  };
  return (
    <div className="mt-24 container">
      <div className="second-slider">
        <h3 className="sliderheading">NEWS ROOM</h3>
        <Slider
          {...settings2}
          className="flex justify-center items-center mt-10"
        >
          {props.images.map((image, index) => (
            <div>
              <img src={image} className="m-auto" alt="" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default NewsRoom;
