import React, { createRef, useEffect, useRef, useState } from "react";
import "./AboutUsPage.css";
import img from "./Home BG Image.svg";
import whyChooseUsImg from "../ElderlyCareServicePage/Why Choose Us 1.svg";
import whyChooseUsImg1 from "../../assets/elderlyCareImages/why choose us final mobile.svg";
import bg from "../../assets/aboutUsPageImages/Rectangle 2514.png";
import bg1 from "../../assets/aboutUsPageImages/Rectangle 2511.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import user1 from "../../assets/aboutUsPageImages/1.png";
import user2 from "../../assets/aboutUsPageImages/2.png";
import user3 from "../../assets/aboutUsPageImages/3.png";
import user4 from "../../assets/aboutUsPageImages/4.png";
import user5 from "../../assets/aboutUsPageImages/5.png";
import user6 from "../../assets/aboutUsPageImages/6.png";
import merger from "../../assets/aboutUsPageImages/merger.png";
import bgM from "./Rectangle 2514.svg";
import iconBg from "./Rectangle 2510.svg";
import bgBg from "./Rectangle 2511.svg";
import mobileImgMain from "./Frame 427319583.svg";
import img1 from "../../assets/aboutUsPageImages/Rectangle 2669.svg";
import img2 from "../../assets/aboutUsPageImages/Rectangle 2669 (1).svg";
import img3 from "../../assets/aboutUsPageImages/Rectangle 2670.svg";
import img4 from "../../assets/aboutUsPageImages/Rectangle 2669.png";
import img5 from "../../assets/aboutUsPageImages/Rectangle 2670.png";
import img6 from "../../assets/aboutUsPageImages/Rectangle 2671.png";
const AboutUsPage = (props) => {
  const { isMobile, handleEnquireNow } = props;
  const mySlider = createRef();
  const dropDownArr = [
    {
      label: "What is Webflow and why is it the best website builder?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      label: "What is your favorite template from BRIX Templates?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      label: "How do you clone a Webflow Template from the Showcase?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      label: "Why is BRIX Templates the best Webflow agency out there?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleIndex = (i) => {
    setSelectedIndex(i);
  };

  const icons = [user1, user2, user3, user4, user5, user6];

  const data = [
    {
      img: user1,
      name: "Raghav Kad",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      linkedin: "www.linkedin.com/in/someone-xyz-7924921b4",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user2,
      name: "Nishant Kakkar",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      linkedin: "www.linkedin.com/in/someone-xyz-7924921b4",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user3,
      name: "Pranav Miglani",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      linkedin: "www.linkedin.com/in/someone-xyz-7924921b4",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user4,
      name: "Kuldeep Singh",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      linkedin: "www.linkedin.com/in/someone-xyz-7924921b4",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user5,
      name: "Nishant Kakkar",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      linkedin: "www.linkedin.com/in/someone-xyz-7924921b4",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user6,
      name: "Pranav Miglani",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      linkedin: "www.linkedin.com/in/someone-xyz-7924921b4",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
  ];

  const alignData = () => {
    return userIndex.map((item, index) => {
      return (
        <>
          {index === 0 && (
            <div className="aboutUsPageContainer3-sliderCont">
              <img src={bg1} className="aboutUsPageContainer3-sliderContImg" />
              <div className="aboutUsPageContainer3-sliderDiv">
                <div>
                  <h6 className="aboutUsPageContainer3-sliderDiv-h">
                    {item.name}
                  </h6>
                  <p className="aboutUsPageContainer3-sliderDiv-d">
                    {item.designation}
                  </p>
                </div>

                <div>
                  <p className="aboutUsPageContainer3-sliderDiv-h1">
                    Years of Experience
                  </p>
                  <p className="aboutUsPageContainer3-sliderDiv-t">
                    {item.experience} years
                  </p>
                </div>
                <div>
                  <p className="aboutUsPageContainer3-sliderDiv-h1">Location</p>
                  <p className="aboutUsPageContainer3-sliderDiv-t">
                    {item.location}
                  </p>
                </div>
                <div>
                  <p className="aboutUsPageContainer3-sliderDiv-h1">
                    Email Address
                  </p>
                  <p className="aboutUsPageContainer3-sliderDiv-t">
                    {item.email}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      );
    });
  };

  const alignDataM = () => {
    return userIndex.map((item, index) => {
      return (
        <>
          {index === 0 && (
            <div className="aboutUsPageContainer3-sliderCont">
              <div className="aboutUsPageContainer3-sliderDivM">
                <div style={{ width: "30%" }}>
                  <h6 className="aboutUsPageContainer3-sliderDiv-h">
                    {item.name}
                  </h6>
                  <p className="aboutUsPageContainer3-sliderDiv-d">
                    {item.designation}
                  </p>
                </div>
                <div style={{ width: "65%" }}>
                  <h6 className="aboutUsPageContainer3-sliderDiv-h">
                    LinkedIn
                  </h6>
                  <p className="aboutUsPageContainer3-sliderDiv-d">
                    {item.linkedin}
                  </p>
                </div>
              </div>
              <div>
                <h6 className="aboutUsPageContainer3-sliderDiv-h">Email</h6>
                <p className="aboutUsPageContainer3-sliderDiv-d">
                  {item.email}
                </p>
              </div>
            </div>
          )}
        </>
      );
    });
  };

  const [value, setValue] = useState(9.6);

  // useEffect(() => {
  //   if (currentSlide === 0) {
  //     setValue(12);
  //   } else if (currentSlide === 1) {
  //     setValue(6);
  //   } else if (currentSlide === 2) {
  //     setValue(0);
  //   } else if (currentSlide === 3) {
  //     setValue(-6);
  //   }
  // }, [currentSlide]);

  // useEffect(() => {
  //   const increment = currentSlide * 8;
  //   setValue(9.6 - increment);
  // }, [currentSlide]);

  const [iconIndex, setIconIndex] = useState(icons);
  const [userIndex, setUserIndex] = useState(data);

  const handleRightButtonClick = () => {
    const shiftediconIndex = [
      iconIndex[iconIndex.length - 1],
      ...iconIndex.slice(0, iconIndex.length - 1),
    ];
    setIconIndex(shiftediconIndex);
    handleRightUser();
  };

  const handleLeftButtonClick = () => {
    const shiftediconIndex = [...iconIndex.slice(1), iconIndex[0]];
    setIconIndex(shiftediconIndex);
    handleLeftUser();
  };

  const handleRightUser = () => {
    const shiftedData = [
      userIndex[userIndex.length - 1],
      ...userIndex.slice(0, userIndex.length - 1),
    ];
    setUserIndex(shiftedData);
  };

  const handleLeftUser = () => {
    const shiftedData = [...userIndex.slice(1), userIndex[0]];
    setUserIndex(shiftedData);
  };

  const alignIcons = () => {
    return iconIndex.map((item, index) => {
      return (
        <img
          src={item}
          alt="team"
          key={index}
          className="aboutUsPageContainer3-icon"
        />
      );
    });
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const contentRef = useRef(null);

  const texts = [
    {
      title: "Patient Engaged",
      picture: img1,
      mPicture: img4,
      desc: "Empowering you with comprehensive knowledge and support throughout your healthcare journey. Thus, ensuring confidence and active involvement in every decision regarding your well-being.",
    },
    {
      title: "Personalized",
      picture: img2,
      mPicture: img5,
      desc: "Providing tailored assessments and customized options for your unique health needs, preferences, and goals. Thus, ensuring your care plan is as individual as you are, for the best possible outcomes.",
    },
    {
      title: "Tech Progressive",
      picture: img3,
      mPicture: img6,
      desc: "Integrating cutting-edge technology with compassionate human touch, offering advanced and innovative solutions that enhance your healthcare experience and support your path to better health.",
    },
  ];

  useEffect(() => {
    const contentDiv = contentRef.current;
    const activeDiv = contentDiv.children[activeIndex];

    if (activeDiv) {
      contentDiv.scrollTo({
        top: activeDiv.offsetTop - contentDiv.offsetTop,
        behavior: "smooth",
      });
    }
  }, [activeIndex]);

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Healthy You at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, we empower you through <br /> prevention, cure,
              and awareness, supporting <br /> your journey to better health.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Healthy You at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, we empower you through prevention and cure.
              Enhancing <br />
              awareness for a healthier future and supporting your journey to
              betterment.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      {isMobile ? (
        <>
          <div className="aboutUsPageContainer1">
            <div className="aboutUsPageContainer1-text1">
              We're crafting upscale, comprehensive preventive and <br />
              curative solutions for optimal patient outcomes.
            </div>
            <div className="aboutUsPageContainer1-div1">
              <div className="aboutUsPageContainer1-scroll-container">
                <div className="aboutUsPageContainer1-scroll-bar-box">
                  <div
                    className="aboutUsPageContainer1-scroll-bar"
                    style={{ top: `${activeIndex * 33.39}%` }}
                  ></div>
                </div>

                <div className="content" ref={contentRef}>
                  {texts.map((text, index) => (
                    <div
                      key={index}
                      className="aboutUsPageContainer1-text2"
                      onClick={() => setActiveIndex(index)}
                      style={
                        index === activeIndex
                          ? { color: "#215071" }
                          : { color: "#A4A4A4" }
                      }
                    >
                      {text.title}
                    </div>
                  ))}
                </div>
              </div>
              <img
                src={texts[activeIndex].mPicture}
                className="aboutUsPageContainer1-img"
              />
            </div>
            <div className="aboutUsPageContainer1-text1">
              {texts[activeIndex].desc}
            </div>
          </div>
        </>
      ) : (
        <div className="aboutUsPageContainer1">
          <div className="aboutUsPageContainer1-left">
            <div className="aboutUsPageContainer1-text1">
              We're crafting upscale, comprehensive <br /> preventive and
              curative solutions for <br />
              optimal patient outcomes.
            </div>
            <div className="aboutUsPageContainer1-scroll-container">
              <div className="aboutUsPageContainer1-scroll-bar-box">
                <div
                  className="aboutUsPageContainer1-scroll-bar"
                  style={{ top: `${activeIndex * 33.39}%` }}
                ></div>
              </div>

              <div className="content" ref={contentRef}>
                {texts.map((text, index) => (
                  <div
                    key={index}
                    className="aboutUsPageContainer1-text2"
                    onClick={() => setActiveIndex(index)}
                    style={
                      index === activeIndex
                        ? { color: "#215071" }
                        : { color: "#A4A4A4" }
                    }
                  >
                    {text.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="aboutUsPageContainer1-right">
            <div className="aboutUsPageContainer1-text1">
              {texts[activeIndex].title}
            </div>
            <img
              src={texts[activeIndex].picture}
              className="aboutUsPageContainer1-img"
            />
            <div className="aboutUsPageContainer1-text1">
              {texts[activeIndex].desc}
            </div>
          </div>
        </div>
      )}

      <img
        src={isMobile ? whyChooseUsImg1 : whyChooseUsImg}
        style={
          isMobile
            ? { margin: "2rem 0rem", width: "100%" }
            : { margin: "5rem 0rem", width: "100%" }
        }
      />

      {/* <>
        {isMobile ? (
          <>
            <div className="aboutUsPageContainer3">
              <img src={bgM} style={{ width: "100%" }} />
              <div className="aboutUsPageContainer3-M">
                <div>
                  <h4
                    style={{ padding: "16px 0px" }}
                    className="aboutUsPageContainer3-h1"
                  >
                    Meet our <br /> team members
                  </h4>
                  <p className="aboutUsPageContainer3-t1">
                    Our team embodies our values by prioritising integrity,
                    empathy, and innovation, ensuring every decision and action
                    aligns with our commitment to excellence.
                  </p>
                </div>

                <div>
                  <div className="aboutUsPageContainer3-alignIcons">
                    {alignIcons()}
                  </div>
                  <div className="aboutUsPageContainer3-sliderContMain">
                    <img
                      src={merger}
                      className="aboutUsPageContainer3-alignIcons-pointer"
                    />
                    {alignDataM()}
                  </div>
                </div>

                <div>
                  <p className="aboutUsPageContainer3-t2">
                    Join us to be part of a dynamic team dedicated to
                    revolutionising healthcare, where your talents are valued,
                    your impact is <br /> profound, and innovation thrives.
                  </p>
                  <button className="aboutUsPageContainer3-t2Btn">
                    Career with us
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="aboutUsPageContainer3">
            <img src={bg} style={{ width: "100%" }} />
            <div className="aboutUsPageContainer3-div">
              <div className="aboutUsPageContainer3-cont1">
                <h4 className="aboutUsPageContainer3-h1">
                  Meet our team members
                </h4>
                <p className="aboutUsPageContainer3-t1">
                  Our team embodies our values by prioritising integrity,
                  empathy, and innovation, ensuring every decision and action
                  aligns with our commitment to excellence.
                </p>
              </div>

              <div className="aboutUsPageContainer3-alignIcons">
                {alignIcons()}

                <div className="aboutUsPageContainer3-arrow1">
                  <ArrowRightAltIcon
                    onClick={handleRightButtonClick}
                    fontSize="large"
                  />
                </div>
                <div className="aboutUsPageContainer3-arrow2">
                  <ArrowRightAltIcon
                    onClick={handleLeftButtonClick}
                    fontSize="large"
                  />
                </div>
              </div>

              <div className="aboutUsPageContainer3-sliderContMain">
                <img
                  src={merger}
                  className="aboutUsPageContainer3-alignIcons-pointer"
                />
                {alignData()}
              </div>

              <div>
                <p className="aboutUsPageContainer3-t2">
                  Join us to be part of a dynamic team dedicated to
                  revolutionising healthcare, where your talents are valued,
                  your impact is <br /> profound, and innovation thrives.
                </p>
                <button className="aboutUsPageContainer3-t2Btn">
                  Career with us
                </button>
              </div>
            </div>
          </div>
        )}
      </> */}
    </>
  );
};

export default AboutUsPage;
