import React, { useContext, useEffect, useRef, useState } from "react";
import CareerPage from "../../components/CareerPage/CareerPage";
import { Context } from "../../store/Store";
import { selectIsMobile } from "../../store/global/Global.selectors";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { APIPostRequest } from "../../config/HttpClient";
import { ADD_CAREER } from "./CareerContainer.constants";
import { Backdrop, makeStyles } from "@material-ui/core";
import images from "../../commons/ImagesExport";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

const CareerContainer = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);
  const imageRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickImage = () => {
    imageRef.current.click();
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setIsImageSelected(true);
    } else {
      setSelectedImage(null);
      setIsImageSelected(false);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    linkedIn: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!formData.linkedIn) {
      newErrors.linkedIn = "link is required";
    } else if (formData.linkedIn.length < 6) {
      newErrors.linkedIn = "link must be at least 6 characters";
    }

    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^\d+$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number must be digits only";
    } else if (formData.mobile.length !== 10) {
      newErrors.mobile = "Mobile number must be 10 digits";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate() && isImageSelected) {
      console.log("Form submitted successfully", formData);
      handleSendOtp();
    } else {
      console.log("Form has errors");
      setIsImageSelected(false);
    }
  };

  const handleSendOtp = () => {
    var formdata = new FormData();
    formdata.append(
      "data",
      `{"name":"${formData.name}", "email":"${formData.email}", "mobileNumber":"${formData.mobile}", "linkedin":"${formData.linkedIn}"}`
    );
    formdata.append("resume", selectedImage, selectedImage.name);
    setIsFetching(true);
    APIPostRequest(
      ADD_CAREER,
      formdata,
      (response) => {
        console.log("Customer logged in", response);
        setIsFetching(false);
        setSuccess(true);
      },
      (error) => {
        console.log(error);
        setError(true);
      }
    );
  };

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".career-container2");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <Snackbar open={success} autoHideDuration={1000}>
        <MuiAlert
          onClose={() => setSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Resume Uploaded Successfully!
        </MuiAlert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={1000}>
        <MuiAlert
          onClose={() => setError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Sorry, something went wrong!
        </MuiAlert>
      </Snackbar>

      {isFetching ? (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader} />
        </Backdrop>
      ) : (
        <CareerPage
          isMobile={selectIsMobile(state)}
          imageRef={imageRef}
          selectedImage={selectedImage}
          handleClickImage={handleClickImage}
          handleImage={handleImage}
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          errors={errors}
          isImageSelected={isImageSelected}
          handleEnquireNow={handleEnquireNow}
        />
      )}
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default CareerContainer;
