import React, { useEffect, useState } from "react";
import img2 from "../../assets/elderlyCareImages/elderly care bg.png";
import "./ElderlyCareServicePage.css";
import whyChooseUsImg from "./Why Choose Us 1.svg";
import whyChooseUsImg1 from "../../assets/elderlyCareImages/why choose us final mobile.svg";
import img1 from "../../assets/elderlyCareImages/eWhy1.png";
import img111 from "./Rectangle 2660.jpg";
import bg1 from "../../assets/elderlyCareImages/bg1.png";
import checkboxImg from "./Frame.png";
import mobileImgMain from "./Image & BG.svg";
import commparision from "./Frame 427320003.png";
import ab1 from "./Frame 427320025.svg";
import ab2 from "./Frame 427320024.svg";
import ab3 from "./Frame 427320006.svg";
import ab4 from "./Frame 427320027.svg";

const ElderlyCareServicePage = (props) => {
  const { isMobile, arr, arr1, handleClick, handleEnquireNow } = props;

  const [active, setActive] = useState(true);

  const handleToggle = () => {
    setActive(!active);
    const enqElement = document.querySelector(".elderlyCarePageContainer3");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const crossArr = [ab2, ab3, ab4];

  const [startX, setStartX] = useState(null);
  const [currIndex, setcurrIndex] = useState(0);

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    if (!startX) return;

    const currentX = event.touches[0].clientX;
    const deltaX = currentX - startX;

    if (deltaX > 50 && currIndex > 0) {
      setcurrIndex((prevIndex) => prevIndex - 1);
      setStartX(null);
    } else if (deltaX < -50 && currIndex < crossArr.length - 2) {
      setcurrIndex((prevIndex) => prevIndex + 1);
      setStartX(null);
    }
  };

  const renderImages = () => {
    const imagesToDisplay = crossArr.slice(currIndex, currIndex + 2);

    return imagesToDisplay.map((imageUrl, index) => (
      <img key={index} src={imageUrl} alt={`Image ${currIndex + index + 1}`} />
    ));
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Elderly Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, we prioritize comprehensive elder care that <br />{" "}
              extends beyond mere physical assistance.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Elderly Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Healthy You, we prioritize comprehensive elder care that
              extends beyond <br /> mere physical assistance.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img
        src={isMobile ? whyChooseUsImg1 : whyChooseUsImg}
        style={isMobile ? {} : { width: "100%", margin: "6rem 0rem" }}
      />

      <div className="elderlyCarePageContainer2">
        <img alt="images" src={isMobile ? img111 : img1} width="100%" />

        <div className="elderlyCarePageContainer2-div">
          <div className="elderlyCarePageContainer2-div1">
            <img
              alt="images"
              src={bg1}
              className="elderlyCarePageContainer2imgbg"
            />

            <div className="elderlyCarePageContainer2-div2">
              <h4 className="elderlyCarePageContainer2-div-h">
                Basic Care Plan
              </h4>
              <p className="elderlyCarePageContainer2-div-t">
                We provide tender attention, aid with daily <br /> tasks, ensure
                comfort, foster connections, and <br /> nurture well-being for
                cherished elders.
              </p>

              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Consultations
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Health manager
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Trained caregivers
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Health profiling
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Emergency Services
                </p>
              </div>
              <p className="elderlyCarePageContainer2-div-t3">and more</p>

              <div className="elderlyCarePageContainer2-btn">
                <button
                  onClick={handleClick}
                  className="elderlyCarePageContainer2-btn1"
                >
                  Compare
                </button>
                <button
                  onClick={() => handleToggle()}
                  className="elderlyCarePageContainer2-btn2"
                >
                  Know more
                </button>
              </div>
            </div>
          </div>

          <div className="elderlyCarePageContainer2-div1">
            <img
              alt="images"
              src={bg1}
              className="elderlyCarePageContainer2imgbg"
            />
            <div className="elderlyCarePageContainer2-div2">
              <h4 className="elderlyCarePageContainer2-div-h">
                Specialised Care Plan
              </h4>
              <p className="elderlyCarePageContainer2-div-t">
                Our tailored expertise addresses unique health
                <br /> challenges with compassion , enhancing the <br /> quality
                of life for beloved seniors.
              </p>

              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Gait, balance & mobility training
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Health manager
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Skilled Caregiver
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Pain relief and management
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                <img
                  src={checkboxImg}
                  className="elderlyCarePageContainer-checkBox"
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Emergency Services
                </p>
              </div>
              <p className="elderlyCarePageContainer2-div-t3">and more</p>

              <div className="elderlyCarePageContainer2-btn">
                <button
                  onClick={handleClick}
                  className="elderlyCarePageContainer2-btn3"
                >
                  Starts INR 11,999/-
                </button>
                <button
                  onClick={() => handleToggle()}
                  className="elderlyCarePageContainer2-btn2"
                >
                  Know more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="elderlyCarePageContainer-toggle">
        <div className="elderlyCarePageContainer-toggle1">
          <button
            onClick={handleToggle}
            className={
              active
                ? "elderlyCarePageContainer-toggleActive"
                : "elderlyCarePageContainer-toggleUnActive"
            }
          >
            Basic
          </button>
          <button
            onClick={handleToggle}
            className={
              !active
                ? "elderlyCarePageContainer-toggleActive"
                : "elderlyCarePageContainer-toggleUnActive"
            }
          >
            Specialised
          </button>
        </div>
      </div>

      {active ? (
        <div className="elderlyCarePageContainer3">
          {arr.map((item, index) => {
            return (
              <div key={index} className="elderlyCarePageContainer3-card">
                <div>
                  <img
                    alt="images"
                    src={item.arrImg}
                    className="elderlyCarePageContainer3-cardImg"
                  />
                </div>

                <h5 className="elderlyCarePageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="elderlyCarePageContainer3-cardText2">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="elderlyCarePageContainer3">
          {arr1.map((item, index) => {
            return (
              <div key={index} className="elderlyCarePageContainer3-card">
                <div>
                  <img
                    alt="images"
                    src={item.arrImg}
                    className="elderlyCarePageContainer3-cardImg"
                  />
                </div>

                <h5 className="elderlyCarePageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="elderlyCarePageContainer3-cardText2">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      )}

      <div className="elderlyCarePageContainer4">
        {isMobile ? (
          <>
            <div
              style={{
                position: "relative",
                padding: "16px 0px",
                display: "flex",
              }}
            >
              <div>
                <img src={ab1} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: "0px",
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={() => setStartX(null)}
              >
                {renderImages()}
              </div>
            </div>
          </>
        ) : (
          <img alt="images" src={commparision} />
        )}
      </div>

      <button
        style={
          isMobile
            ? { display: "block", margin: "auto", width: "180px" }
            : { display: "block", margin: "auto", width: "340px" }
        }
        className="consultationPageContainer1Content-btn"
        onClick={handleEnquireNow}
      >
        Enquire Now
      </button>
    </>
  );
};

export default ElderlyCareServicePage;
