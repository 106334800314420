import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import "./tailwindCSS/Tailwind_Input.css";
import "./App.css";
import routeConfig from "./routes";
import HomePage from "./containers/HomePageContainer";
import Store from "./store/Store";

const App = () => (
  <Store>
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route key="initial" exact path="/home" component={HomePage} />
        {routeConfig.routes.map((route) => (
          <Route
            key={route.name}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </Router>
  </Store>
);

export default App;
