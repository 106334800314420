import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import tick from "./tick.svg";

const DeleteAccountPage = (props) => {
  const {
    isMobile,
    show,
    name,
    setName,
    mobileNumber,
    handleMobile,
    mobileErr,
    showDialog,
    handleSendOtp,
    handleClose,
    inputRefs,
    handleChange,
    handleKeyDown,
    otp,
    formatTime,
    timeLeft,
    handleSubmitOpt,
    handleDeleteAccount,
    showSuccessMsg,
    handleProceed,
    email,
    handleEmailChange,
    emailError,
    accountDeletionSuccess,
    userNotFound,
  } = props;

  return (
    <>
      <div className="contactUsPage">
        {accountDeletionSuccess ? (
          <div className="contactUsPage-div1" style={{ width: "100%" }}>
            <p className="contactUsPage-head1">
              Request submitted successfully! Your account will be deleted in
              next 7 working days. You will be notified once the deletion
              process is completed.
            </p>
          </div>
        ) : (
          <div className="contactUsPage-div1" style={{ width: "100%" }}>
            <h4 className="contactUsPage-head1">
              Request to Delete Your Account.
            </h4>

            <div className="contactUsPage-formMain">
              {show ? (
                <div className="contactUsPage-form">
                  <div className="contactUsPage-form1">
                    <label className="contactUsPage-formLabel">Name</label>
                    <input
                      type="text"
                      className="contactUsPage-formInput"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      disabled
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="contactUsPage-form1">
                    <label className="contactUsPage-formLabel">
                      Enter your mobile number
                    </label>
                    <div className="contactUsPage-formInput1Div">
                      <div className="contactUsPage-formInput1Div1">
                        <div className="contactUsPage-formInput1Div-code">
                          +91
                        </div>
                        <ArrowDropDownIcon htmlColor="#838383" />
                      </div>
                      <input
                        type="text"
                        maxLength={10}
                        value={mobileNumber}
                        disabled
                        className="contactUsPage-formInput1"
                        placeholder="Enter your Mobile Number"
                      />
                    </div>
                    {mobileErr && <div>{mobileErr}</div>}
                  </div>
                </div>
              ) : (
                <div className="contactUsPage-form2">
                  <div className="contactUsPage-form1A">
                    <label className="contactUsPage-formLabel">Name</label>
                    <input
                      type="text"
                      className="contactUsPage-formInput"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="contactUsPage-form1A">
                    <label className="contactUsPage-formLabel">
                      Enter your mobile number
                    </label>
                    <div className="contactUsPage-formInput1Div">
                      <div className="contactUsPage-formInput1Div1A">
                        <div className="contactUsPage-formInput1Div-code">
                          +91
                        </div>
                        <ArrowDropDownIcon htmlColor="#838383" />
                      </div>
                      <input
                        type="text"
                        maxLength={10}
                        value={mobileNumber}
                        onChange={handleMobile}
                        className="contactUsPage-formInput1AA"
                        placeholder="Enter your Mobile Number"
                      />
                    </div>
                    {mobileErr && <div>{mobileErr}</div>}
                  </div>
                </div>
              )}
              {show && (
                <div className="contactUsPage-form">
                  <div className="contactUsPage-form1">
                    <label className="contactUsPage-formLabel">Email</label>
                    <input
                      type="text"
                      className="contactUsPage-formInput"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="JohnDoe@gmail.com"
                    />{" "}
                    {emailError && <div>{emailError}</div>}
                  </div>
                </div>
              )}
            </div>
            {userNotFound && (
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  font: "1.5rem",
                }}
              >
                <div>{userNotFound}</div>
              </div>
            )}
            {show ? (
              <button
                onClick={() => handleDeleteAccount()}
                className="contactUsPage-Btn"
              >
                Submit
              </button>
            ) : (
              <button
                onClick={() => handleSendOtp()}
                className="contactUsPage-Btn"
              >
                Send OTP
              </button>
            )}
          </div>
        )}
      </div>

      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        {showSuccessMsg ? (
          <div className="contactUsPage-dialog">
            <div onClick={handleClose} className="contactUsPage-dialogClose">
              <CloseIcon />
            </div>

            <img src={tick} />
            <h1 className="contactUsPage-dialogHead">
              Verification Successful
            </h1>

            <p
              style={{ color: "#000000", fontSize: "14px", lineHeight: "18px" }}
              className="contactUsPage-dialogText"
            >
              Your details have been verified.
              <br />
              Click continue to secure account.
            </p>
            <button onClick={handleProceed} className="contactUsPage-dialogBtn">
              Proceed
            </button>
          </div>
        ) : (
          <div className="contactUsPage-dialog">
            <div onClick={handleClose} className="contactUsPage-dialogClose">
              <CloseIcon />
            </div>
            <h1 className="contactUsPage-dialogHead">OTP Verification</h1>
            <p className="contactUsPage-dialogText">
              Enter the OTP sent to : +91 {mobileNumber}
            </p>
            <div className="contactUsPage-dialogBoxs">
              {otp?.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  ref={inputRefs[index]}
                  className="contactUsPage-dialogInput"
                />
              ))}
            </div>
            <p className="contactUsPage-dialogText">
              {formatTime(timeLeft)} Mins
            </p>
            <p className="contactUsPage-dialogText">
              Didn't receive the code?{" "}
              <span className="contactUsPage-dialogText1">Re-send</span>
            </p>
            <button
              onClick={handleSubmitOpt}
              className="contactUsPage-dialogBtn"
            >
              Submit
            </button>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default DeleteAccountPage;
