import React from "react";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import "./OurTeam.css";
import Link from "react-router-dom/Link";

function OurTeam(props) {
  return (
    <div>
      <HeaderContainer />
      <h2 className="our-team-header">Meet Our Team</h2>
      <section className="our-team">
        <div className="row-1-team">
          {props.teamData &&
            props.teamData.director.map((item, index) => {
              return (
                <a href={item.linkedinLink} className="text-black cursor-pointer" target="_blank">
                  <div className="box-1-team">
                    <img
                      className="team-image "
                      src={
                        item.image.length > 0
                          ? item.image
                          : "https://stlrevesby.syd.catholic.edu.au/wp-content/uploads/sites/44/2019/05/Person-icon.jpg"
                      }
                      alt=""
                      style={{ width: "90%", margin: "auto" }}
                    />
                    <h4 className="box-title">{item.name}</h4>
                    <p className="caption">{item.designation}</p>
                  </div>
                </a>
              );
            })}
        </div>
        <div className="text-center flex justify-center flex-wrap">
          {props.teamData &&
            props.teamData.teamMembers.map((item, index) => {
              return (
                <a href={item.linkedinLink} className="text-black cursor-pointer" target="_blank">
                  <div className="box-team">
                    <img
                      className="team-image"
                      src={
                        item.image.length > 0
                          ? item.image
                          : "https://stlrevesby.syd.catholic.edu.au/wp-content/uploads/sites/44/2019/05/Person-icon.jpg"
                      }
                      alt=""
                      style={{ width: "90%", margin: "auto" }}
                    />
                    <h4 className="box-title">{item.name}</h4>
                  </div>
                </a>
              );
            })}
        </div>
      </section>
      <h2 className="our-team-header">Meet Our Advisors</h2>
      <section className="our-team">
        <div className="text-center flex justify-center flex-wrap">
          {props.teamData &&
            props.teamData.advisor.map((item, index) => {
              return (
                <a href={item.linkedinLink} className="text-black cursor-pointer" target="_blank">
                  <div className="box-1-team">
                    <img
                      className="team-image"
                      src={
                        item.image.length > 0
                          ? item.image
                          : "https://stlrevesby.syd.catholic.edu.au/wp-content/uploads/sites/44/2019/05/Person-icon.jpg"
                      }
                      alt=""
                      style={{ width: "90%", margin: "auto" }}
                    />
                    <h4 className="box-title">{item.name}</h4>
                    <p className="caption">{item.designation}</p>
                  </div>
                </a>
              );
            })}
        </div>

      </section>
      <Footer />
    </div>
  );
}

export default OurTeam;
