import React, { useState } from "react";
import "./MobileFloatingButton.css";
import imgs from "./chat button.svg";
import img1 from "./Frame 427320021.svg";
import img2 from "./chat button (1).svg";
import img3 from "./chat.svg";
import img4 from "./lab test (1).svg";
import img5 from "./lab test.svg";
import img6 from "./Frame 427320022.svg";
import img7 from "./physiotherapy.svg";
import img8 from "./whatsapp.svg";
import { useHistory } from "react-router-dom";
import {
  CARE_GIVER_SERVICE_PAGE,
  CONSULTATION_PAGE,
  LAB_TEST_SERVICE_PAGE,
  MEDICAL_EQUIPMENT_SERVICE_PAGE,
  PHYSIO_THERAPY_SERVICE_PAGE,
} from "../../routes/constants";
const MobileFloatingButton = (props) => {
  const { isMobile, handleShowChat, showBtn, setShowBtns } = props;
  const history = useHistory();

  const btnArr = [
    { picture: img5, text: "Consulatation at Home" },
    { picture: img4, text: "Lab test at Home" },
    { picture: img6, text: "Medical Equipment at Home" },
    { picture: img1, text: "Caregiver at Home" },
    { picture: img7, text: "Physiotherapy at Home" },
    { picture: img3, text: "Chat" },
    { picture: img8, text: "Whatsapp" },
    { picture: img2, text: "" },
  ];

  const showBtns = () => {
    return btnArr.map((item, index) => {
      return (
        <div className="mfbContainer2">
          <div
            onClick={() => handleClickIcons(index)}
            className={item.text === "" ? "" : "mfbContainer-div"}
          >
            {item.text}
          </div>
          <img
            src={item.picture}
            onClick={() => {
              item.text === "" && setShowBtns(!showBtn);
              handleClickIcons(index);
            }}
          />
        </div>
      );
    });
  };

  const handleClickIcons = (i) => {
    if (i === 0) {
      history.push(CONSULTATION_PAGE);
    } else if (i === 1) {
      history.push(LAB_TEST_SERVICE_PAGE);
    } else if (i === 2) {
      history.push(MEDICAL_EQUIPMENT_SERVICE_PAGE);
    } else if (i === 3) {
      history.push(CARE_GIVER_SERVICE_PAGE);
    } else if (i === 4) {
      history.push(PHYSIO_THERAPY_SERVICE_PAGE);
    } else if (i === 5) {
      handleShowChat();
    }
  };
  return (
    <>
      {isMobile && (
        <div
          style={
            showBtn
              ? { height: window.innerHeight, backgroundColor: "#ced8d9d1" }
              : { height: "auto", backgroundColor: "" }
          }
          className="mfbContainer"
        >
          {showBtn && <div className="mfbContainer1">{showBtns()}</div>}
          <div className="mfbContainerImg">
            <img src={imgs} onClick={() => setShowBtns(!showBtn)} />
          </div>
        </div>
      )}
    </>
  );
};

export default MobileFloatingButton;
