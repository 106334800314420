import React, { createRef } from "react";
import "./CareerPage.css";
import img from "../../assets/careersImages/Web bg.svg";
import mobileImgMain from "../../assets/careersImages/Mobile bg.svg";
import icon1 from "../../assets/careersImages/Icons.svg";
import bgImage from "../../assets/careersImages/Frame 427320487.jpg";
import callIcon from "../../assets/careersImages/call.svg";
import mailIcon from "../../assets/careersImages/mail.svg";
import fileIcon from "../../assets/careersImages/attach.svg";
const CareerPage = (props) => {
  const {
    isMobile,
    handleEnquireNow,
    imageRef,
    handleClickImage,
    handleImage,
    formData,
    handleChange,
    handleSubmit,
    errors,
    selectedImage,
    isImageSelected,
  } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />
          <div className="consultationPageContainer1Content">
            <h1
              style={{ textAlign: "center" }}
              className="consultationPageContainer1Content-Head"
            >
              Join Us
            </h1>
            <p className="consultationPageContainer1Content-text">
              We seek compassionate individuals with a <br /> commitment to
              lifelong learning for impactful and <br /> effective service.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">Join Us</h1>
            <p className="consultationPageContainer1Content-text">
              We seek compassionate individuals with a commitment to <br />{" "}
              lifelong learning for impactful and effective service.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      {isMobile ? (
        <div className="career-container">
          <div className="mobileCareer-align">
            <div className="mobileCareer-1">
              WHY <br /> JOIN <br /> US?
            </div>
            <div className="mobileCareer-2">
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Positive Impact</p>
              <p className="career-text1">Empower better health.</p>
            </div>
            <div className="mobileCareer-3">
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Career Growth</p>
              <p className="career-text1">Advance your expertise.</p>
            </div>
          </div>
          <div
            className="mobileCareer-3"
            style={{
              width: "100%",
              backgroundColor: "#215071",
              height: "7rem",
            }}
          >
            <img src={icon1} alt="icon1" className="career-icon" />
            <p className="career-text">Foster a Culture of Care</p>
            <p className="career-text1">
              Be part of a genuinely empathetic and caring team.
            </p>
          </div>
          <div className="mobileCareer-align">
            <div
              className="mobileCareer-3"
              style={{ width: "48%", height: "13rem" }}
            >
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Integrity and Transparency at Core</p>
              <p className="career-text1">
                Work in a secure, transparent, & trustworthy environment.
              </p>
            </div>
            <div
              className="mobileCareer-2"
              style={{ width: "48%", height: "13rem" }}
            >
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Embrace Innovation and Excellence</p>
              <p className="career-text1">
                Join a cutting-edge, forward-thinking healthcare organization.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="career-container">
          <div className="careerDiv1">
            <div className="alignCareer1">
              <div className="career-containerHeaD">
                WHY <br /> JOIN US?
              </div>
            </div>
            <div className="alignCareer2">
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Embrace Innovation and Excellence</p>
              <p className="career-text1">
                Join a cutting-edge, forward-thinking healthcare organization.
              </p>
            </div>
          </div>
          <div className="careerDiv1">
            <div
              style={{ backgroundColor: "#E77947" }}
              className="alignCareer2"
            >
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Embrace Innovation and Excellence</p>
              <p className="career-text1">
                Join a cutting-edge, forward-thinking healthcare organization.
              </p>
            </div>
            <div
              style={{ backgroundColor: "#215071" }}
              className="alignCareer1"
            >
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Career Growth</p>
              <p className="career-text1">Advance your expertise.</p>
            </div>
          </div>
          <div className="careerDiv1">
            <div
              style={{ backgroundColor: "#215071" }}
              className="alignCareer1"
            >
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Positive Impact</p>
              <p className="career-text1">Empower better health.</p>
            </div>
            <div
              style={{ backgroundColor: "#E77947" }}
              className="alignCareer2"
            >
              <img src={icon1} alt="icon1" className="career-icon" />
              <p className="career-text">Foster a Culture of Care</p>
              <p className="career-text1">
                Be part of a genuinely empathetic and caring team.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="career-container2">
        <div className="alignCareer3">
          <p className="career-form-head">Join Us</p>
          <form className="career-form" onSubmit={handleSubmit}>
            <div>
              <p className="career-form-label">Full Name*</p>
              <input
                type="text"
                className="career-input"
                placeholder="John Doe"
                name="name"
                onChange={handleChange}
                value={formData.name}
                style={errors.name ? { borderColor: "red" } : {}}
              />
            </div>
            <div style={{ position: "relative" }}>
              <p className="career-form-label">Mobile Number*</p>
              <input
                type="tel"
                className="career-input"
                placeholder="9888888888"
                style={
                  errors.mobile
                    ? { borderColor: "red", paddingLeft: "22%" }
                    : { paddingLeft: "22%" }
                }
                name="mobile"
                maxLength={10}
                onChange={handleChange}
              />
              <div
                style={errors.mobile ? { borderColor: "red" } : {}}
                className="career-inputA"
              >
                +91
              </div>
            </div>
            <div>
              <p className="career-form-label">Email Address*</p>
              <input
                type="text"
                className="career-input"
                placeholder="someone123@xyz.com"
                name="email"
                onChange={handleChange}
                style={errors.email ? { borderColor: "red" } : {}}
              />
            </div>
            <div>
              <p className="career-form-label">LinkedIn*</p>
              <input
                type="text"
                className="career-input"
                placeholder="www.linkedin.com/in/someone-xyz-7924921b4"
                name="linkedIn"
                onChange={handleChange}
                style={errors.linkedIn ? { borderColor: "red" } : {}}
              />
            </div>
            <div style={{ position: "relative", cursor: "pointer" }}>
              <p className="career-form-label">Resume*</p>
              <div
                onClick={() => handleClickImage()}
                className="career-input"
                style={
                  isImageSelected === false
                    ? {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderColor: "red",
                      }
                    : {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }
                }
              >
                {selectedImage?.name}
              </div>
              <input
                onChange={handleImage}
                ref={imageRef}
                type="file"
                hidden
                placeholder="Upload PDF here"
              />
              <img
                src={fileIcon}
                style={{
                  width: isMobile ? "16px" : "24px",
                  position: "absolute",
                  right: "5%",
                  bottom: "20%",
                }}
                alt="bgImage"
              />
            </div>

            <button className="career-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
        {isMobile ? (
          <></>
        ) : (
          <div className="alignCareer4">
            <img
              src={bgImage}
              alt="bgImage"
              style={{ height: "100%", width: "100%" }}
            />

            <div className="alignCareer4-div">
              <p className="career-text2">
                Partner with us to bring innovative healthcare solutions to your
                community or organization.
              </p>

              <p className="career-text2">
                For immediate assistance or inquiries, feel free to call our
                dedicated team.
              </p>

              <div>
                <p className="career-text2">
                  You can also reach out to us via email or Whatsapp
                </p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <img src={callIcon} style={{ width: "24px" }} alt="bgImage" />
                  <p style={{ margin: 0 }} className="career-text2">
                    801-0711-010
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    paddingTop: "0.5rem",
                  }}
                >
                  <img src={mailIcon} style={{ width: "24px" }} alt="bgImage" />
                  <p style={{ margin: 0 }} className="career-text2">
                    support@hekahealth.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {isMobile ? (
        <>
          <div className="alignCareer5">
            <div className="careerDivider"></div>
            <div className="careerDivider-text">OR</div>
            <div className="careerDivider"></div>
          </div>

          <div className="alignCareer6">
            <img
              src={bgImage}
              alt="bgImage"
              style={{
                height: "100%",
                width: "92.5%",
                objectFit: "contain",
                display: "block",
                margin: "auto",
                borderRadius: "10px",
              }}
            />

            <div className="alignCareer4-div">
              <p className="career-text2" style={{ fontSize: "16px" }}>
                Partner with Us
              </p>
              <p className="career-text2">
                Partner with us to bring innovative healthcare solutions to your
                community or organization.
              </p>

              <p className="career-text2">
                For immediate assistance or inquiries, feel free to call our
                dedicated team.
              </p>

              <p className="career-text2">
                You can also reach out to us via email or Whatsapp
              </p>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <img src={callIcon} alt="bgImage" />
                <p style={{ margin: 0 }} className="career-text2">
                  801-0711-010
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  paddingTop: "0.5rem",
                }}
              >
                <img src={mailIcon} alt="bgImage" />
                <p style={{ margin: 0 }} className="career-text2">
                  support@hekahealth.in
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CareerPage;
