import React from "react";
import "./StrokeRehabilitation.css";
import images from "../../commons/ImagesExport";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import CallBackPopUp from "../../shared/components/CallBackPopUpPage/CallBackPopUp";

function StrokeRehabilitation() {
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  return (
    <>
      <HeaderContainer />
      {window.scrollTo(0, 0)}
      <div className="container">
        <div className="spacing-stroke">
          <h2 className="main-heading-stroke">STROKE REHABILITATION</h2>
          <p className="safety-para-stroke">
            A stroke occurs when the blood flow to a specific part of your brain
            is cut off. When this happens, the cells don’t get oxygen and begin
            to die, causing numerous symptoms. The most common symptoms are
            changes in speech and numbness or weakness of the face, legs, or
            arms.
          </p>
        </div>
      </div>
      <div className="container">
        <img src={images.Stroke6} alt="" className="stroke6image-stroke" />
        <p className="safety-para-stroke">
          The quick assessment for stroke, known by the acronym FAST (face,
          arms, speech, time), is seen below:
        </p>
      </div>
      <div className="container">
        <div className="spacing-stroke">
          <div className="capital-big-stroke">
            <div className="bigcap-stroke">
              <h2>F</h2>
            </div>
            <div className="bigcap-para-stroke">
              <p>Check for facial droop.</p>
            </div>
          </div>
          <div className="capital-big-stroke">
            <div className="bigcap-stroke">
              <h2>A</h2>
            </div>
            <div className="bigcap-para-stroke">
              <p>Hold arms out. Does one drop downward?</p>
            </div>
          </div>
          <div className="capital-big-stroke">
            <div className="bigcap-stroke">
              <h2>S</h2>
            </div>
            <div className="bigcap-para-stroke">
              <p>Is speech abnormal, delayed, or absent?</p>
            </div>
          </div>
          <div className="capital-big-stroke">
            <div className="bigcap-stroke">
              <h2>T</h2>
            </div>
            <div className="bigcap-para-stroke">
              <p>
                It’s time to call local emergency service if any of these
                symptoms are present.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="safety-para-stroke">
          Strokes can cause significant impairment in language, cognition,
          motor, and sensory skills. This is why it’s considered to be a leading
          cause of serious long-term disability.
        </p>
        <p className="safety-para-stroke">
          Successful stroke recovery depends on a number of factors, including:
        </p>
        <div className="electrical-photo-stroke">
          <div className="elctriccontent-stroke">
            <div className="engage-main-stroke">
              <div className="engageimg-div-stroke">
                <img src={images.Stroke1} alt="" />
              </div>
              <h6>How much damage the stroke caused</h6>
            </div>
          </div>
          <div className="elctriccontent-stroke">
            <div className="engage-main-stroke">
              <div className="engageimg-div-stroke">
                <img src={images.Stroke2} alt="" />
              </div>
              <h6>How soon recovery starts</h6>
            </div>
          </div>
          <div className="elctriccontent-stroke">
            <div className="engage-main-stroke">
              <div className="engageimg-div-stroke">
                <img src={images.Stroke3} alt="" />
              </div>
              <h6>Inner motivation &amp; outlook towards recovery</h6>
            </div>
          </div>
          <div className="elctriccontent-stroke">
            <div className="engage-main-stroke">
              <div className="engageimg-div-stroke">
                <img src={images.Stroke4} alt="" />
              </div>
              <h6>Age, when it happened</h6>
            </div>
          </div>
          <div className="elctriccontent-stroke">
            <div className="engage-main-stroke">
              <div className="engageimg-div-stroke">
                <img src={images.Stroke5} alt="" />
              </div>
              <h6>Existing comorbidities, that can affect recovery</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p className="safety-para-stroke">
          At Heka our experienced team of Doctors along with the dedicated care
          manager offer rehabilitation care and helps to meet your stroke
          recovery goals, who plan and coordinate rehab program to help meet
          your stroke recovery goals.
        </p>
        <p className="safety-para-stroke">
          1. Physiatrist – specialises in rehabilitation following injuries,
          accidents or illness.
        </p>
        <p className="safety-para-stroke">
          2. Neurologist – specialises in the prevention, diagnosis and
          treatment of stroke and other diseases of the brain and spinal cord.
        </p>
        <p className="safety-para-stroke">
          3. Rehabilitation nurse – helps people with disabilities and helps
          survivors manage health problems like diabetes and high blood pressure
          and adjust to life after stroke.
        </p>
        <p className="safety-para-stroke">
          4. Physical therapist – helps with problems in moving and balance,
          suggesting exercises to strengthen muscles for walking, standing and
          other activities.
        </p>
        <p className="safety-para-stroke">
          5. Occupational therapist – helps with strategies to manage daily
          activities such as eating, bathing, dressing, writing and cooking.
        </p>
        <p className="safety-para-stroke">
          6. Speech-therapist – helps with talking, reading and writing, and
          shares strategies to help with swallowing problems.
        </p>
        <p className="safety-para-stroke">
          7. Dietician – healthy eating and special diets low in sodium, fat and
          calories.
        </p>
        <p className="safety-para-stroke">
          8. Neuropsychologist – diagnoses and treats survivors who face changes
          in thinking, memory and behaviour.
        </p>
        <p className="safety-para-stroke">
          9. Recreation Therapist. Helps with strategies to improve the thinking
          and movement skills needed to join in recreational activities.
        </p>
      </div>
      <section className="booknewawssion-stroke">
        <div className="container">
          <p className="sessioncall-para-stroke">What are you waiting for?</p>
          <h4 className="sessioncall-heading-stroke">Book A Session Now</h4>
          <button
            onClick={() => setDisplayPopUp(true)}
            className="sessioncall-btn-stroke"
          >
            Schedule A Call
          </button>
        </div>
      </section>
      <Footer />
      <CallBackPopUp
        displayPopUp={displayPopUp}
        setDisplayPopUp={setDisplayPopUp}
      />
    </>
  );
}

export default StrokeRehabilitation;
