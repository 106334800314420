import { useContext, useRef, useState } from "react";
import { Context } from "../../store/Store";
import PhysioTherapyAd from "../../components/PhysiotherapyAd/PhysiotherapyAd";
import { selectIsMobile } from "../../store/global/Global.selectors";
import AdEnquiryContainer from "../../shared/containers/AdEnquiryContainer/AdEnquiryContainer";
import {
  ADD_ENQUIRY,
  CUSTOMER_LOGIN,
  VERIFY_OTP,
} from "../../shared/containers/ContactUsContainer/ContactUsContainer.constants";
import { APIPostRequest } from "../../config/HttpClient";
import { Loader } from "../../shared/components/Loader/Loader";
import SuccessSnackbar from "../../shared/components/Snackbars/SuccessSnackbar";
import FailureSnackbar from "../../shared/components/Snackbars/FailureSnackbar";
import { API_FAILURE_MESSAGE } from "../../store/global/Global.constants";
import img1 from "../../assets/AdImages/Frame 4273204841.svg";
import { useHistory } from "react-router-dom";
import { PHYSIO_THERAPY_THANK_YOU } from "../../routes/constants";
const CareGiverAdContainer = () => {
  const history = useHistory();
  const head = "Phsyiotherapy At Home";
  const text =
    "Tailored exercises and advanced portable devices to improve mobility, reduce pain, and aid recovery.";
  const { state } = useContext(Context);
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileErr, setMobileError] = useState("");
  const [otpErr, setOtpError] = useState("");
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showFailureSnackbar, setShowFailureSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [bgHeight, setBgHeight] = useState(false);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [show, setShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else if (input.length < 10) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Mobile Number must be of 10 digits");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  const handleSendOtp = () => {
    if (name.length > 1 && mobileNumber.length === 10) {
      var formData = new FormData();
      formData.append("data", `{"mobileNumber":"${mobileNumber}"}`);
      APIPostRequest(
        CUSTOMER_LOGIN,
        formData,
        (response) => {
          console.log("Customer logged in", response);
          setCustomerId(response.customerId);
          setShow(true);
          setTimeLeft(60);
          const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
              if (prevTime === 0) {
                clearInterval(timer);
                return 0;
              }
              return prevTime - 1;
            });
          }, 1000);
          setOtpError("");
          setBgHeight(true);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const verifyOTP = () => {
    const enteredOTP = otp.join("");
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "otp": ${enteredOTP}}`
    );
    APIPostRequest(
      VERIFY_OTP,
      formData,
      (response) => {
        console.log("OTP VERIFIED RESPONSE", response);
        handleSendEnquiry();
      },
      (error) => {
        console.log(error);
        setOtpError("Incorrect OTP");
        setOTP(["", "", "", "", "", ""]);
        console.log("Incorrect OTP, please try again.");
      }
    );
  };

  const handleSendEnquiry = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "name": "${name}", "mobileNumber": "${mobileNumber}", "lookingFor": "11", "enquiryFrom": "${
        selectIsMobile(state) ? "Mobile-web" : "Website"
      }","preferredDate": "","preferredTime": ""}`
    );
    APIPostRequest(
      ADD_ENQUIRY,
      formData,
      (response) => {
        console.log("OTP VERIFIED RESPONSE", response);
        setSnackbarMessage("Enquiry added successfully!");
        setShowSuccessSnackbar(true);
        setLoading(false);
        setShow(false);
        setMobileNumber(" ");
        setName(" ");
        history.push(PHYSIO_THERAPY_THANK_YOU);
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setSnackbarMessage(API_FAILURE_MESSAGE);
        setShowFailureSnackbar(true);
      }
    );
  };

  const handleChange = (index, value) => {
    if (!isNaN(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {showSuccessSnackbar && (
            <SuccessSnackbar
              message={snackbarMessage}
              showSuccessSnackbar
              setShowSuccessSnackbar={setShowSuccessSnackbar}
            />
          )}
          {showFailureSnackbar && (
            <FailureSnackbar
              message={snackbarMessage}
              showFailureSnackbar
              setShowFailureSnackbar={setShowFailureSnackbar}
            />
          )}
          <AdEnquiryContainer
            head={head}
            text={text}
            isMobile={selectIsMobile(state)}
            inputRefs={inputRefs}
            otp={otp}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            formatTime={formatTime}
            timeLeft={timeLeft}
            handleSendOtp={handleSendOtp}
            show={show}
            verifyOTP={verifyOTP}
            setName={setName}
            handleMobile={handleMobile}
            mobileNumber={mobileNumber}
            mobileErr={mobileErr}
            otpErr={otpErr}
            bgHeight={bgHeight}
            bgImg={img1}
          />
          <PhysioTherapyAd isMobile={selectIsMobile(state)} />
        </>
      )}
    </>
  );
};

export default CareGiverAdContainer;
