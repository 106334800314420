import React, { useContext, useEffect, useState } from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { APIGetRequest, APIPostRequest } from "../../config/HttpClient";
import ContactUs from "../../components/ContactUsPage/ContactUs";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";
import images from "../../commons/ImagesExport";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

const GalleryPageContainer = () => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  function sendContactData(contact) {
    // if(contact.mobileNumber.length !== 10){
    //   window.alert("Please enter a valid mobile number");
    //   return;
    // }
    setIsFetching(true);
    let formdata = new FormData();
    formdata.append("data", JSON.stringify(contact));
    APIPostRequest(
      "/addContact.php",
      formdata,
      (res) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Your message has been sent successfully");
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
      }
    );
  }

  function sendCareerData(career, file) {
    // if(career.mobileNumber.length !== 10){
    //   window.alert("Please enter a valid mobile number");
    //   return;
    // }
    setIsFetching(true);
    let formdata = new FormData();
    formdata.append("data", JSON.stringify(career));
    formdata.append("resume", file, file.name);
    APIPostRequest(
      "/addCareer.php",
      formdata,
      (res) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Your message has been sent successfully");
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
      }
    );
  }

  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <>
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => {
            setSuccess(false);
          }}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {isFetching ? (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader}  />
        </Backdrop>
      ) : (
        <ContactUs
          sendContactData={sendContactData}
          sendCareerData={sendCareerData}
        />
      )}
    </>
  );
};

export default GalleryPageContainer;
