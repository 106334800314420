import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import {
  ABOUT_US_PAGE,
  BLOG_PAGE,
  CARE_GIVER_SERVICE_PAGE,
  CAREER_PAGE,
  CONSULTATION_PAGE,
  ELDERLY_CARE_SERVICE_PAGE,
  EVENTS_PAGE,
  GENERAL_WELLNESS_SERVICE_PAGE,
  LAB_TEST_SERVICE_PAGE,
  MEDICAL_EQUIPMENT_SERVICE_PAGE,
  OPERATIVE_CARE_SERVICE_PAGE,
  PHYSIO_THERAPY_SERVICE_PAGE,
  PREGNANCY_CARE_SERVICE_PAGE,
  SAFETY_TRAINING_SERVICE_PAGE,
} from "../../../routes/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const HeaderContainer = () => {
  const history = useHistory();
  const [showCities, setShowCities] = useState(false);
  const cityRef = useRef();

  const handleShowCity = () => {
    setShowCities(true);
  };

  const cityArr = [
    "Delhi",
    "Gurgaon",
    "Noida",
    "Faridabad",
    "Manesar",
    "Ghaziabad",
  ];

  const services = [
    "Elderly Care",
    "Pregnancy Care",

    // "General Wellness",
    "Operative Care",
    "Safety Training",
  ];

  const handleCity = () => {};

  const [showServices, setShowServices] = useState(false);

  const handleAboutUs = () => {
    history.push(ABOUT_US_PAGE);
  };

  const handleCareer = () => {
    history.push(CAREER_PAGE);
  };

  const handleServicesOption = (index) => {
    if (index === 0) {
      history.push(ELDERLY_CARE_SERVICE_PAGE);
    } else if (index === 1) {
      history.push(PREGNANCY_CARE_SERVICE_PAGE);
    } else if (index === 2) {
      history.push(OPERATIVE_CARE_SERVICE_PAGE);
    } else if (index === 3) {
      history.push(SAFETY_TRAINING_SERVICE_PAGE);
    }
    setShowServices(false);
  };

  const handleHomePage = () => {
    history.push("/");
  };

  const handlePhysioTherapy = () => {
    history.push(PHYSIO_THERAPY_SERVICE_PAGE);
  };

  const handleCareService = () => {
    history.push(CARE_GIVER_SERVICE_PAGE);
  };

  const handleMedicalEquipment = () => {
    history.push(MEDICAL_EQUIPMENT_SERVICE_PAGE);
  };

  const handleBlogs = () => {
    history.push(BLOG_PAGE);
  };

  const handleClickOutside = (event) => {
    if (cityRef.current && !cityRef.current.contains(event.target)) {
      setShowCities(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleConsultation = () => {
    history.push(CONSULTATION_PAGE);
  };

  const handleLabTest = () => {
    history.push(LAB_TEST_SERVICE_PAGE);
  };

  const handleEvents = () => {
    history.push(EVENTS_PAGE)
  };

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0 && isHeaderVisible) {
        setIsHeaderVisible(false);
      } else if (scrollPosition === 0 && !isHeaderVisible) {
        setIsHeaderVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHeaderVisible]);
  return (
    <>
      <Header
        handleHomePage={handleHomePage}
        showCities={showCities}
        cityRef={cityRef}
        cityArr={cityArr}
        services={services}
        handleShowCity={handleShowCity}
        showServices={showServices}
        setShowServices={setShowServices}
        handleAboutUs={handleAboutUs}
        handleServicesOption={handleServicesOption}
        handlePhysioTherapy={handlePhysioTherapy}
        handleCareService={handleCareService}
        handleMedicalEquipment={handleMedicalEquipment}
        handleConsultation={handleConsultation}
        handleLabTest={handleLabTest}
        isHeaderVisible={isHeaderVisible}
        handleBlogs={handleBlogs}
        handleCareer={handleCareer}
        handleEvents={handleEvents}
      />
    </>
  );
};

export default HeaderContainer;
