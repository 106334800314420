import { useEffect, useState } from "react";
import AdEnquiry from "../../components/AdEnquiry/AdEnquiry";

const AdEnquiryContainer = (props) => {
  const {
    head,
    text,
    isMobile,
    inputRefs,
    otp,
    handleChange,
    handleKeyDown,
    formatTime,
    timeLeft,
    handleSendOtp,
    show,
    verifyOTP,
    setName,
    handleMobile,
    otpErr,
    mobileErr,
    bgHeight,
    bgImg,
    checked,
    setChecked,
    handlePolicy,
  } = props;

  const handleScroll = () => {
    window.scroll(0, 100);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 1);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AdEnquiry
        head={head}
        text={text}
        isMobile={isMobile}
        inputRefs={inputRefs}
        otp={otp}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        formatTime={formatTime}
        timeLeft={timeLeft}
        handleSendOtp={handleSendOtp}
        show={show}
        verifyOTP={verifyOTP}
        setName={setName}
        handleMobile={handleMobile}
        handleScroll={handleScroll}
        showButton={showButton}
        mobileErr={mobileErr}
        otpErr={otpErr}
        bgHeight={bgHeight}
        bgImg={bgImg}
        checked={checked}
        setChecked={setChecked}
        handlePolicy={handlePolicy}
      />
    </>
  );
};

export default AdEnquiryContainer;
