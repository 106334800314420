import React from "react";
import images from "../../../commons/ImagesExport";
import HeaderContainer from "../../../shared/containers/HeaderContainer/HeaderContainer";
import OurPartners from "../../../shared/components/Parteners/OurPartners";
import NewsRoom from "../../../shared/components/NewsRoom/NewsRoom";
import Testimonials from "../../../shared/components/Testimonials/Testimonials";
import Footer from "../../../shared/components/Footer/Footer";
import "./CareAtHome.css";
import Link from "react-router-dom/Link";
import { APIPostRequest } from "../../../config/HttpClient";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

function Plan(props) {
  return (
    <div className="col-md-4">
      <div className=" h-auto plan-slider-1">
        <div className="plan-heading-1">
          <h4>{props.title}</h4>
        </div>
        <div className="plan-detail-1">
          <div className="left-detail-1">
            {props.features.map((feature, index) => {
              return (
                <p key={index}>
                  <span className="bullet" />
                  {feature}
                </p>
              );
            })}
          </div>
        </div>
        <Link to={props.link}>
          <button className="selecpra-button-1">Select Plan</button>
        </Link>
      </div>
    </div>
  );
}

function CareAtHome() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const classes = useStyles();
  const handleSubmit = () => {
    setIsFetching(true);
    const data = {
      name: document.getElementById("callPopUp").querySelectorAll("input")[0]
        .value,
      email: document.getElementById("callPopUp").querySelectorAll("input")[1]
        .value,
      mobileNumber: document
        .getElementById("callPopUp")
        .querySelectorAll("input")[2].value,
      categoryName: "Elderly Care",
      subCategoryName: "Care At Home",
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    APIPostRequest(
      "/addCallSchedule.php",
      formdata,
      (res) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Your request has been submitted successfully");
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
      }
    );
  };
  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  return (
    <>
      <HeaderContainer />
      {isFetching && (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader} />
        </Backdrop>
      )}
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {window.scrollTo(0, 0)}
      <section className="parentalcontent">
        <div className="container">
          <div className="row paren-row">
            <div className="col-md-6">
              <div className="left-parental">
                <h4 className="parental-heading">Care At Home</h4>
                <ul className="parental-list">
                  {[
                    "24*7 Nursing care & attendents",
                    "Assistance in feeding/hygiene",
                    "Disabled care and rehabilitation",
                    "Semi ICU and vitals monitoring",
                    "Arrangement of medical equipments",
                  ].map((item, index) => {
                    return (
                      <li key={index} className="flex items-center">
                        <span>
                          <img
                            className="sign-img"
                            src={images.checkMark}
                            alt=""
                          />
                        </span>{" "}
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-parental">
                <div className="req-call-div" id="callPopUp">
                  <h6 className="call-back-heading">Let's Talk</h6>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        required
                        type="text"
                        placeholder="Name"
                        className="call-input"
                        minLength={3}
                        maxLength={26}
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                        placeholder="Email"
                        className="call-input"
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="[0-9]{10}"
                        placeholder="Input Phone Number"
                        minLength={10}
                        maxLength={10}
                        className="call-input"
                      />
                    </div>
                    <button type="submit" className="callbackbtn">
                      Request a Call Back
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="enroll-h-pragnancy-1">
        <div className="container" style={{ padding: "15px 0px" }}>
          <div className="flex-wrap main-health">
            <div className="xs:w-full md:w-auto">
              <div className="health-1-1 w-full">
                <h5>Enroll For Home Care</h5>
              </div>
            </div>
            <div className="flex flex-wrap justify-around flex-1">
              {[
                {
                  image: images.homec1,
                  name: "Trained Attendants",
                },
                {
                  image: images.homec2,
                  name: "Rehabilitation Therapy",
                },
                {
                  image: images.homec3,
                  name: "Semi ICU At Home",
                },
                {
                  image: images.homec4,
                  name: "Equipment On Rent",
                },
              ].map((item, index) => {
                return (
                  <div className="xs:w-full md:w-1/4" key={index}>
                    <div className="flex flex-col items-center">
                      <div className="health-preg-image">
                        <img src={item.image} alt="" />
                      </div>
                      <p className="healthdes-para-1">{item.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/*planns section*/}
      <div className="main-plan">
        <h4 className="sliderheading">OUR PLANS</h4>
        <div className="container">
          <div className="row">
            <Plan
              title="Virtual Care"
              features={[
                "Dedicated Care manager",
                "Well -Being Call ( Morning / Evening )",
                "Electronic Health Records",
                "Medication reminder",
                "Doctor Consultation - 1",
                "Diet Consultation - 1",
              ]}
              link="/elderlyCare/virtualCare"
            />
            <Plan
              title="Comprehensive Care"
              features={[
                "Dedicated Care manager",
                "Electronic Health Records",
                "Full Body Check-up",
                "Doctor Consultation - 1",
                "Dietician Consultation - 1",
                "Physiotherapy Session - 1",
                "Emergency Coordination and Assistance",
                "Emotional wellness",
              ]}
              link="/elderlyCare/comprehensiveCare"
            />
            <Plan
              title="Companion Care"
              features={[
                "Trained attendant at home",
                "Vitals monitoring",
                "Health &amp; wellness",
                "Hygiene &amp; feeding Assistance",
                "Recreational activities",
                "Medication reminder",
                "Companionship",
                "Home security and automation",
              ]}
              link="/elderlyCare/companionCare"
            />
          </div>
        </div>
      </div>
      <OurPartners />
      <NewsRoom
        images={[
          images.ElderNews1,
          images.ElderNews2,
          images.ElderNews3,
          images.ElderNews4,
          images.ElderNews1,
        ]}
      />
      <Testimonials />
      <Footer />
    </>
  );
}

export default CareAtHome;
