import { useState } from "react";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useLocation } from "react-router-dom";
import HeaderDrawer from "../../components/Header/HeaderDrawer";
import {
  ABOUT_US_PAGE,
  CARE_GIVER_SERVICE_PAGE,
  ELDERLY_CARE_SERVICE_PAGE,
  GENERAL_WELLNESS_SERVICE_PAGE,
  MEDICAL_EQUIPMENT_SERVICE_PAGE,
  OPERATIVE_CARE_SERVICE_PAGE,
  PHYSIO_THERAPY_SERVICE_PAGE,
  PREGNANCY_CARE_SERVICE_PAGE,
  SAFETY_TRAINING_SERVICE_PAGE,
} from "../../../routes/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MobileHeaderContainer = () => {
  const location = useLocation();
  const [showCities, setShowCities] = useState(false);
  const history = useHistory();
  const handleShowCity = () => {
    setShowCities(!showCities);
  };

  const cityArr = [
    "Delhi",
    "Gurgaon",
    "Noida",
    "Faridabad",
    "Manesar",
    "Ghaziabad",
  ];

  const options = [
    "Physiotherapy at Home",
    "Caregiver at Home",
    "Medical Equipment at Home",
    "Consultation at Home",
    "Lab Test at Home",
    "Call us at: 809-9110-931",
  ];

  const path = location.pathname;

  console.log(path);

  const [showDrawer, setShowDrawer] = useState(false);

  const handleDrawer = () => {
    setShowDrawer(true);
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const handleHomePage = () => {
    history.push("/");
  };

  const handleAbout = () => {
    history.push("/about-us");
  };

  const handleBlogs = () => {
    history.push("/blogs");
  };

  const handleElderlyCare = () => {
    history.push(ELDERLY_CARE_SERVICE_PAGE);
  };

  const handleSafetyCare = () => {
    history.push(SAFETY_TRAINING_SERVICE_PAGE);
  };

  const handlePregnancyCare = () => {
    history.push(PREGNANCY_CARE_SERVICE_PAGE);
  };

  const handleGeneralWellness = () => {
    history.push(GENERAL_WELLNESS_SERVICE_PAGE);
  };

  const handleOperativeCare = () => {
    history.push(OPERATIVE_CARE_SERVICE_PAGE);
  };

  return (
    <>
      <MobileHeader
        showCities={showCities}
        cityArr={cityArr}
        handleShowCity={handleShowCity}
        options={options}
        handleDrawer={handleDrawer}
        path={path}
      />

      {showDrawer && (
        <HeaderDrawer
          showDrawer={showDrawer}
          handleDrawer={handleDrawer}
          handleDrawerClose={handleDrawerClose}
          handleHomePage={handleHomePage}
          handleElderlyCare={handleElderlyCare}
          handleSafetyCare={handleSafetyCare}
          handlePregnancyCare={handlePregnancyCare}
          handleGeneralWellness={handleGeneralWellness}
          handleOperativeCare={handleOperativeCare}
          handleAbout={handleAbout}
          handleBlogs={handleBlogs}
        />
      )}
    </>
  );
};

export default MobileHeaderContainer;
