import React from "react";
import images from "../../commons/ImagesExport";
import "./RoadSafety.css";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import CallBackPopUp from "../../shared/components/CallBackPopUpPage/CallBackPopUp";

function RoadSafety() {
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  return (
    <div>
      <HeaderContainer />
      {window.scrollTo(0, 0)}
      <div className="container">
        <div className="spacing-road">
          <h2 className="main-heading-road">ROAD SAFETY</h2>
          <p className="safety-para-road">
            WHO estimates that 1.3 million people die, 20 – 50 million gets
            injured in road accidents globally every year. India records 80,000
            deaths annually in road crashes, there is a road accident every
            minute, whereas one fatal accident takes lives every four minutes.
          </p>
          <p className="safety-para-road">
            Ministry of Road Transport &amp; Highways attributes 2 reasons for
            the accidents:
          </p>
          <p className="safety-para-road">1. Carelessness of the driver</p>
          <p className="safety-para-road">
            2. Lack of road safety awareness methods and techniques.
          </p>
          <p className="safety-para-road">
            With the number of vehicles on our roads increasing with every
            passing hour, it’s of vital importance for everyone to have traffic
            awareness and understand and respect all the road safety rules in
            India.
          </p>
          <p className="safety-para-road">
            Since road accidents involves roads, motor vehicles and human
            beings, hence it needs overall development of the strategies and
            interventions at multi - functional levels.
          </p>
        </div>
        <div className="spacing-road">
          <h2 className="main-heading-road">
            REASONS THAT HAMPER ROAD SAFETY ISSUES
          </h2>
          <p className="safety-para-road">
            Road safety in India is very poor. Despite several government rules
            and regulations, there are mishaps and accidents. Here are some of
            the issues:{" "}
          </p>
          <div className="spacing">
            <img src={images.roadSafetyMap} alt="" style={{ width: "100%" }} />
          </div>
          <div className="spacing-road">
            <h2 className="main-heading-road">
              CONSEQUENCES OF ROAD ACCIDENTS
            </h2>
            <ul className="roadsafety-list-road">
              <li>
                Around 400 persons are dying every day in road accident deaths
                in India
              </li>
              <li>
                Young males under the age group of 25 years are dying more than
                young females.
              </li>
              <li>
                Kids and youngsters (14 to 29 years age) are dying more in road
                crashes.
              </li>
              <li>
                Loss of Bread earning member to a family &amp; household
                productivity loss.
              </li>
              <li>
                Huge financial loss in road crash victims emergency and medical
                treatment.
              </li>
              <li>
                Workplace productivity loss due to non-reporting of employee.
              </li>
              <li>Loss to business because of untimely dispatch.</li>
              <li>Loss to insurance companies.</li>
              <li>Traffic Congestion &amp; inconvenience.</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety1} alt="" />
                </div>
                <h6>Safe Driving Practices &amp; SOP’s</h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety2} alt="" />
                </div>
                <h6>
                  Traffic Rules and Regulations – Traffic Offences, Fines, Road
                  Signs &amp; Markings
                </h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety3} alt="" />
                </div>
                <h6>
                  Traffic Violations &amp; Offences Familiarisation with Central
                  Motor Vehicle Act, 1988.
                </h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety4} alt="" />
                </div>
                <h6>Personal &amp; Medical aptitude for safe driving</h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety5} alt="" />
                </div>
                <h6>
                  Factors influencing driving – stress, alcohol, drugs &amp;
                  mobile phone use.
                </h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety6} alt="" />
                </div>
                <h6>
                  Driving under difficult and potentially dangerous conditions –
                  weather, terrain, traffic, visibility.
                </h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road-road">
                  <img src={images.roadSafety7} alt="" />
                </div>
                <h6>Defensive Driving &amp; Road Rage avoidance.</h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety8} alt="" />
                </div>
                <h6>
                  Use of ISI conformed headgear, reflective tapes for drivers of
                  2 wheelers and Safety protocols for drivers of 4 wheelers.
                </h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="engage-main-road">
                <div className="engageimg-div-road">
                  <img src={images.roadSafety9} alt="" />
                </div>
                <h6>Preventive and breakdown maintenance</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="booknewawssion-road">
        <div className="container-road">
          <p className="sessioncall-para-road">What are you waiting for?</p>
          <h4 className="sessioncall-heading-road">Book A Session Now</h4>
          <button
            onClick={() => setDisplayPopUp(true)}
            className="sessioncall-btn-road"
          >
            Schedule A Call
          </button>
        </div>
      </section>
      <Footer />
      <CallBackPopUp
        displayPopUp={displayPopUp}
        setDisplayPopUp={setDisplayPopUp}
      />
    </div>
  );
}

export default RoadSafety;
