import { useContext, useEffect, useState } from "react";
import BlogDetailPage from "../../components/BlogDetailPage/BlogDetailPage";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import dayjs from "dayjs";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import { useLocation } from "react-router-dom";
import { APIPostRequest } from "../../config/HttpClient";
const BlogDetailContainer = () => {
  const { state, dispatch } = useContext(Context);
  const [blogData, setBlogData] = useState();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showDateDialog, setShowDateDialog] = useState(false);
  const [showTimeDialog, setShowTimeDialog] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const location = useLocation();
  const getQueryParams = (search) => {
    return Object.fromEntries(new URLSearchParams(search));
  };

  const queryParams = getQueryParams(location.search);
  const blogId = queryParams.id ? queryParams.id : null;
  useEffect(() => {
    var formData = new FormData();
    formData.append("data", `{"id":"${blogId}"}`);
    APIPostRequest(
      "/fetchBlogDetails.php",
      formData,
      (response) => {
        setBlogData(response?.blogs[0]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  console.log(blogData?.blogDetails);

  const handleDateDialog = () => {
    setShowDateDialog(!showDateDialog);
  };

  const handleTimeDialog = () => {
    setShowTimeDialog(!showTimeDialog);
  };

  const handleDateChange = (newDate) => {
    const formattedDate = dayjs(newDate);
    setSelectedDate(formattedDate);
    setShowDateDialog(!showDateDialog);
  };

  const timeSlot = [
    "09:00 AM - 12:00 PM",
    "12:00 PM - 03:00 PM",
    "03:00 PM - 06:00 PM",
    "06:00 PM - 09:00 PM",
  ];

  const [selectedSlot, setSelectedSlot] = useState("09:00 AM - 12:00 PM");
  const [mobileErr, setMobileError] = useState("");

  const handleSlot = (i) => {
    setSelectedSlot(i);
    setShowTimeDialog(!showTimeDialog);
  };

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const handleForm = () => {};

  const newArr = blogData?.blogDetails;
  const insertNameKey = (ar) => {
    const result = [];
    let pushCount = 0;
    for (let i = 0; i < ar?.length; i++) {
      result.push(ar[i]);
      if ((i + 1) % 2 === 0 && pushCount < 2) {
        result.push({ heading: "", data: [], image: blogData?.secondaryImage });
        pushCount++;
      }
    }
    return result;
  };

  const updatedArr = insertNameKey(newArr);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <BlogDetailPage
        isMobile={selectIsMobile(state)}
        pageData={blogData}
        updatedArr={updatedArr}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default BlogDetailContainer;
