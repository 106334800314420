import React from 'react'
import FeatureBasePage from '../../shared/components/FeatureBasePage/FeatureBasePage'
import images from '../../commons/ImagesExport'
import Slider from 'react-slick';

function Sliders(images) {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 10000,
      centerPadding: "15px",
      cssEase: "linear",
      arrows: false,
  
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    const settings2 = {
      ...settings,
      slidesToShow: 3,
      autoplaySpeed: 2000,
    };
    return (
      <Slider {...settings2} className="flex justify-center items-center">
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt="image" className="m-auto" />
          </div>
        ))}
      </Slider>
    );
  }      

function DoctorConsultation() {
  return (
    <FeatureBasePage
    featureImage={images.DoctorVideo}
    name="Doctor Consultation"
    intro1={images.DoctorIntro1}
    intro2={images.DoctorIntro2}
    intro3={images.DoctorIntro3}
    intro4={images.DoctorIntro4}
    intro5={images.DoctorIntro5}
    intro6={images.DoctorIntro6}
    intro7={images.DoctorIntro7}
    introName1="Instant access to specialist"
    introName2="Secure and convenient consultations."
    introName3="Cost effective & time saving"
    introName4="Least chance of acquiring infections"
    introName5="Seamless virtual consultation across multiple specialists"
    introName6="Hassle-free scheduling & better patient management"
    introName7="Integrated diagnostics & Pharmacy on single platform"
    ServiceInformation={Sliders([images.DoctorIntro8, images.DoctorIntro9, images.DoctorIntro10, images.DoctorIntro11])}
    support={images.DoctorSupport}
    supportText="In matters of health, we recommend consulting only the finest doctors around. However, searching for
    doctors online can prove to be a time consuming and ineffective process. It lacks the personal touch of
    doctor-patient communication. Moreover, the online presence of a doctor is a vague and inaccurate parameter
    for judging a doctor’s merit and ability. Heka Healthy You boasts of an elaborate connection of the most
    elite doctors in town. We help you eliminate the search and connect you to the best doctors to ensure you
    get the consultation you desire."
    serviceBannerName="How it works"
    serviceBannerImage1={images.DoctorPlan1}
    serviceBannerImage2={images.DoctorPlan2}
    serviceBannerImage3={images.DoctorPlan3}
    serviceBannerImage4={images.DoctorPlan4}
    serviceBannerText1="Make Registration"
    serviceBannerText2="Pay the fees"
    serviceBannerText3="Start Consultation"
    serviceBannerText4="Get Digital Prescription"
    faqs={[
      {
        question: "What is online doctor consultation?",
        answer:
          "Online doctor consultation, also known as online medical consultation, is a method of virtually connecting patients and doctors. It is a simple and convenient way to obtain online doctor advice using doctor apps, telemedicine apps, or platforms, and the internet.",
      },
      {
        question:
          "Who are the consulting doctors?",
        answer:
          "All of the doctors are licenced medical practitioners. Along with qualifying degrees, experience, research, and a track record of practise are all considered before a doctor is credentialed with Heka Healthy you and given access to answer patient questions.",
      },
      {
        question: "Will the doctor be able to help me with my problem?",
        answer:
          "Our doctors will provide expert advice on your problem and assist you in determining the next steps, which may include additional tests, medication recommendations, or lifestyle changes. Few cases necessitate a face-to-face examination, and we do ask patients to share pictures or reports if possible in order to make an accurate diagnosis.",
      },
      {
        question: "Is my consultation private with my doctor?",
        answer:
          "Data privacy is a fundamental human right, and we at Heka Healthy You recognise it. Your medical history, as well as your online consultation with us, are completely private and confidential. We adhere to industry standards to ensure the security of your consultations.",
      },
      {
        question: "For how long is the consultation valid?",
        answer:
          "In the case of a paid consultation, you may consult with your doctor for up to three days. If you choose a free consultation, follow-up questions are only valid for one day.",
      },
      {
        question: "What happens if I don’t get a response from a doctor?",
        answer:
          "If an online doctor does not respond, you will be entitled to a full refund.",
      },
    ]}
    newsRoomImages={[
      images.DoctorNews1,
      images.DoctorNews2,
      images.DoctorNews3,
      images.DoctorNews4,
      images.DoctorNews1
    ]}
    />
  )
}

export default DoctorConsultation