import React, { useContext, useState } from "react";
import { Context } from "../../store/Store";
import { selectIsMobile } from "../../store/global/Global.selectors";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import EventPage from "../../components/EventsPage/EventPage";
import { APIGetRequest } from "../../config/HttpClient";
import { useEffect } from "react";
const EventPageContainer = () => {
  const { state, dispatch } = useContext(Context);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleIndex = (i) => {
    setSelectedIndex(i);
  };

  const dropDownArr = [
    {
      label: "Event name and details and more",
      text: "hriowndn it is an amzing place for amazing care and specialities, i loved mhriowndn. It is an amzing place for amazing care and specialities, i loved my timey timehriowndn it is an amzing place for amazing care and specialities, i loved mhriowndn. It is an amzing place for amazing care and specialities, i loved my timey time.",
    },
    {
      label: "How long does a physiotherapy session last?",
      text: "Our caregivers undergo First Aid and CPR training to manage home emergencies effectively. Your loved one's safety is our utmost priority, supported by comprehensive safety training.",
    },
    {
      label: "Why should I consult a physiotherapist?",
      text: "We understand your needs may be unique. Connect with our Health Manager at 8010-711-010 to tailor a personalized plan.",
    },
    {
      label: "Who will decide my plan of treatment?",
      text: "We strive to initiate services promptly. With a 12-hour lead time, we ensure a smooth start to prioritize your loved one's well-being and comfort.",
    },
    {
      label: "Do I need a daily treatment session?",
      text: "In case of illness, we have trained backup caregivers ready to ensure continuity of care without interruption.",
    },
  ];

  const [arr, setArr] = useState([]);

  useEffect(() => {
    APIGetRequest(
      "/fetchAllEvents.php",
      (response) => {
        setArr(response?.events);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <EventPage
        isMobile={selectIsMobile(state)}
        selectedIndex={selectedIndex}
        handleIndex={handleIndex}
        arr={arr}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default EventPageContainer;
