import React, { createRef } from "react";
import "./ConsultationComponent.css";
import img from "../../assets/consultationImages/consultation Image.png";
import img1 from "../../assets/physiotherapyImages/How IS work.png";
import whyChooseUsImgMobile from "../../assets/physiotherapyImages/How Instant Services work mobile.png";
import mobileImgMain from "../../assets/consultationImages/Frame 427319570.jpg";
import whiteImg1 from "../../assets/physiotherapyImages/Rectangle 2586.png";
import whiteImg from "../../assets/physiotherapyImages/Rectangle 2586 (1).png";
import doctor1 from "../../assets/consultationImages/Doctors.svg";
import doctor2 from "../../assets/consultationImages/Doctors (1).svg";
import doctor3 from "../../assets/consultationImages/Doctors (2).svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ConsultationComponent = (props) => {
  const { isMobile, arr, handleEnquireNow } = props;

  const doctors = [doctor1, doctor2, doctor3];

  const mySlider = createRef();

  const setting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const alignDoctors = () => {
    return doctors.map((doc, index) => {
      return (
        <img
          src={doc}
          alt={`doctor${index}`}
          style={isMobile ? {} : { width: "30%" }}
        />
      );
    });
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />
          <div className="consultationPageContainer1Content">
            <h1
              style={{ textAlign: "center" }}
              className="consultationPageContainer1Content-Head"
            >
              Consultations at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Personalized consultations at home: Access expert <br /> medical
              advice conveniently, ensuring comprehensive <br /> care and
              comfort in familiar surroundings.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Consultations at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, get access to expert medical advice conveniently,
              ensuring <br /> comprehensive care and comfort in familiar
              surroundings.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      {/* <div
        style={
          isMobile ? { padding: "32px 0px 0px 0px" } : { padding: "32px 0px" }
        }
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
      </div> */}

      <img
        src={isMobile ? whyChooseUsImgMobile : img1}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2rem auto" }
            : {
                width: "78%",
                display: "block",
                margin: " 3rem auto",
                marginTop: "5rem",
              }
        }
      />

      {/* <div
        style={isMobile ? {} : { padding: "32px 0px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Our Doctors</h4>
      </div>

      {isMobile ? (
        <Slider ref={mySlider} arrows={false} {...setting}>
          {alignDoctors()}
        </Slider>
      ) : (
        <div className="consultationPageContainer2">{alignDoctors()}</div>
      )} */}

      <div
        style={isMobile ? {} : { padding: "32px 0px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Why Consult from Heka Health?
        </h4>
      </div>

      <div className="careGiverPageContainer3">
        {arr.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img src={item.arrImg} className="physioPageContainer3-cardImg" />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={whiteImg}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}

                <div className="careGiverPageContainer3-card-div1">
                  <h5 className="careGiverPageContainer3-cardHead">
                    {item.head}
                  </h5>
                  <p className="careGiverPageContainer3-cardText2">
                    {item.text}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ConsultationComponent;
