const ApiConfig = {
  baseURL: "https://zylicontechnologies.com/hekaAPI/userAPI",
};

const headers = {
  // "Content-Type": "application/json",
};

export const APIGetRequest = (api, success, failure, header) => {
  const apiURL = ApiConfig.baseURL + api;
  console.log({ apiURL });
  fetch(apiURL, {
    method: "GET",
    headers: { ...headers },
  })
    .then((response) => {
      if (response.ok) {
        console.log("Response OK!", response);
        return response.json();
      }
      throw new Error("Something went wrong");
    })
    .then((data) => {
      success(data);
    })
    .catch((error) => {
      console.log("Error in GET", error);
      failure(error);
    });
};

export const APIPostRequest = (api, params, success, failure, header) => {
  const apiURL = ApiConfig.baseURL + api;
    console.log("cordova false");
    fetch(apiURL, {
      method: "POST",
      headers: { ...headers },
      body: params,
    })
      .then((response) => {
        response
          .json()
          .then(function (data) {
            // console.log("In the then", response);
            if ((data.apiStatus >= 200 && data.apiStatus <= 299) || data.code>=200 && data.code<=299) {
              // console.log("In the IF", data);
              success(data);
            } else {
              // console.log("inside else ", data.code);
              failure({ data, status: response.status });
            }
          })
          // .catch(() => {
          //   console.log("Inside catch", response);
          //   if (response.code >= 200 && response.code <= 299) {
          //     success({});
          //   } else {
          //     failure({
          //       data: { message: response.statusText },
          //       status: response.status,
          //     });
          //   }
          // });
      })
      .catch((error) => {
        failure(error);
      });
};

