import { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import img1 from "../../assets/physiotherapyImages/Frame 427319361.svg";
import img2 from "../../assets/physiotherapyImages/Frame 427319360.svg";
import img3 from "../../assets/physiotherapyImages/Frame 427319358.svg";
import img4 from "../../assets/physiotherapyImages/Frame 427319359.svg";
import img5 from "../../assets/physiotherapyImages/Frame 427319357.svg";
import img6 from "../../assets/physiotherapyImages/Group 35641.svg";
import PhysiotherapyServicePage from "../../components/PhysiotherapyServicePage/PhysiotherapyServicePage";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img7 from "../../assets/physiotherapyImages/Frame 427319338.svg";
import img8 from "../../assets/physiotherapyImages/Frame 427319339.svg";
import img9 from "../../assets/physiotherapyImages/Frame 427319337.svg";
import img10 from "../../assets/physiotherapyImages/Frame 427319340.svg";
import img11 from "../../assets/physiotherapyImages/Frame 427319341.svg";
import img12 from "../../assets/physiotherapyImages/Frame 427319342.svg";
import img13 from "../../assets/physiotherapyImages/Frame 427319343.svg";
import img14 from "../../assets/physiotherapyImages/Frame 427319344.svg";
import img15 from "../../assets/physiotherapyImages/mobile/Frame 427319351.svg";
import img16 from "../../assets/physiotherapyImages/mobile/Frame 427319337.svg";
import img17 from "../../assets/physiotherapyImages/mobile/Frame 427319347.svg";
import img18 from "../../assets/physiotherapyImages/mobile/Frame 427319348.svg";
import img19 from "../../assets/physiotherapyImages/mobile/Frame 427319349.svg";
import img20 from "../../assets/physiotherapyImages/mobile/Frame 427319350.svg";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import testimonialsImage from "../../assets/physiotherapyImages/Rectangle 2660.svg";
import Testimonials from "../../components/Testimonials/Testimonials";

const PhysiotherapyServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "6";

  const arr = [
    {
      arrImg: img1,
      head: "Balance and coordination",
      text: "Our physiotherapy improves balance and coordination, reducing falls and enhancing mobility.",
    },
    {
      arrImg: img2,
      head: "Women's wellness",
      text: "We provide specialised care for women's health, empowering with personalised treatments and education for vitality.",
    },

    {
      arrImg: img3,
      head: "Pain management",
      text: "Alleviate discomfort and enhance quality of life without relying solely on medication.",
    },
    {
      arrImg: img4,
      head: "Gain Flexibility",
      text: "Enhance mobility, alleviate pain, and improve function through physiotherapy techniques.",
    },
    {
      arrImg: img5,
      head: "Surgical recovery",
      text: "Facilitate faster healing and regain full functional control of everything post-operation.",
    },
    {
      arrImg: img6,
      head: "Physical optimization",
      text: "Enhance performance and prevent injuries for a healthier, more active lifestyle.",
    },
  ];

  const arr1 = [
    {
      arrImg: img7,
      head: "neurological Physiotherapy",
      text: "Restore movement and independence post-nervous system injuries or conditions, enhancing life quality.",
    },
    {
      arrImg: img8,
      head: "Pre & Post Surgical Physiotherapy",
      text: "Prepare for surgery and expedite recovery with targeted interventions.",
    },

    {
      arrImg: img9,
      head: "Cardiopulmonary Physiotherapy",
      text: "Enhance cardiovascular function and respiratory efficiency, fostering better health.",
    },
    {
      arrImg: img10,
      head: "Orthopaedic/ Musculoskeletal Physiotherapy",
      text: "Alleviate pain, improve mobility, and aid recovery for muscle, bone, or joint issues.",
    },
    {
      arrImg: img11,
      head: "Geriatric Physiotherapy",
      text: "Improve mobility, balance, and strength in seniors, promoting independence and well-being.",
    },
    {
      arrImg: img12,
      head: "Sports Physiotherapy",
      text: "Enhance athletic performance, prevent injuries, and expedite recovery for athletes.",
    },
    {
      arrImg: img13,
      head: "Oncological Physiotherapy",
      text: "Support cancer patients with tailored exercises, managing symptoms, and improving well-being.",
    },
    {
      arrImg: img14,
      head: "Physiotherapy for Women",
      text: "Address women's health concerns with specialized care, promoting optimal well-being.",
    },
  ];

  const arr2 = [
    {
      arrImg: img16,
      head: "Cardiopulmonary Physiotherapy",
      text: "Address heart and lung conditions through tailored exercises and breathing techniques to improve cardiovascular function, endurance, and respiratory efficiency, enhancing overall health and well-being.",
    },
    {
      arrImg: img15,
      head: "Geriatric Physiotherapy",
      text: "Enhance mobility, balance, and strength in older adults through personalised exercises and fall prevention strategies, promoting independence and ensuring a higher quality of life in later years.",
    },
    {
      arrImg: img17,
      head: "Sports Physiotherapy",
      text: "Optimise athletic performance and prevent injuries with specialised exercises, biomechanical analysis, and injury prevention strategies, facilitating faster recovery and enabling athletes to reach their full potential.",
    },
    {
      arrImg: img18,
      head: "Oncological Physiotherapy",
      text: "Support cancer patients through personalised exercises and symptom management techniques to improve mobility, reduce pain, and enhance overall well-being during and after cancer treatment.",
    },
    {
      arrImg: img19,
      head: "Pre & Post Surgical Physiotherapy",
      text: "Prepare patients for surgery with pre-operative exercises and education, and facilitate faster recovery and rehabilitation post-surgery with targeted interventions to restore function and mobility.",
    },
    {
      arrImg: img20,
      head: "Physiotherapy for Women",
      text: "Address specific women's health concerns such as pregnancy-related issues, pelvic floor dysfunction, and postpartum recovery through specialised exercises and manual therapy, promoting optimal health and well-being at every stage of life.",
    },
  ];

  const dropDownArr = [
    {
      label: "What services are included in home physiotherapy?",
      text: "Our services encompass personalised treatment plans, tailored exercises, and educational support designed to facilitate optimal recovery in the comfort of your home.",
    },
    {
      label: "How long is a typical physiotherapy session?",
      text: "Sessions typically range from 45 minutes to an hour, adjusted based on individual needs and treatment objectives.",
    },
    {
      label: "Why consult a physiotherapist?",
      text: "Physiotherapists evaluate, diagnose, and treat musculoskeletal conditions, aiming to improve mobility, reduce pain, and enhance overall quality of life through specialised interventions.",
    },
    {
      label: "Who decides my treatment plan?",
      text: "A qualified physiotherapist, in collaboration with our Health Manager, assesses your condition and collaborates with you to develop a personalised treatment plan aligned with your specific goals and needs.",
    },
    {
      label: "Do I need daily treatment sessions?",
      text: "The frequency of treatment sessions depends on individual requirements and the severity of the condition. Your physiotherapist will recommend a suitable treatment schedule to achieve optimal results.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <PhysiotherapyServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        arr2={arr2}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default PhysiotherapyServiceContainer;
