import React from "react";
import img2 from "./Elderly BG Image.svg";
import "./OperativeCareServicePage.css";
import whyChooseUsImg from "./Why Choose Us 1.svg";
import img3 from "../../assets/operativeCareImages/operative 1.svg";
import img4 from "../../assets/operativeCareImages/operative 2.svg";
import img5 from "../../assets/operativeCareImages/operative 3.svg";
import img6 from "../../assets/operativeCareImages/operative 4.svg";
import whyChooseUsImg1 from "./why choose us final mobile.svg";
import mobileImgMain from "./Image & BG.svg";

const OperativeCareServicePage = (props) => {
  const { isMobile, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Post-Operative Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our comprehensive post-operative care <br />{" "}
              supports patient recovery with medical and emotional <br />{" "}
              support throughout.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img
            src={img2}
            className="consultationPageImg1"
            style={{ width: "100%" }}
          />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Post-Operative Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our comprehensive post-operative care supports
              patient <br /> recovery with medical and emotional support
              throughout.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img
        src={isMobile ? whyChooseUsImg1 : whyChooseUsImg}
        style={
          isMobile
            ? { display: "block", margin: "auto" }
            : { width: "100%", margin: "6rem 0rem" }
        }
      />

      <h4
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMain"
      >
        Your Journey With Us
      </h4>

      {isMobile ? (
        <div className="operativeCarePageContainer3">
          <img src={img3} />
          <img src={img5} />
          <img src={img4} />
          <img src={img6} />
        </div>
      ) : (
        <div className="operativeCarePageContainer3">
          <img style={{ width: "40%" }} src={img3} />
          <img style={{ width: "40%" }} src={img4} />
          <img style={{ width: "40%" }} src={img5} />
          <img style={{ width: "40%" }} src={img6} />
        </div>
      )}
    </>
  );
};

export default OperativeCareServicePage;
