import React from "react";
import "./ComingSoon.css";
import images from "../../../commons/ImagesExport";
import HeaderContainer from "../../containers/HeaderContainer/HeaderContainer";
import Footer from "../Footer/Footer";
function ComingSoon() {
  return (
    <div>
      <HeaderContainer />
      <section className="comingsoon">
        <h2 className="wrapper-heading">Services Launching Soon</h2>
        <p className="werapper-para">
          We’re working <br /> hard to give you the best experience!
        </p>
        <img className="werapper-image" src={images.comingSoon} alt="" />
      </section>
      <Footer />
    </div>
  );
}

export default ComingSoon;
