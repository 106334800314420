import React, { useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Backdrop, makeStyles } from "@material-ui/core";
import { APIPostRequest } from "../../../config/HttpClient";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";
import images from "../../../commons/ImagesExport";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));
function EnquiryForm(props) {
  const { setEnquiryModal, enquiryModal } = props;
  const [lookingFor, setLookingFor] = React.useState(["Looking For"]);
  const [referredFor, setRefferedFor] = React.useState(["Referred By"]);
  const [isFetching, setIsFetching] = React.useState(false);
  const today = new Date().toLocaleDateString("en-IN", {
    timeZone: "Asia/Kolkata",
  });
  const [currDate, setCurrDate] = React.useState();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const classes = useStyles();
  const services = [
    "Safety Training",
    "Elderly Care",
    "Pregnancy Care",
    "Operative Care",
    "Medical Astrology",
    "Doctor Consultation",
    "Lab test",
    "Pharmacy",
    "General Wellness",
    "Overseas Services",
  ];

  const referrer = [
    "Doctor",
    "Friend",
    "Social Media",
    "Advertisement",
    "Others",
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleLookingFor = (event) => {
    let {
      target: { value },
    } = event;
    value = value.filter((item) => item !== "Looking For");
    setLookingFor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleRefferedFor = (event) => {
    let {
      target: { value },
    } = event;
    value = value.filter((item) => item !== "Referred By");
    setRefferedFor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (lookingFor.length === 0 || lookingFor[0] === "Looking For") {
      window.alert("Please select Looking For");
      return;
    } else if (referredFor.length === 0 || referredFor[0] === "Referred By") {
      window.alert("Please select Referred By");
      return;
    }
    setIsFetching(true);

    const data = {
      name: document.getElementById("enquiredName").value,
      mobileNumber: document.getElementById("enquiredMobile").value,
      lookingFor: lookingFor,
      preferredTime: document.getElementById("enquiredTime").value,
      referredBy: referredFor,
      preferredDate: currDate.toLocaleDateString("en-IN", {
        timeZone: "Asia/Kolkata",
      }),
      email: document.getElementById("enquiredEmail").value,
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    APIPostRequest(
      "/addEnquiry.php",
      formdata,
      (response) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Enquiry Submitted Successfully");
        // window.alert("Enquiry Submitted Successfully");
        // setEnquiryModal(false);
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
        console.log(err);
        // window.alert("Something went wrong");
      }
    );
  };

  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <div className=" fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-[1000] bg-[#00000070]">
      {isFetching && (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader} className={classes.progressbar} color="primary" />
        </Backdrop>
      )}
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => {setSuccess(false); setEnquiryModal(false)}}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      <div className=" modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="modal-basic-title">
            Enquiry Now
          </h4>
          <button
            type="button"
            onClick={() => {
              setEnquiryModal(false);
            }}
            className="btn-close"
            aria-label="Close"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div>
              <input
                required
                id="enquiredName"
                type="text"
                placeholder="Name"
                minLength={3}
                maxLength={30}
                className="commin-inputbox"
              />
            </div>
            <div>
              <input
                required
                id="enquiredEmail"
                type="tel"
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                placeholder="Email Id"
                className="commin-inputbox"
              />
            </div>
            <div>
              <input
                required
                type="tel"
                pattern="[0-9]{10}"
                id="enquiredMobile"
                placeholder="+ 91 | Mobile No"
                className="commin-inputbox"
                maxLength={10}
                minLength={10}
              />
            </div>
            <div className="relative">
              {/* <InputLabel id="demo-multiple-checkbox-label" className="">
              Looking For
            </InputLabel> */}
              <Select
                required
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                className="min-w-full"
                value={lookingFor}
                onChange={handleLookingFor}
                input={<OutlinedInput label="Looking For" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {services.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={lookingFor.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <DatePicker
                required
                id="preferredDay"
                placeholder="Prefered Day to Contact"
                className="commin-inputbox"
                minDate={
                  new Date(
                    today.split("/")[2],
                    Number(today.split("/")[1]) - 1,
                    today.split("/")[0]
                  )
                }
                onChange={(date) => {
                  setCurrDate(date);
                }}
                value={currDate}
                // value={new Date(today)}
                minDateMessage="Invalid Date"
                maxDateMessage="Invalid Date"
                disablePast
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                autoOk
              />
            </div>
            <div>
              <select
                required
                id="enquiredTime"
                placeholder="Prefered Time to Contact"
                className="commin-inputbox"
              >
                <option value="9 - 12 a.m.">9 - 12 a.m.</option>
                <option value="12 - 3 p.m.">12 - 3 p.m.</option>
                <option value="3 - 6 p.m.">3 - 6 p.m.</option>
                <option value="6 - 9 p.m.">6 - 9 p.m.</option>
              </select>
            </div>
            <div>
              <div className="relative">
                {/* <InputLabel id="demo-multiple-checkbox-label" className="">
              Looking For
            </InputLabel> */}
                <Select
                  required
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  className="min-w-full"
                  value={referredFor}
                  onChange={handleRefferedFor}
                  input={<OutlinedInput label="Reffered By" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {referrer.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={referredFor.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="hsubmitbtn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EnquiryForm;
