import React from "react";
import images from "../../commons/ImagesExport";
import FeatureBasePage from "../../shared/components/FeatureBasePage/FeatureBasePage";
import Slider from "react-slick";
import Link from "react-router-dom/Link";

function Card(props) {
  return (
    <div className="plan-slider">
      <div className="plan-heading">
        <h4>{props.title}</h4>
      </div>
      <div className="plan-detail">
        <div className="left-detail">
          {props.bullets.map((bullet, index) => (
            <p>
              <span className="bullet" />
              {bullet}
            </p>
          ))}
        </div>
      </div>
      <Link to={`/safetyTraining/${props.link}`}>
        <button className="selecpra-button">Select Plan</button>
      </Link>
    </div>
  );
}

function ServiceInformation() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: true,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    ...settings,
    slidesToShow: 3,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings2} className="flex justify-center items-center">
      <div>
        <Link to="/safetyTraining/firstAid" className="no-underline">
          <img src={images.firstAidService} className="m-auto" alt="" />
          <p className="text-center no-underline text-black"> BLS Training & First Aid </p>
        </Link>
      </div>
      <div>
        <Link to="/safetyTraining/electricalSafety" className="no-underline">
          <img src={images.electricalSafetyService} className="m-auto" alt="" />
          <p className="text-center no-underline border-none text-black"> Electrical Safety</p>
        </Link>
      </div>
      <div>
        <Link to="/safetyTraining/roadSafety" className="no-underline">
          <img src={images.roadSafetyService} className="m-auto" alt="" />
          <p className="text-center no-underline text-black"> Road Safety</p>
        </Link>
      </div>
      
    </Slider>
  );
}

function SafetyTraining() {
  return (
    <FeatureBasePage
      featureVideo={"https://zylicontechnologies.com/hekaAPI/uploads/events/Heka%20Healthy%20You%20(BLS%20Training).mp4"}
      name="Safety Training"
      intro1={images.SafetyIntro1}
      intro2={images.SafetyIntro2}
      intro3={images.SafetyIntro3}
      intro4={images.SafetyIntro4}
      intro5={images.SafetyIntro5}
      intro6={images.SafetyIntro6}
      intro7={images.SafetyIntro7}
      intro8={images.SafetyIntro8}
      introName1="Promotes a safe & healthy work environment."
      introName2="Enhance employee productivity and lower abstenism"
      introName3="Better equipped to handle the crisis."
      introName4="Ensure Compliances & future incident prevention."
      introName5="Train anywhere, anytime"
      introName6="ISO 9001: 2015 Certified"
      introName7="Recommended program by IMA"
      introName8="Get certified same day"
      ServiceInformation={ServiceInformation()}
      support={images.SafetySupport}
      supportText="Life Support & Safety is the care that first-responders, healthcare professionals or trained professional
      impart upon the anyone suffering from cardiac arrest, obstructed airways or respiratory distress as a first
      aid. Nearly 45% of cardiac arrest victims have a chance of surviving with a CPR trained bystander around.
      Heka Healthy You believes that in order to make our society aware and prepared for such commonly occurring
      yet life threatening ailments, it is imperative to train individuals in Life Support & Safety. Therefore, we
      offer hands-on training by certified professionals in Emergency care, First aid, Road safety protocols &
      Safe electrical training."
      serviceBannerName="Safet At Work"
      serviceBannerImage1={images.BLSHealthCare}
      serviceBannerImage2={images.FileProtection}
      serviceBannerImage3={images.Helmet}
      serviceBannerImage4={images.Warning}
      serviceBannerText1="Health"
      serviceBannerText2="Regulation"
      serviceBannerText3="Protection"
      serviceBannerText4="Hazard Analysis"
      faqs={[
        {
          question: "Why this training is important for me ?",
          answer:
            "This training helps participants with knowledge and skill to tackle basic life support techniques to save lives.",
        },
        {
          question: "I am not a medical person why I will take training?",
          answer:
            "This training can be taken by anyone, irrespective of being a doctor or paramedic.",
        },
        {
          question: "This certificate is for life time ?",
          answer:
            "The certificate is valid for a period of 2 years from the date of training.",
        },
        {
          question:
            "How long does it take to learn BLS ( Basic Life Support)and First -Aid training?",
          answer:
            "This training can be completed in approximately 3 hours, including hands - on skills.",
        },
        {
          question: "What do you expect in a training session?",
          answer:
            "This course includes - single and team based CPR training, AED operatives for adults, children and infants. Relief in choking and drowning Victims along with First - Aid for Burn, Fracture, Poisoning, etc.",
        },
        {
          question: "What is an AED?",
          answer:
            "Automated External Defibrillator (AED) is used on those experiencing sudden cardiac arrest, as it analyses heart's rhythm and delivers shock to re-establish normal heart rhythm.",
        },
      ]}
      newsRoomImages={[
        images.BLSNews1,
        images.BLSNews2,
        images.BLSNews3,
        images.BLSNews4,
        images.BLSNews5,
        images.BLSNews6,
        images.BLSNews7,
        images.BLSNews8,
        images.BLSNews9,
      ]}
    />
  );
}

export default SafetyTraining;
