import React, { useState } from "react";
import "./FAQ.css";
const FAQCard = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq "}
      id={`FAQ${index}`}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};
export default function FAQ(props) {
  const [currOpen, setCurrOpen] = useState(-1);
  const toggleFAQ = (index) => {
    if (currOpen === index) {
      document.getElementById("FAQ" + index).classList.remove("open");
      setCurrOpen(-1);
    } else if (currOpen !== index && currOpen != -1) {
      document.getElementById("FAQ" + index).classList.add("open");
      document.getElementById("FAQ" + currOpen).classList.remove("open");
      setCurrOpen(index);
    } else {
      document.getElementById("FAQ" + index).classList.add("open");
      setCurrOpen(index);
    }
  };

  return (
    <>
      <div className="my-10 flex justify-center items-center">
        <h3 className="text-3xl font-bold font-[Poppins]">FREQUENTLY ASKED QUESTIONS</h3>
      </div>
      <div className="faqs">
        {props.faqs.map((faq, index) => (
          <FAQCard
            faq={faq}
            index={index}
            currOpen={currOpen}
            key={index}
            toggleFAQ={toggleFAQ}
          />
        ))}
      </div>
    </>
  );
}
