import React, { createRef } from "react";
import img from "../../assets/labtestImages/Lab test Image.png";
import howItWorks from "../../assets/physiotherapyImages/How IS work.png";
import howItWorksMobile from "../../assets/physiotherapyImages/How Instant Services work mobile.png";
import "./LabTestComponent.css";
import mobileImgMain from "../../assets/labtestImages/Image & BG.svg";
import whiteImg1 from "../../assets/physiotherapyImages/Rectangle 2586.png";
import whiteImg from "../../assets/physiotherapyImages/Rectangle 2586 (1).png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EastIcon from "@mui/icons-material/East";
import img2 from "../../assets/labtestImages/Rectangle 2668.svg";
import img3 from "../../assets/labtestImages//Rectangle 2668 (1).svg";
const LabTestComponent = (props) => {
  const { isMobile, arr, arr1, handleEnquireNow } = props;
  const mySlider = createRef();

  const setting = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const handleNext = () => {
    mySlider.current.slickNext();
  };

  const handlePrev = () => {
    mySlider.current.slickPrev();
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Lab Tests At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, you can enjoy the convenience of home <br />
              -based lab tests, providing accurate results for proactive <br />{" "}
              health management.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Lab Tests At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, you can enjoy the convenience of home-based lab
              tests, providing <br /> accurate results for proactive health
              management.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      {/* <div
        style={
          isMobile ? { padding: "32px 0px 0px 0px" } : { padding: "32px 0px" }
        }
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
      </div> */}

      <img
        src={isMobile ? howItWorksMobile : howItWorks}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2rem auto" }
            : {
                width: "78%",
                display: "block",
                margin: " 3rem auto",
                marginTop: "5rem",
              }
        }
      />

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Types of Lab Tests</h4>
      </div>

      {isMobile ? (
        <div className="labTestPageContainer2">
          {arr.map((item) => {
            return (
              <div className="labTestPageContainer2-card">
                <div style={{ width: "35%" }}>
                  <img
                    src={item.arrImg}
                    className="labTestPageContainer2-cardImg"
                  />
                </div>
                <div style={{ width: "65%" }}>
                  <h4 className="labTestPageContainer2-cardHead">
                    {item.head}
                  </h4>
                  <p className="labTestPageContainer2-cardText">{item.text}</p>

                  <div className="labTestPageContainer2-cardDiv">
                    <button className="labTestPageContainer2-cardBtn1">
                      Starting at &#8377;{item.price}
                    </button>
                    <button
                      onClick={() => handleEnquireNow()}
                      className="labTestPageContainer2-cardBtn2"
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="labTestPageContainer2-slider">
          <Slider ref={mySlider} arrows={false} {...setting}>
            {arr.map((item) => {
              return (
                <div className="labTestPageContainer2-card">
                  <h4 className="labTestPageContainer2-cardHead">
                    {item.head}
                  </h4>
                  <p className="labTestPageContainer2-cardText">{item.text}</p>
                  <img
                    src={item.arrImg}
                    className="labTestPageContainer2-cardImg"
                  />
                  <div className="labTestPageContainer2-cardDiv">
                    <button className="labTestPageContainer2-cardBtn1">
                      Starting at &#8377;{item.price}
                    </button>
                    <button
                      onClick={() => handleEnquireNow()}
                      className="labTestPageContainer2-cardBtn2"
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              );
            })}
          </Slider>

          {isMobile ? (
            <></>
          ) : (
            <>
              <div style={{ top: "45%" }} className="homePageContainer4-arrow1">
                <EastIcon
                  sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
                  onClick={handlePrev}
                />
              </div>
              <div style={{ top: "45%" }} className="homePageContainer4-arrow2">
                <EastIcon
                  sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
                  onClick={handleNext}
                />
              </div>
            </>
          )}
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Our Diagnostic Partners</h4>
      </div>

      <div className="lab-image-container">
        <img src={img3} className="lab-image" />
        <img src={img2} className="lab-image" />
      </div>

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Why take Lab Tests from Heka Health?
        </h4>
      </div>

      <div className="careGiverPageContainer3">
        {arr1.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img src={item.arrImg} className="physioPageContainer3-cardImg" />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={whiteImg}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}

                <div className="careGiverPageContainer3-card-div1">
                  <h5 className="careGiverPageContainer3-cardHead">
                    {item.head}
                  </h5>
                  <p className="labTestPageContainer3-text careGiverPageContainer3-cardText2">
                    {item.text}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LabTestComponent;
