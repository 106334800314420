import Logo from "../images/mainLogo.png";
import bgImg from "../images/bgImg.svg";
import bgImg1 from "../images/bgImg1.svg";
import Logo_2 from "../images/Logo2.svg";
import HS1I1 from "../images/HS1I1.png";
import HS1I2 from "../images/HS1I2.png";
import HS1I3 from "../images/HS1I3.png";
import HS1I4 from "../images/HS1I4.png";
import HS1I5 from "../images/HS1I5.png";
import HS2I1 from "../images/HS2I1.svg";
import HS2I2 from "../images/HS2I2.svg";
import HS2I3 from "../images/HS2I3.svg";
import Barcode from "../assets/image/barcode.png";
import GooglePlay from "../assets/image/googleplay.png";
import AppStore from "../assets/image/appstore.png";
import EmailNew from "../assets/image/emailnew.png";
import WhatsappNew from "../assets/image/whatsappnew.png";
import CallNew from "../assets/image/callnew.png";
import FacebookNew from "../assets/image/facebooknew.png";
import Instagram from "../assets/image/instagram.svg";
import LinkedIn from "../assets/image/linkedin.svg";
import Googles from "../assets/image/googles.svg";
import Banner1Image from "../assets/image/bannerframe-1.png";
import Banner2Image from "../assets/image/bannerframe-2.png";
import Banner3Image from "../assets/image/bannerframe-3.png";
import Banner4Image from "../assets/image/bannerframe-4.png";
import Banner5Image from "../assets/image/bannerframe-5.png";
import Banner6Image from "../assets/image/bannerframe-6.png";
import Banner7Image from "../assets/image/bannerframe-7.png";
import Partners1 from "../assets/image/Partners1.png";
import Partners2 from "../assets/image/Partners2.jpeg";
import Partners3 from "../assets/image/Partners3.jpeg";
import Partners4 from "../assets/image/Partners4.jpeg";
import Partners5 from "../assets/image/Partners5.png";
import Partners6 from "../assets/image/Partners6.png";
import Partners7 from "../assets/image/Partners7.png";
import Partners8 from "../assets/image/Partners8.jpeg";
import Partners9 from "../assets/image/Partners9.jpeg";
import SRV1 from "../assets/image/srv1.png";
import SRV2 from "../assets/image/srv2.png";
import SRV3 from "../assets/image/srv3.png";
import SRV4 from "../assets/image/srv4.png";
import SRV5 from "../assets/image/srv5.png";
import SRV6 from "../assets/image/srv6.png";
import SRV7 from "../assets/image/srv7.png";
import SRV8 from "../assets/image/srv8.png";
import SRV9 from "../assets/image/srv9.png";
import SRV10 from "../assets/image/srv10.png";
import About1sd from "../assets/image/about-1sd.png";
import About2sd from "../assets/image/about-2sd.png";
import About3sd from "../assets/image/about-3sd.png";
import About4sd from "../assets/image/about-4sd.png";
import WhyHekka1 from "../assets/image/whyaboutheka-1.png";
import WhyHekka2 from "../assets/image/whyaboutheka-2.png";
import WhyHekka3 from "../assets/image/whyaboutheka-3.png";
import WhyHekka4 from "../assets/image/whyaboutheka-4.png";
import ElderlyVideo from "../assets/image/elderly-video.svg";
import ElderlyIntro1 from "../assets/image/elintro-1.svg";
import ElderlyIntro2 from "../assets/image/elintro-2.svg";
import ElderlyIntro3 from "../assets/image/elintro-3.svg";
import ElderlyIntro4 from "../assets/image/elintro-4.svg";
import ElderlyIntro5 from "../assets/image/elintro-5.svg";
import ElderlyIntro6 from "../assets/image/elintro-6.png";
import ElderlyIntro7 from "../assets/image/elintro-7.svg";
import ElderlyIntro8 from "../assets/image/elintro-8.svg";
import ElderlySupport from "../assets/image/elederlysupport.png";
import ElderlyPlan1 from "../assets/image/eld-plan-1.png";
import ElderlyPlan2 from "../assets/image/eld-plan-2.png";
import ElderlyPlan3 from "../assets/image/eld-plan-3.png";
import ElderPlSec1 from "../assets/image/elderplsec-1.png";
import ElderPlSec2 from "../assets/image/elderplsec-2.png";
import ElderPlSec3 from "../assets/image/elderplsec-3.png";
import ElderPlSec4 from "../assets/image/elderplsec-4.png";
import ElderNews1 from "../assets/image/eldernew-1.png";
import ElderNews2 from "../assets/image/eldernew-2.png";
import ElderNews3 from "../assets/image/eldernew-3.png";
import ElderNews4 from "../assets/image/eldernew-4.png";
import BoyTesti from "../assets/image/boytesti.png";
import Star from "../assets/image/feelstar.png";
import GirlTesti from "../assets/image/girl-testi.png";
import BlankStar from "../assets/image/blankstar.png";
import FAQBannerImage from "../assets/image/faqbannerold.svg";
import PregnancyVideo from "../assets/image/pregnancy-video.svg";
import PregnancyIntro1 from "../assets/image/pgtab-1.svg";
import PregnancyIntro2 from "../assets/image/ppgtab-2.svg";
import PregnancyIntro3 from "../assets/image/pgtab-3.svg";
import PregnancyIntro4 from "../assets/image/pgtab-4.png";
import PregnancyIntro5 from "../assets/image/pgtab-5.png";
import PregnancyIntro6 from "../assets/image/pgtab-6.png";
import PregnancySupport from "../assets/image/pregnancysupport.png";
import PregnancyPlan1 from "../assets/image/parental-banner.svg";
import PregnancyPlan2 from "../assets/image/post-pregnancy.svg";
import PregnancyPlan3 from "../assets/image/continnum.svg";
import BLSHealthCare from "../assets/image/blshealthcare.png";
import FileProtection from "../assets/image/file-protection.png";
import Helmet from "../assets/image/helmet.png";
import Warning from "../assets/image/warning.png";
import OperativeCare from "../assets/image/operative.png";
import OperativeIntro1 from "../assets/image/operativeint-1.png";
import OperativeIntro2 from "../assets/image/operativeint-2.png";
import OperativeIntro3 from "../assets/image/operativeint-3.png";
import OperativeIntro4 from "../assets/image/operativeint-4.png";
import OperativeIntro5 from "../assets/image/opertaive-se-tab-1.png";
import OperativeIntro6 from "../assets/image/opertaive-se-tab-2.png";
import OperativeIntro7 from "../assets/image/opertaive-se-tab-3.png";
import OperativeIntro8 from "../assets/image/opertaive-se-tab-4.png";
import OperativeIntro9 from "../assets/image/opertaive-th-tab-1.png";
import OperativeIntro10 from "../assets/image/opertaive-th-tab-2.png";
import OperativeIntro11 from "../assets/image/opertaive-th-tab-3.png";
import OperativeIntro12 from "../assets/image/opertaive-th-tab-4.png";
import OperativePageSupport from "../assets/image/operativesupport.png";
import CareProvide1 from "../assets/image/care-provide-1.png";
import CareProvide2 from "../assets/image/care-provide-2.png";
import CareProvide3 from "../assets/image/care-provide-3.png";
import CareProvide4 from "../assets/image/care-provide-4.png";
import Astrology from "../assets/image/astrology.png";
import AstrologyIntro1 from "../assets/image/astroin-1.png";
import AstrologyIntro2 from "../assets/image/astroin-2.png";
import AstrologyIntro3 from "../assets/image/astroin-3.png";
import AstrologyIntro4 from "../assets/image/astroin-4.png";
import AstrologyIntro5 from "../assets/image/astroin-5.png";
import AstrologyIntro6 from "../assets/image/astroin-6.png";
import AstrologyIntro7 from "../assets/image/astroin-7.png";
import AstrologyIntro8 from "../assets/image/astroin-8.png";
import AstroSupport from "../assets/image/astrosupport.png";
import SafetyIntro1 from "../assets/image/blstab-1.png";
import SafetyIntro2 from "../assets/image/blstab-2.png";
import SafetyIntro3 from "../assets/image/blstab-3.png";
import SafetyIntro4 from "../assets/image/blstab-4.png";
import SafetyIntro5 from "../assets/image/blstab-5.png";
import SafetyIntro6 from "../assets/image/blstab-6.svg";
import SafetyIntro7 from "../assets/image/blstab-7.svg";
import SafetyIntro8 from "../assets/image/blstab-8.png";
import SafetySupport from "../assets/image/lifesupport.png";
import ComingSoon from "../assets/image/servicecomin.png";
import Doctor from "../assets/image/doctor.png";
import DoctorIntro1 from "../assets/image/doctorintro-1.png";
import DoctorIntro2 from "../assets/image/doctorintro-2.png";
import DoctorIntro3 from "../assets/image/doctorintro-3.png";
import DoctorIntro4 from "../assets/image/doctorintro-4.png";
import DoctorIntro5 from "../assets/image/doctorintro-5.png";
import DoctorIntro6 from "../assets/image/doctorintro-6.png";
import DoctorIntro7 from "../assets/image/doctorintro-7.png";
import DoctorIntro8 from "../assets/image/doctor-tab-1.png";
import DoctorIntro9 from "../assets/image/doctor-tab-2.png";
import DoctorIntro10 from "../assets/image/doctor-tab-3.png";
import DoctorIntro11 from "../assets/image/doctor-tab-4.png";
import DoctorSupport from "../assets/image/doctorsupport.png";
import DoctorPlan1 from "../assets/image/doctor-plan-1.png";
import DoctorPlan2 from "../assets/image/doctor-plan-2.png";
import DoctorPlan3 from "../assets/image/doctor-plan-3.png";
import DoctorPlan4 from "../assets/image/doctor-plan-4.png";
import LabTest from "../assets/image/labtest.png";
import LabTestIntro1 from "../assets/image/labintro-1.png";
import LabTestIntro2 from "../assets/image/labintro-2.png";
import LabTestIntro3 from "../assets/image/labintro-3.png";
import LabTestIntro4 from "../assets/image/labintro-4.png";
import LabTestIntro5 from "../assets/image/labintro-5.png";
import LabTestIntro6 from "../assets/image/labintro-6.png";
import LabTestIntro7 from "../assets/image/labintro-7.png";
import LabTestIntro8 from "../assets/image/labintro-8.png";
import LabTestIntro9 from "../assets/image/labtest-1.png";
import LabTestIntro10 from "../assets/image/labtest-2.png";
import LabTestIntro11 from "../assets/image/labtest-3.png";
import LabTestIntro12 from "../assets/image/labtest-4.png";
import LabSupport from "../assets/image/labsupport.png";
import LabPlan1 from "../assets/image/labplan-1.png";
import LabPlan2 from "../assets/image/labplan-2.png";
import LabPlan3 from "../assets/image/labplan-3.png";
import LabPlan4 from "../assets/image/labplan4.png";
import BLSNews1 from "../assets/image/blsnews-1.png";
import BLSNews2 from "../assets/image/blsnews-2.png";
import BLSNews3 from "../assets/image/blsnews-3.png";
import BLSNews4 from "../assets/image/blsnews-4.png";
import BLSNews5 from "../assets/image/blsnews-5.png";
import BLSNews6 from "../assets/image/blsnews-6.png";
import BLSNews7 from "../assets/image/blsnews-7.png";
import BLSNews8 from "../assets/image/blsnews-8.png";
import BLSNews9 from "../assets/image/blsnews-9.png";
import PregNews1 from "../assets/image/pregnews-1.png";
import PregNews2 from "../assets/image/pregnews-2.png";
import PregNews3 from "../assets/image/pregnews-3.png";
import PregNews4 from "../assets/image/pregnews-4.png";
import PregNews5 from "../assets/image/pregnews-5.png";
import PregNews6 from "../assets/image/pregnews-6.png";
import PregNews7 from "../assets/image/pregnews-7.png";
import operativeNews1 from "../assets/image/operative-new-1.png";
import operativeNews2 from "../assets/image/operative-new-2.png";
import operativeNews3 from "../assets/image/operative-new-3.png";
import operativeNews4 from "../assets/image/operative-new-4.png";
import AstroNews1 from "../assets/image/astronews-1.png";
import AstroNews2 from "../assets/image/astronews-2.png";
import AstroNews3 from "../assets/image/astronews-3.png";
import AstroNews4 from "../assets/image/astronews-4.png";
import AstroNews5 from "../assets/image/astronews-5.png";
import DoctorNews1 from "../assets/image/doctor-new-1.png";
import DoctorNews2 from "../assets/image/doctor-new-2.png";
import DoctorNews3 from "../assets/image/doctor-new-3.png";
import DoctorNews4 from "../assets/image/doctor-new-4.png";
import LabNews1 from "../assets/image/labnew-1.png";
import LabNews2 from "../assets/image/labnew-2.png";
import LabNews3 from "../assets/image/labnew-3.png";
import LabNews4 from "../assets/image/labnew-4.png";
import StarRev from "../assets/image/Starrev.png";
import EldPlanImage from "../assets/image/eld-plan-1.png";
import checkMark from "../assets/image/signnew.svg";
import homec1 from "../assets/image/homec-1.png";
import homec2 from "../assets/image/homec-2.png";
import homec3 from "../assets/image/homec-3.png";
import homec4 from "../assets/image/homec-4.png";
import PhysioSupport from "../assets/image/physiotherapht-support.png";
import physio1 from "../assets/image/physio-1.png";
import physio2 from "../assets/image/physio-2.png";
import physio3 from "../assets/image/physio-3.png";
import physio4 from "../assets/image/physio-4.png";
import physio5 from "../assets/image/physio-5.png";
import physio6 from "../assets/image/physio-6.png";
import physio7 from "../assets/image/physio-7.png";
import recoverySupport from "../assets/image/recovery-support.png";
import reenPlan1 from "../assets/image/reenplan-1.png";
import reenPlan2 from "../assets/image/reenplan-2.png";
import reenPlan3 from "../assets/image/reenplan-3.png";
import reenPlan4 from "../assets/image/physical-therapy 1.png";
import replan1 from "../assets/image/replan-1.png";
import replan2 from "../assets/image/replan-2.png";
import replan3 from "../assets/image/replan-3.png";
import replan4 from "../assets/image/replan-4.png";
import replan5 from "../assets/image/replan-5.png";
import replan6 from "../assets/image/replan-6.png";
import roadSafetyMap from "../assets/image/roadsatetymap.svg";
import roadSafety1 from "../assets/image/road-saf-1.png";
import roadSafety2 from "../assets/image/road-saf-2.png";
import roadSafety3 from "../assets/image/road-saf-3.png";
import roadSafety4 from "../assets/image/road-saf-4.png";
import roadSafety5 from "../assets/image/road-saf-5.png";
import roadSafety6 from "../assets/image/road-saf-6.png";
import roadSafety7 from "../assets/image/road-saf-7.png";
import roadSafety8 from "../assets/image/road-saf-8.png";
import roadSafety9 from "../assets/image/road-saf-9.png";
import lifesupport from "../assets/image/lifesupport.png";
import firstAidDoc from "../assets/image/firstaidsoc.png";
import firstAidRoadMap from "../assets/image/firstaidroasmap.png";
import electricalSafety from "../assets/image/elctricalsafety.png";
import electricalSafe1 from "../assets/image/electricalsafe-1.png";
import electricalSafe2 from "../assets/image/electricalsafe-2.png";
import electricalSafe3 from "../assets/image/electricalsafe-3.png";
import electricalSafe4 from "../assets/image/electricalsafe-4.png";
import electricalSafe5 from "../assets/image/electricalsafe-5.png";
import electricalSafe6 from "../assets/image/electricalsafe-6.png";
import electricalSafe7 from "../assets/image/electricalsafe-7.png";
import electricalSafe8 from "../assets/image/electricalsafe-8.png";
import electricalSafe9 from "../assets/image/electricalsafe-9.png";
import electricalSafe10 from "../assets/image/electricalsafe-10.png";
import PregNormal from "../assets/image/preg-common-natural.svg";
import regaining from "../assets/image/regaining.svg";
import PreCommonHelp from "../assets/image/pre-common-help.svg";
import PreCommonExt from "../assets/image/precommon-ext.svg";
import PostPreg1 from "../assets/image/contic-1.png";
import PostPreg2 from "../assets/image/contic-2.png";
import PostPreg3 from "../assets/image/contic-3.png";
import PostPreg4 from "../assets/image/contic-4.png";
import PostPreg5 from "../assets/image/postpre-1.png";
import PostPreg6 from "../assets/image/postpre-2.png";
import PostPreg7 from "../assets/image/postpre-3.png";
import PostPreg8 from "../assets/image/postpre-4.png";
import MedicalConst1 from "../assets/image/medical-cons-1.png";
import MedicalConst2 from "../assets/image/medical-cons-2.png";
import MedicalConst3 from "../assets/image/medical-cons-3.png";
import Stroke1 from "../assets/image/stroke-1.png";
import Stroke2 from "../assets/image/stroke-2.png";
import Stroke3 from "../assets/image/stroke-3.png";
import Stroke4 from "../assets/image/stroke-4.png";
import Stroke5 from "../assets/image/stroke-5.png";
import Stroke6 from "../assets/image/stroke-6.png";
import cbcPopUp from "../assets/image/cbc-popup.png";
import bloddTestPopUp from "../assets/image/bloodtestpopup.png";
import electrolytePopUp from "../assets/image/elctrlytepopup.png";
import creatinePopUp from "../assets/image/creatinepopup.png";
import ContactUsBanner from "../assets/image/conatctus-banner.png";
import CareerBanner from "../assets/image/carrer-banner.png";
import Upload from "../assets/image/upload.png";
import GalleryImage1 from "../assets/image/GalleryImage1.JPG";
import electricalSafetyService from "../assets/image/electricalSafety.svg";
import firstAidService from "../assets/image/firstaid.svg";
import roadSafetyService from "../assets/image/roadSafety.svg";
import loader from "../assets/image/loader.gif";

const images = {
  Logo: Logo,
  bgImg: bgImg,
  bgImg1: bgImg1,
  Logo_2: Logo_2,
  HS1I1: HS1I1,
  HS1I2: HS1I2,
  HS1I3: HS1I3,
  HS1I4: HS1I4,
  HS1I5: HS1I5,
  HS2I1: HS2I1,
  HS2I2: HS2I2,
  HS2I3: HS2I3,
  Barcode: Barcode,
  GooglePlay: GooglePlay,
  AppStore: AppStore,
  EmailNew: EmailNew,
  WhatsappNew: WhatsappNew,
  CallNew: CallNew,
  FacebookNew: FacebookNew,
  Instagram: Instagram,
  LinkedIn: LinkedIn,
  Googles: Googles,
  Banner1Image: Banner1Image,
  Banner2Image: Banner2Image,
  Banner3Image: Banner3Image,
  Banner4Image: Banner4Image,
  Banner5Image: Banner5Image,
  Banner6Image: Banner6Image,
  Banner7Image: Banner7Image,
  Partners1: Partners1,
  Partners2: Partners2,
  Partners3: Partners3,
  Partners4: Partners4,
  Partners5: Partners5,
  Partners6: Partners6,
  Partners7: Partners7,
  Partners8: Partners8,
  Partners9: Partners9,
  SRV1: SRV1,
  SRV2: SRV2,
  SRV3: SRV3,
  SRV4: SRV4,
  SRV5: SRV5,
  SRV6: SRV6,
  SRV7: SRV7,
  SRV8: SRV8,
  SRV9: SRV9,
  SRV10: SRV10,
  About1sd: About1sd,
  About2sd: About2sd,
  About3sd: About3sd,
  About4sd: About4sd,
  WhyHekka1: WhyHekka1,
  WhyHekka2: WhyHekka2,
  WhyHekka3: WhyHekka3,
  WhyHekka4: WhyHekka4,
  ElderlyVideo: ElderlyVideo,
  ElderlyIntro1: ElderlyIntro1,
  ElderlyIntro2: ElderlyIntro2,
  ElderlyIntro3: ElderlyIntro3,
  ElderlyIntro4: ElderlyIntro4,
  ElderlyIntro5: ElderlyIntro5,
  ElderlyIntro6: ElderlyIntro6,
  ElderlyIntro7: ElderlyIntro7,
  ElderlyIntro8: ElderlyIntro8,
  ElderlySupport: ElderlySupport,
  ElderlyPlan1: ElderlyPlan1,
  ElderlyPlan2: ElderlyPlan2,
  ElderlyPlan3: ElderlyPlan3,
  ElderPlSec1: ElderPlSec1,
  ElderPlSec2: ElderPlSec2,
  ElderPlSec3: ElderPlSec3,
  ElderPlSec4: ElderPlSec4,
  ElderNews1: ElderNews1,
  ElderNews2: ElderNews2,
  ElderNews3: ElderNews3,
  ElderNews4: ElderNews4,
  BoyTesti: BoyTesti,
  Star: Star,
  GirlTesti: GirlTesti,
  BlankStar: BlankStar,
  FAQBannerImage: FAQBannerImage,
  PregnancyVideo: PregnancyVideo,
  PregnancyIntro1: PregnancyIntro1,
  PregnancyIntro2: PregnancyIntro2,
  PregnancyIntro3: PregnancyIntro3,
  PregnancyIntro4: PregnancyIntro4,
  PregnancyIntro5: PregnancyIntro5,
  PregnancyIntro6: PregnancyIntro6,
  PregnancySupport: PregnancySupport,
  PregnancyPlan1: PregnancyPlan1,
  PregnancyPlan2: PregnancyPlan2,
  PregnancyPlan3: PregnancyPlan3,
  BLSHealthCare: BLSHealthCare,
  FileProtection: FileProtection,
  Helmet: Helmet,
  Warning: Warning,
  OperativeCareVideo: OperativeCare,
  OperativeIntro1: OperativeIntro1,
  OperativeIntro2: OperativeIntro2,
  OperativeIntro3: OperativeIntro3,
  OperativeIntro4: OperativeIntro4,
  OperativeIntro5: OperativeIntro5,
  OperativeIntro6: OperativeIntro6,
  OperativeIntro7: OperativeIntro7,
  OperativeIntro8: OperativeIntro8,
  OperativeIntro9: OperativeIntro9,
  OperativeIntro10: OperativeIntro10,
  OperativeIntro11: OperativeIntro11,
  OperativeIntro12: OperativeIntro12,
  OperativePageSupport: OperativePageSupport,
  CareProvide1: CareProvide1,
  CareProvide2: CareProvide2,
  CareProvide3: CareProvide3,
  CareProvide4: CareProvide4,
  AstrologyVideo: Astrology,
  AstrologyIntro1: AstrologyIntro1,
  AstrologyIntro2: AstrologyIntro2,
  AstrologyIntro3: AstrologyIntro3,
  AstrologyIntro4: AstrologyIntro4,
  AstrologyIntro5: AstrologyIntro5,
  AstrologyIntro6: AstrologyIntro6,
  AstrologyIntro7: AstrologyIntro7,
  AstrologyIntro8: AstrologyIntro8,
  AstroSupport: AstroSupport,
  SafetyIntro1: SafetyIntro1,
  SafetyIntro2: SafetyIntro2,
  SafetyIntro3: SafetyIntro3,
  SafetyIntro4: SafetyIntro4,
  SafetyIntro5: SafetyIntro5,
  SafetyIntro6: SafetyIntro6,
  SafetyIntro7: SafetyIntro7,
  SafetyIntro8: SafetyIntro8,
  SafetySupport: SafetySupport,
  comingSoon: ComingSoon,
  DoctorVideo: Doctor,
  DoctorIntro1: DoctorIntro1,
  DoctorIntro2: DoctorIntro2,
  DoctorIntro3: DoctorIntro3,
  DoctorIntro4: DoctorIntro4,
  DoctorIntro5: DoctorIntro5,
  DoctorIntro6: DoctorIntro6,
  DoctorIntro7: DoctorIntro7,
  DoctorIntro8: DoctorIntro8,
  DoctorIntro9: DoctorIntro9,
  DoctorIntro10: DoctorIntro10,
  DoctorIntro11: DoctorIntro11,
  DoctorSupport: DoctorSupport,
  DoctorPlan1: DoctorPlan1,
  DoctorPlan2: DoctorPlan2,
  DoctorPlan3: DoctorPlan3,
  DoctorPlan4: DoctorPlan4,
  LabTestVideo: LabTest,
  LabTestIntro1: LabTestIntro1,
  LabTestIntro2: LabTestIntro2,
  LabTestIntro3: LabTestIntro3,
  LabTestIntro4: LabTestIntro4,
  LabTestIntro5: LabTestIntro5,
  LabTestIntro6: LabTestIntro6,
  LabTestIntro7: LabTestIntro7,
  LabTestIntro8: LabTestIntro8,
  LabTestIntro9: LabTestIntro9,
  LabTestIntro10: LabTestIntro10,
  LabTestIntro11: LabTestIntro11,
  LabTestIntro12: LabTestIntro12,
  LabSupport: LabSupport,
  LabPlan1: LabPlan1,
  LabPlan2: LabPlan2,
  LabPlan3: LabPlan3,
  LabPlan4: LabPlan4,
  BLSNews1: BLSNews1,
  BLSNews2: BLSNews2,
  BLSNews3: BLSNews3,
  BLSNews4: BLSNews4,
  BLSNews5: BLSNews5,
  BLSNews6: BLSNews6,
  BLSNews7: BLSNews7,
  BLSNews8: BLSNews8,
  BLSNews9: BLSNews9,
  PregNews1: PregNews1,
  PregNews2: PregNews2,
  PregNews3: PregNews3,
  PregNews4: PregNews4,
  PregNews5: PregNews5,
  PregNews6: PregNews6,
  PregNews7: PregNews7,
  operativeNews1: operativeNews1,
  operativeNews2: operativeNews2,
  operativeNews3: operativeNews3,
  operativeNews4: operativeNews4,
  AstroNews1: AstroNews1,
  AstroNews2: AstroNews2,
  AstroNews3: AstroNews3,
  AstroNews4: AstroNews4,
  AstroNews5: AstroNews5,
  DoctorNews1: DoctorNews1,
  DoctorNews2: DoctorNews2,
  DoctorNews3: DoctorNews3,
  DoctorNews4: DoctorNews4,
  LabNews1: LabNews1,
  LabNews2: LabNews2,
  LabNews3: LabNews3,
  LabNews4: LabNews4,
  StarRev: StarRev,
  EldPlanImage: EldPlanImage,
  checkMark: checkMark,
  homec1: homec1,
  homec2: homec2,
  homec3: homec3,
  homec4: homec4,
  physioSupport: PhysioSupport,
  physio1: physio1,
  physio2: physio2,
  physio3: physio3,
  physio4: physio4,
  physio5: physio5,
  physio6: physio6,
  physio7: physio7,
  recoverySupport: recoverySupport,
  reenPlan1: reenPlan1,
  reenPlan2: reenPlan2,
  reenPlan3: reenPlan3,
  reenPlan4: reenPlan4,
  replan1: replan1,
  replan2: replan2,
  replan3: replan3,
  replan4: replan4,
  replan5: replan5,
  replan6: replan6,
  roadSafetyMap: roadSafetyMap,
  roadSafety1: roadSafety1,
  roadSafety2: roadSafety2,
  roadSafety3: roadSafety3,
  roadSafety4: roadSafety4,
  roadSafety5: roadSafety5,
  roadSafety6: roadSafety6,
  roadSafety7: roadSafety7,
  roadSafety8: roadSafety8,
  roadSafety9: roadSafety9,
  lifesupport: lifesupport,
  firstAidDoc: firstAidDoc,
  firstAidRoadMap: firstAidRoadMap,
  electricalSafety: electricalSafety,
  electricalSafe1: electricalSafe1,
  electricalSafe2: electricalSafe2,
  electricalSafe3: electricalSafe3,
  electricalSafe4: electricalSafe4,
  electricalSafe5: electricalSafe5,
  electricalSafe6: electricalSafe6,
  electricalSafe7: electricalSafe7,
  electricalSafe8: electricalSafe8,
  electricalSafe9: electricalSafe9,
  electricalSafe10: electricalSafe10,
  PregNormal: PregNormal,
  regaining: regaining,
  PreCommonHelp: PreCommonHelp,
  PreCommonExt: PreCommonExt,
  PostPreg1: PostPreg1,
  PostPreg2: PostPreg2,
  PostPreg3: PostPreg3,
  PostPreg4: PostPreg4,
  PostPreg5: PostPreg5,
  PostPreg6: PostPreg6,
  PostPreg7: PostPreg7,
  PostPreg8: PostPreg8,
  MedicalConst1: MedicalConst1,
  MedicalConst2: MedicalConst2,
  MedicalConst3: MedicalConst3,
  Stroke1: Stroke1,
  Stroke2: Stroke2,
  Stroke3: Stroke3,
  Stroke4: Stroke4,
  Stroke5: Stroke5,
  Stroke6: Stroke6,
  cbcPopUp: cbcPopUp,
  bloddTestPopUp: bloddTestPopUp,
  electrolytePopUp: electrolytePopUp,
  creatinePopUp: creatinePopUp,
  ContactUsBanner: ContactUsBanner,
  CareerBanner: CareerBanner,
  Upload: Upload,
  GalleryImage1: GalleryImage1,
  electricalSafetyService: electricalSafetyService,
  firstAidService: firstAidService,
  roadSafetyService: roadSafetyService,
  loader: loader,
};

export default images;
