import React, { useContext, useEffect, useRef, useState } from "react";
import { APIPostRequest } from "../../config/HttpClient";
import DeleteAccountPage from "../../components/DeleteAccountPage/DeleteAccountPage";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import {
  CUSTOMER_LOGIN,
  VERIFY_OTP,
} from "../../shared/containers/ContactUsContainer/ContactUsContainer.constants";
import { DELETE_ACCOUNT } from "./DeleteAccountContainer.constants";
import { Loader } from "../../shared/components/Loader/Loader";
import FailureSnackbar from "../../shared/components/Snackbars/FailureSnackbar";
import { API_FAILURE_MESSAGE } from "../../store/global/Global.constants";

const DeleteAccountContainer = () => {
  const { state, dispatch } = useContext(Context);
  const slotRef = useRef();
  const [customerId, setCustomerId] = useState("");
  const [accountDeletionSuccess, setAccountDeletionSuccess] = useState(false);
  const [showFailureSnackbar, setShowFailureSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userNotFound, setUserNotFound] = useState("");

  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [mobileErr, setMobileError] = useState("");

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const [show, setShow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [timeLeft, setTimeLeft] = useState(90);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleSendOtp = () => {
    var formData = new FormData();
    formData.append("data", `{"mobileNumber":"${mobileNumber}"}`);
    APIPostRequest(
      CUSTOMER_LOGIN,
      formData,
      (response) => {
        console.log("Customer logged in", response);
        setCustomerId(response.customerId);
        setShowDialog(true);
        setTimeLeft(90); // Reset timer
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => {
            if (prevTime === 0) {
              clearInterval(timer);
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, value) => {
    if (!isNaN(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const verifyOTP = () => {
    const enteredOTP = otp.join("");
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "otp": ${enteredOTP}}`
    );
    APIPostRequest(
      VERIFY_OTP,
      formData,
      (response) => {
        console.log("OTP VERIFIED RESPONSE", response);
        setShowSuccessMsg(true);
      },
      (error) => {
        console.log(error);
        console.log("Incorrect OTP, please try again.");
      }
    );
  };

  const handleDeleteAccount = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "mobileNumber": "${mobileNumber}", "emailId":"${email}"}`
    );
    APIPostRequest(
      DELETE_ACCOUNT,
      formData,
      (response) => {
        setLoading(false);
        if (response.code === 201) {
          setUserNotFound("User Not Found");
        } else {
          setAccountDeletionSuccess(true);
          setShow(false);
          setUserNotFound("");
          setMobileNumber(" ");
          setName(" ");
        }
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setUserNotFound("");
        setShowFailureSnackbar(true);
        setSnackbarMessage(API_FAILURE_MESSAGE);
      }
    );
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleProceed = () => {
    setShowDialog(false);
    setShow(true);
  };

  console.log(email);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {showFailureSnackbar && (
            <FailureSnackbar
              message={snackbarMessage}
              showFailureSnackbar
              setShowFailureSnackbar={setShowFailureSnackbar}
            />
          )}
          <DeleteAccountPage
            isMobile={selectIsMobile(state)}
            slotRef={slotRef}
            name={name}
            setName={setName}
            mobileNumber={mobileNumber}
            handleMobile={handleMobile}
            mobileErr={mobileErr}
            show={show}
            handleSendOtp={handleSendOtp}
            showDialog={showDialog}
            handleClose={handleClose}
            inputRefs={inputRefs}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            otp={otp}
            formatTime={formatTime}
            timeLeft={timeLeft}
            handleSubmitOpt={verifyOTP}
            handleDeleteAccount={handleDeleteAccount}
            showSuccessMsg={showSuccessMsg}
            handleProceed={handleProceed}
            email={email}
            handleEmailChange={handleEmailChange}
            emailError={emailError}
            accountDeletionSuccess={accountDeletionSuccess}
            userNotFound={userNotFound}
          />
        </>
      )}
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default DeleteAccountContainer;
