import React from "react";
import images from "../../commons/ImagesExport";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import OurPartners from "../../shared/components/Parteners/OurPartners";
import NewsRoom from "../../shared/components/NewsRoom/NewsRoom";
import Testimonials from "../../shared/components/Testimonials/Testimonials";
import "./ParentalCare.css";
import Link from "react-router-dom/Link";
import { APIPostRequest } from "../../config/HttpClient";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

function Plan(props) {
  return (
    <div className="col-md-4">
      <div className="plan-slider-pregnancy">
        <div className="plan-heading-pregnancy">
          <h4>{props.title}</h4>
        </div>
        <div className="selectplainlist-main-pregnancy">
          {props.features.map((feature, index) => {
            return (
              <div className="plan-detail-pregnancy" key={index}>
                <div className="left-detail-pregnancy">
                  <p>
                    <span className="bullet-pregnancy"></span>
                    {feature.name}
                  </p>
                </div>
                <div className="right-detail-pregnancy">
                  <p>{feature.sessions}</p>
                </div>
              </div>
            );
          })}

          <div className="plan-detail-pregnancy">
            <div className="left-detail-pregnancy">
              <p
                style={
                  props.title == "3 months Plan"
                    ? { color: "#000", fontWeight: 700, marginTop: 48 }
                    : { color: "#000", fontWeight: 700, marginTop: 15 }
                }
              >
                Total Sessions
              </p>
            </div>
            <div className="right-detail-pregnancy">
              <p />
              <p
                style={
                  props.title == "3 months Plan"
                    ? { color: "#000", fontWeight: 700, marginTop: 48 }
                    : { color: "#000", fontWeight: 700, marginTop: 15 }
                }
              >
                {props.totalSessions}
              </p>
            </div>
          </div>
        </div>
        <Link to={props.link} className="no-underline">
          <button className="selecpra-button-pregnancy">Select Plan</button>
        </Link>
      </div>
    </div>
  );
}

function PostPregnancy() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const classes = useStyles();
  const handleSubmit = () => {
    setIsFetching(true);
    const data = {
      name: document.getElementById("callPopUp").querySelectorAll("input")[0]
        .value,
      email: document.getElementById("callPopUp").querySelectorAll("input")[1]
        .value,
      mobileNumber: document
        .getElementById("callPopUp")
        .querySelectorAll("input")[2].value,
      categoryName: "Pregnancy Care",
      subCategoryName: "Post Pregnancy",
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    APIPostRequest(
      "/addCallSchedule.php",
      formdata,
      (res) => {
        setIsFetching(false);
      },
      (err) => {
        setIsFetching(false);
      }
    );
  };

  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  return (
    <>
      <HeaderContainer />
      {isFetching && (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader}  />
        </Backdrop>
      )}
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {window.scrollTo(0, 0)}
      <section className="parentalcontent">
        <div className="container">
          <div className="row paren-row">
            <div className="col-md-6">
              <div className="left-parental">
                <h4 className="parental-heading">Post Pregnancy</h4>
                <ul className="parental-list">
                  {[
                    "Counselling on breastfeeding/ baby thermal care & Hygiene",
                    "Assessment & management for complications/ Risks",
                    "Assessment & management for complications/ Risks",
                    "New born Assessment at healthcare facilities",
                    "Facilitation of japa maid",
                  ].map((item, index) => {
                    return (
                      <li key={index} className="flex items-center">
                        <span>
                          <img
                            className="sign-img"
                            src={images.checkMark}
                            alt=""
                          />
                        </span>{" "}
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-parental">
                <div className="req-call-div" id="callPopUp">
                  <h6 className="call-back-heading">Let's Talk</h6>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        required
                        type="text"
                        placeholder="Name"
                        className="call-input"
                        minLength={3}
                        maxLength={26}
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                        placeholder="Email"
                        className="call-input"
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="[0-9]{10}"
                        placeholder="Input Phone Number"
                        minLength={10}
                        maxLength={10}
                        className="call-input"
                      />
                    </div>
                    <button type="submit" className="callbackbtn">
                      Request a Call Back
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="enroll-h-pragnancy-1">
        <div className="container" style={{ padding: "15px 0px" }}>
          <div className="flex-wrap main-health">
            <div className="xs:w-full md:w-auto">
              <div className="health-1-1 w-full">
                <h5>Post Pregnancy Care</h5>
              </div>
            </div>
            <div className="flex flex-wrap justify-around flex-1">
              {[
                {
                  image: images.PostPreg1,
                  name: "Family Counnselling",
                },
                {
                  image: images.PostPreg2,
                  name: "Regaining Pre Pregnancy Care",
                },
                {
                  image: images.PostPreg3,
                  name: "Help with Back Pain",
                },
                {
                  image: images.PostPreg4,
                  name: "Exhaustion",
                },
              ].map((item, index) => {
                return (
                  <div className="xs:w-full md:w-1/4" key={index}>
                    <div className="flex flex-col items-center">
                      <div className="health-preg-image">
                        <img src={item.image} alt="" />
                      </div>
                      <p className="healthdes-para-1">{item.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/*planns section*/}
      <div className="main-plan">
        <h4 className="sliderheading">OUR PLANS</h4>
        <div className="container">
          <div className="row">
            <Plan
              title="3 months Plan"
              features={[
                {
                  name: "Dedicated Care manager",
                  sessions: "",
                },
                {
                  name: "Couple Counselling",
                  sessions: "2",
                },
                {
                  name: "Prenatal Yoga Sessions",
                  sessions: "12",
                },
                {
                  name: "Discount on Lab Test",
                  sessions: "10%",
                },
                {
                  name: "Nutrition Counselling",
                  sessions: "",
                },
              ]}
              totalSessions="14"
              link="/pregnancyCare/threeMonthsPlan"
            />
            <Plan
              title="6 months Plan"
              features={[
                {
                  name: "Dedicated Care manager",
                  sessions: "",
                },
                {
                  name: "Couple Counselling",
                  sessions: "4",
                },
                {
                  name: "Prenatal Yoga Sessions",
                  sessions: "12",
                },
                {
                  name: "Discount on Lab Test",
                  sessions: "10%",
                },
                {
                  name: "Nutrition Counselling",
                  sessions: "",
                },
                {
                  name: "Garbhsanskar sessions",
                  sessions: "2",
                },
                {
                  name: "General Counselling",
                  sessions: "2",
                },
              ]}
              totalSessions="18"
              link="/pregnancyCare/sixMonthsPlan"
            />
            <Plan
              title="9 months Plan"
              features={[
                {
                  name: "Dedicated Care manager",
                  sessions: "",
                },
                {
                  name: "Couple Counselling",
                  sessions: "6",
                },
                {
                  name: "Prenatal Yoga Sessions",
                  sessions: "24",
                },
                {
                  name: "Discount on Lab Test",
                  sessions: "20%",
                },
                {
                  name: "Nutrition Counselling",
                  sessions: "",
                },
                {
                  name: "Dental checkup",
                  sessions: "4",
                },
                {
                  name: "General Counselling",
                  sessions: "4",
                },
              ]}
              totalSessions="34"
              link="/pregnancyCare/nineMonthsPlan"
            />
          </div>
        </div>
      </div>
      <OurPartners />
      <NewsRoom
        images={[
          images.PregNews1,
          images.PregNews2,
          images.PregNews3,
          images.PregNews4,
          images.PregNews5,
          images.PregNews6,
          images.PregNews7,
        ]}
      />
      <Testimonials />
      <Footer />
    </>
  );
}

export default PostPregnancy;
