import globalReducer, { rootReducer } from "./global/Global.reducers";

const reducers = {
  global: globalReducer,
};

export const updateReducers = (key, reducer) => {
  if (reducer) {
    reducers[key] = reducer;
  } else {
    delete reducers[key];
  }
};

const initialState = {};

const combineReducers = () => {
  return (state = initialState, action) => {
    const updatedState = rootReducer(state, action);
    return Object.keys(reducers).reduce(
      (nextState, key) => {
        nextState[key] = reducers[key](updatedState[key], action);
        return nextState;
      },
      { ...updatedState }
    );
  };
};

export default () => {
  return combineReducers();
};
