import React, {createContext, useReducer, useEffect} from "react";
import noop from 'lodash/noop';
import createReducer from "./reducer";
import labels from "../commons/WebLabels";
import {fetchLabelsAction, setLabels, setMobileDevice} from "./global/Global.actions";
import PropTypes from "prop-types";

const mobileDeviceWidth = 500;

const reducer = createReducer();
const initialState = reducer({}, {type: ''});


export const fetchLabels = (dispatch) => {
    dispatch(fetchLabelsAction());
    dispatch(setLabels(labels));
    dispatch(setMobileDevice(window.outerWidth<= mobileDeviceWidth));
};

const Store = ({children}) => {
     const [state, dispatch] = useReducer(reducer, initialState);
    
     useEffect(() => {
         fetchLabels(dispatch);
     },[dispatch]);
     return(
         <Context.Provider value ={{state, dispatch}}>
             {children}
         </Context.Provider>
     );
 };

 Store.propTypes = {
    children: PropTypes.object,
};

export const Context = createContext({
    state: initialState,
    dispatch: noop,
})

export default Store;
