import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { createRef } from "react";
import React, { useState } from "react";
import { APIPostRequest } from "../../config/HttpClient";
import { useEffect } from "react";

const Testimonials = (props) => {
  const { isMobile, pageIndex } = props;
  const mySlider5 = createRef();

  const [arr, setArr] = useState([]);
  const categories = [
    { category: "Elderly care", value: "1" },
    { category: "Safety Training", value: "2" },
    { category: "Operative care", value: "3" },
    { category: "Pregnancy care", value: "4" },
    { category: "Physio", value: "6" },
    { category: "Caregiver", value: "7" },
    { category: "Medical equipment", value: "8" },
    { category: "Lab", value: "9" },
    { category: "Doctor consultation", value: "10" },
  ];

  const categoryName =
    categories.find((item) => item.value === pageIndex)?.category || null;

  useEffect(() => {
    const formData = new FormData();
    formData.append("data", `{"category":"${categoryName}"}`);
    APIPostRequest(
      "/fetchAllTestimonials.php",
      formData,
      (response) => {
        setArr(response?.testimonials);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [pageIndex, categoryName]);

  const newArr = [...arr, ...arr];

  const setting5 = {
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    // autoplay: true,
    centerMode: true,
    centerPadding: "0",
    beforeChange: (oldIndex, newIndex) => setCurrentSlide5(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [currentSlide5, setCurrentSlide5] = useState(0);

  const alignImages5 = () => {
    return newArr.map((item, index) => {
      const isCenter = index === currentSlide5;
      return (
        <>
          {isMobile ? (
            <>
              <div className="homePageContainer4-mainBox">
                <img
                  src={item.backgroundImage}
                  style={{ borderRadius: "16px", objectFit: "contain" }}
                  className="homePageContainer4-img"
                />
                <div className="homePageContainer4-boxA">
                  <div className="homePageContainer4-alignHead">
                    <div className="homePageContainer4-head">{item.name}</div>
                  </div>
                  <p className="homePageContainer4-text">{item.description}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              {isCenter ? (
                <>
                  <div className="homePageContainer4-mainBox">
                    <img
                      src={item.backgroundImage}
                      className="homePageContainer4-img"
                      style={{ borderRadius: "16px", objectFit: "contain" }}
                    />
                    <div className="homePageContainer4-boxA">
                      <div className="homePageContainer4-alignHead">
                        <div className="homePageContainer4-head">
                          {item.name}
                        </div>
                      </div>
                      <p className="homePageContainer4-text">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="homePageContainer4-boxU">
                  <div className="homePageContainer4-alignHead">
                    <div className="homePageContainer4-head">{item.name}</div>
                  </div>
                  <p className="homePageContainer4-text">{item.description}</p>
                </div>
              )}
            </>
          )}
        </>
      );
    });
  };

  const handleNext5 = () => {
    mySlider5.current.slickNext();
  };

  const handlePrev5 = () => {
    mySlider5.current.slickPrev();
  };

  return (
    <React.Fragment>
      <div className="homePageContainer4-headMainCont">
        <h4
          className="homePageContainer4-headMain"
          style={isMobile ? { paddingBottom: "16px" } : {}}
        >
          Testimonials
        </h4>
        <div className="homePageContainer4">
          <Slider ref={mySlider5} arrows={false} {...setting5}>
            {alignImages5()}
          </Slider>
          {isMobile ? (
            <></>
          ) : (
            <>
              <div className="homePageContainer4-arrow1">
                <ArrowRightAltIcon onClick={handlePrev5} />
              </div>
              <div className="homePageContainer4-arrow2">
                <ArrowRightAltIcon onClick={handleNext5} />
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
