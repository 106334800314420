import React from "react";
import img2 from "./physio Image.png";
import "./PhysiotherapyServicePage.css";
import howItWorks from "../../assets/physiotherapyImages/How IS work.png";
import howItWorksMobile from "../../assets/physiotherapyImages/How Instant Services work mobile.png";
import whiteImg1 from "../../assets/physiotherapyImages/Rectangle 2586.png";
import whiteImg from "../../assets/physiotherapyImages/Rectangle 2586 (1).png";
import mobileImgMain from "./Image & BG.svg";

const PhysiotherapyServicePage = (props) => {
  const { isMobile, arr, arr1, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Physiotherapy At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Physiotherapy employs specialised exercises and cutting <br />
              -edge portable modalities to enhance mobility, alleviate <br />{" "}
              pain, and promote recovery from injuries or disabilities.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Physiotherapy At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, Physiotherapy employs specialised exercises and
              cutting-edge portable <br /> modalities to enhance mobility,
              alleviate pain, and promote recovery from injuries or
              disabilities.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      {/* <div
        style={
          isMobile ? { padding: "32px 0px 0px 0px" } : { padding: "32px 0px" }
        }
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
      </div> */}

      <img
        src={isMobile ? howItWorksMobile : howItWorks}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2rem auto" }
            : {
                width: "78%",
                display: "block",
                margin: " 3rem auto",
                marginTop: "5rem",
              }
        }
      />

      <div className="homePageContainer4-headMainCont">
        <h4 className="homePageContainer4-headMain">Types of Physiotherapy</h4>
      </div>

      <div className="physioPageContainer4">
        {arr1.map((item, index) => {
          return (
            <div className="physioPageContainer4-card">
              <img src={item.arrImg} className="" style={{ width: "100%" }} />
              <h5 className="physioPageContainer4-cardHead">{item.head}</h5>
              <p className="physioPageContainer4-cardText">{item.text}</p>
            </div>
          );
        })}
      </div>

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Why take Physiotherapy from Heka Health?
        </h4>
        {/* {!isMobile && (
          <img className="homePageContainer4-headMain-dadi5" src={dadi} />
        )} */}
      </div>
      <div className="careGiverPageContainer3">
        {arr.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img src={item.arrImg} className="physioPageContainer3-cardImg" />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={whiteImg}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}

                <div className="careGiverPageContainer3-card-div1">
                  <h5 className="careGiverPageContainer3-cardHead">
                    {item.head}
                  </h5>
                  <p className="careGiverPageContainer3-cardText2">
                    {item.text}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PhysiotherapyServicePage;
