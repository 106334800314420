import React from "react";
import "./ContactUs.css";
import images from "../../commons/ImagesExport";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";

function ContactUs(props) {
  const [contact, setContact] = React.useState({
    name: "",
    mobileNumber: "",
    message: "",
  });
  const [career, setCareer] = React.useState({
    name: "",
    mobileNumber: "",
    email: "",
    linkedin: "",
  });
  const [file, setFile] = React.useState(null);

  return (
    <>
      <HeaderContainer />
      {/* {window.scrollTo(0, 0)} */}
      <h2 className="wrapper-heading">Contact Us</h2>
      <img src={images.ContactUsBanner} alt="" className="contact-us-img" />
      <div className="form-caontainer">
        <h6 className="small-cnt-heading">Contact Us</h6>
        <form onSubmit={() => props.sendContactData(contact)}>
          <div className="cnt-name">
            <input
              required
              type="text"
              value={contact.name}
              onChange={(e) => setContact({ ...contact, name: e.target.value })}
              className="commonnameph"
              placeholder="Name"
              minLength={3}
              maxLength={26}
            />
          </div>
          <div className="cnt-name">
            <input
              required
              type="tel"
              pattern="[0-9]{10}"
              value={contact.mobileNumber}
              onChange={(e) =>
                setContact({ ...contact, mobileNumber: e.target.value })
              }
              className="commonnameph"
              placeholder="Phone Number"
              maxLength={10}
            />
          </div>
          <div className="cnt-name">
            <textarea
              required
              cols={40}
              rows={3}
              value={contact.message}
              onChange={(e) =>
                setContact({ ...contact, message: e.target.value })
              }
              className="commonnamephtarea"
              placeholder="Messages..."
              defaultValue={""}
            />
          </div>
          <div>
            <button type="submit" className="submitmess-btn">
              Send
            </button>
          </div>
        </form>
      </div>
      <div className="section-careerwith-us">
        <div>
          <h2 className="wrapper-heading">Career with Us</h2>
          <img src={images.CareerBanner} className="careerimage" />
        </div>
      </div>
      <div className="careercv-section">
        <div className="container">
          <div className="cv-update">
            <h4 className="carcv-1">If you think you’ll fit right in,</h4>
            <h3 className="carcv-2">Join The Team</h3>
            <form onSubmit={() => props.sendCareerData(career, file)}>
              <div>
                <input
                  required
                  type="text"
                  placeholder="Full Name"
                  className="cvinpit"
                  value={career.name}
                  onChange={(e) =>
                    setCareer({ ...career, name: e.target.value })
                  }
                  minLength={3}
                  maxLength={26}
                />
              </div>
              <div>
                <input
                  required
                  type="tel"
                  pattern="[0-9]{10}"
                  placeholder="Mobile Number"
                  maxLength={10}
                  className="cvinpit"
                  value={career.mobileNumber}
                  onChange={(e) =>
                    setCareer({ ...career, mobileNumber: e.target.value })
                  }
                />
              </div>
              <div>
                <input
                  required
                  type="tel"
                  pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                  placeholder="Email"
                  value={career.email}
                  onChange={(e) =>
                    setCareer({ ...career, email: e.target.value })
                  }
                  className="cvinpit"
                />
              </div>
              <div>
                <input
                  required
                  type="text"
                  placeholder="LinkedIn Profile URL"
                  className="cvinpit"
                  value={career.linkedin}
                  onChange={(e) =>
                    setCareer({ ...career, linkedin: e.target.value })
                  }
                />
              </div>

              <div className="file-choosenr">
                <input
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  required
                  type="file"
                  id="file"
                  accept=".pdf"
                />
                <label className="flex items-center w-48" htmlFor="file">
                  <span>Add Resume </span>
                  <span className=" inline-block">
                    <img src={images.Upload} alt="" style={{ height: 20 }} />
                  </span>
                </label>
                <p className="ml-4">{file && file.name}</p>
                {/* <h5 class="choosen-heading">No Choosen File</h5> */}
              </div>
              <button type="submit" className="cvsubmit">
                Apply
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
