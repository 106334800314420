import React, { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import BlogPage from "../../components/BlogPage/BlogPage";
import img1 from "../../assets/blogsImages/Rectangle 2543.png";
import img2 from "../../assets/blogsImages/background and image (1).svg";
import img3 from "../../assets/blogsImages/background and image (2).svg";
import img4 from "../../assets/blogsImages/background and image (3).svg";
import img5 from "../../assets/blogsImages/Frame 427320029.svg";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { Context } from "../../store/Store";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { useHistory } from "react-router-dom";
import { FETCH_ALL_BLOGS } from "./BlogPageContainer.constants";
import { APIGetRequest } from "../../config/HttpClient";
import { BLOG_DETAILS_PAGE } from "../../routes/constants";
const BlogPageContainer = () => {
  const history = useHistory();
  const { state } = useContext(Context);
  const [arr, setArr] = useState([]);

  useEffect(() => {
    APIGetRequest(
      "/fetchAllBlogs.php",
      (response) => {
        setArr(response?.blogs);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (id) => {
    history.push(`${BLOG_DETAILS_PAGE}?id=${id}`);
  };

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <BlogPage
        isMobile={selectIsMobile(state)}
        blogs={arr}
        handleClick={handleClick}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default BlogPageContainer;
