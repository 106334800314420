import React, { useEffect, useRef } from "react";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import Slider from "react-slick";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import images from "../../commons/ImagesExport";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

function GalleryPage(props) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: true,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // useEffect(() => {
  //   if(document.getElementsByClassName("galleryImage")[0].complete){
  //     props.setIsFetching(false);
  //   }
  // }, [document.getElementsByClassName("galleryImage")[0].complete]);
  // const counter = useRef(0);
  // const imageLoaded = () => {
  //   counter.current += 1;
  //   if (counter.current >= props.galleryData.length) {
  //     props.setIsFetching(false);
  //   }
  // }
  return (
    <div>
      <HeaderContainer />
      {props.isFetching && (
        <Backdrop className={classes.backdrop} open={props.isFetching}>
          <img src={images.loader} />
        </Backdrop>
      )}
      <Slider
        {...settings}
        className=" w-[88vw] sm:w-[95vw] h-[354px] sm:h-[454px] max-h-[500px] my-26 mx-auto"
      >
        {props.galleryData &&
          props.galleryData.map((gallery, index) => (
            <div className=" h-[300px] sm:h-[400px]">
              <img
                // onLoad={imageLoaded}
                src={gallery.file}
                alt="image"
                className={`galleryImage m-auto w-[88%] h-[200px] sm:h-[300px]`}
              />
              <div className="bg-[#afa16a] flex mx-auto h-[100px] sm:h-[64px] items-center justify-start w-[88%]">
                <h3 className="text-xl font-semibold ml-8 my-2 line-clamp-1">
                  {gallery.caption}
                  {/* {gallery.caption.length > 35 ? "..." : ""}{" "} */}
                </h3>
              </div>
            </div>
          ))}
      </Slider>
      <Footer />
    </div>
  );
}

export default GalleryPage;
