import React, { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import OperativeCareServicePage from "../../components/OperativeCareServicePage/OperativeCareServicePage";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import CareModel from "../../shared/components/CareModel/CareModel";
import Testimonials from "../../components/Testimonials/Testimonials";
import testimonialImage from "../../assets/operative.svg";

const OperativeCareServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "3";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropDownArr = [
    {
      label: "What is operative care?",
      text: "Operative care supports patients throughout the surgical process, including pre-operative planning, the procedure itself, and post-operative recovery.",
    },
    {
      label: "What's included in pre-operative assessments?",
      text: "Pre-operative assessments involve thorough evaluations of the patient's health status and planning for the surgical procedure based on individual needs.",
    },
    {
      label: "How is patient progress monitored post-surgery?",
      text: "Patient progress is closely monitored through regular follow-up appointments, allowing for the tracking of recovery, addressing any concerns, and ensuring positive outcomes.",
    },
    {
      label: "Who benefits from operative care?",
      text: "Operative care benefits anyone scheduled for surgery, regardless of age or medical history, by providing specialised support tailored to individual needs.",
    },
    {
      label: "How does operative care advocate for patients?",
      text: "Operative care advocates for patients by prioritising their needs, empowering them through informed decision-making, and offering continuous support throughout the surgical journey.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <OperativeCareServicePage
        isMobile={selectIsMobile(state)}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <CareModel isMobile={selectIsMobile(state)} />
      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default OperativeCareServiceContainer;
