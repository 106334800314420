import React, { useContext, useState, useEffect } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import dayjs from "dayjs";
import img1 from "../../assets/pregnancyCareImgages/icon1.svg";
import img2 from "../../assets/pregnancyCareImgages/icon2.svg";
import img3 from "../../assets/pregnancyCareImgages/icon3.svg";
import img4 from "../../assets/pregnancyCareImgages/icon4.svg";
import img5 from "../../assets/pregnancyCareImgages/icon5.svg";
import img6 from "../../assets/pregnancyCareImgages/icon6.svg";
import PregnancyCareServicePage from "../../components/PregnancyCareServicePage/PregnancyCareServicePage";
import bg1 from "../../assets/pregnancyCareImgages/bg1.svg";
import bg2 from "../../assets/pregnancyCareImgages/bg2.svg";
import mBg1 from "../../assets/pregnancyCareImgages/mBg1.svg";
import mBg2 from "../../assets/pregnancyCareImgages/mBg2.svg";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import CareModel from "../../shared/components/CareModel/CareModel";
import Testimonials from "../../components/Testimonials/Testimonials";
import testimonialImage from "../../assets/pregnancy.svg";
const PregnancyServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "4";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      mobileBg: mBg1,
      bgImage: bg1,
      head: "Screening Tests and Ultrasound",
      text: "Screening tests and ultrasounds to detect any potential complications earlier.",
    },
    {
      mobileBg: mBg2,
      bgImage: bg2,
      arrImg: img2,
      head: "Regular Prenatal Checkups",
      text: "Regular prenatal checkups with healthcare providers to monitor both mother & baby.",
    },
    {
      mobileBg: mBg1,
      bgImage: bg1,
      arrImg: img3,
      head: "Nutritional Guidance",
      text: "Nutritional guidance to ensure the mother and baby receive adequate nutrients for optimal growth and development.",
    },
    {
      mobileBg: mBg2,
      bgImage: bg2,
      arrImg: img4,
      head: "Postnatal Care",
      text: "Postnatal care encompasses comprehensive support for both mother and baby following childbirth.",
    },
    {
      mobileBg: mBg1,
      bgImage: bg1,
      arrImg: img5,
      head: "Emotional Support",
      text: "Emotional support and counseling for addressing fears, anxieties, or mood changes during pregnancy.",
    },
    {
      mobileBg: mBg2,
      bgImage: bg2,
      arrImg: img6,
      head: "Full care Guidance",
      text: "Education on childbirth preparation, breastfeeding, and newborn care.",
    },
  ];

  const dropDownArr = [
    {
      label: "What does Pregnancy Care entail?",
      text: "Our Pregnancy Care includes prenatal checkups, screenings, emotional support, nutrition guidance, and comprehensive postnatal care services.",
    },
    {
      label: "When should I begin Pregnancy Care?",
      text: "It is recommended to begin as soon as pregnancy is confirmed to optimize maternal and child health.",
    },
    {
      label: "How frequent are prenatal checkups?",
      text: "Regular checkups are scheduled, typically monthly initially and more frequently in later stages of pregnancy.",
    },
    {
      label: "Is emotional support provided during Pregnancy Care?",
      text: "Yes, our trained professionals offer counseling and emotional support to address the emotional needs, fears, and anxieties throughout the pregnancy journey.",
    },
    {
      label: "Do you provide postnatal care?",
      text: "Yes, our services extend to comprehensive postnatal care including medical checkups, lactation support, and guidance on newborn care through our caregivers.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <PregnancyCareServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <CareModel isMobile={selectIsMobile(state)} />

      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />
      <FaqComponent isMobile={selectIsMobile(state)} arr={dropDownArr} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />

      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default PregnancyServiceContainer;
