import { useState } from "react";
import "./MobileFooter.css";
import facebook from "../../../assets/headerIcons/fb2.svg";
import linkedIn from "../../../assets/headerIcons/linekdin2.svg";
import mail from "../../../assets/headerIcons/mail2.svg";
import twitter from "../../../assets/headerIcons/twitter 12.svg";
import youtube from "../../../assets/headerIcons/Layer_12.svg";
import insta from "../../../assets/headerIcons/Frame2.svg";
import applePlayImg from "../../../assets/homePageImages/footer/image 37.svg";
import googlePlayImg from "../../../assets/homePageImages/footer/image 49.svg";
const MobileFooter = (props) => {
  const {
    instantLabels,
    quickLinksLabels,
    ourServicesLabels,
    handleClick,
    showDialog,
    handleDialog,
    handleChange,
    subscribeInput,
    handleSubscribe,
    handlePolicy,
  } = props;
  return (
    <>
      <div className="mobileFooterContainerDiv2-div1">
        <a target="_blank" href="https://www.facebook.com/HealthHeka">
          <img style={{ height: "18px", width: "18px" }} src={facebook} />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/heka-healthy-you/"
        >
          <img style={{ height: "18px", width: "18px" }} src={linkedIn} />
        </a>
        <a target="_blank" href="mailto:support@hekahealth.in">
          <img style={{ height: "18px", width: "18px" }} src={mail} />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/@hekahealthyyouprivatelimited/featured"
        >
          <img style={{ height: "18px", width: "18px" }} src={youtube} />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/heka__health?igsh=aXdhaTdlencyNnc="
        >
          <img style={{ height: "18px", width: "18px" }} src={insta} />
        </a>
        {/* <a
          target="_blank"
          href="https://x.com/hekahealthyyou?t=bIVd2LIDtxooXT_znSYiMA&s=08"
        >
          <img style={{ height: "18px", width: "18px" }} src={twitter} />
        </a> */}
      </div>
      <div className="mobileFooterContainer">
        <div className="mobileFooterContainerDiv1">
          <div>
            <h4 className="mobileFooterContainerDiv1-h">Quick Links</h4>
            <div>
              {quickLinksLabels.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item.link)}
                    className="mobileFooterContainerDiv1-p"
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>

          <div>
            <h4 className="mobileFooterContainerDiv1-h">Get Instantly</h4>
            <div>
              {instantLabels.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item.link)}
                    className="mobileFooterContainerDiv1-p"
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>

            {/* <div className="mobileFooter-alignIcons">
              <img src={googlePlayImg} style={{ width: "94px" }} />
              <img src={applePlayImg} style={{ width: "80px" }} />
            </div> */}
          </div>
        </div>
      </div>

      <div className="mobileFooterContainer">
        <div style={{ gap: "0px" }} className="mobileFooterContainerDiv1">
          <div style={{ width: "46%" }}>
            <h4 className="mobileFooterContainerDiv1-h">Our Services</h4>
            <div>
              {ourServicesLabels.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item.link)}
                    className="mobileFooterContainerDiv1-p"
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>

          <div style={{ width: "48%" }}>
            <div className="mobileFooterContainerDiv2-div2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28059.941346533644!2d77.06388039805813!3d28.464706076352893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18dc634e8939%3A0xfec8c235f5d1e002!2sHeka%20Healthy%20You!5e0!3m2!1sen!2sin!4v1709393596630!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                className="mobileFooterMap"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="mobileFooterAddress">
              <p className="mobileFooterAddress-p">
                DLF Phase IV, Gurugram, Haryana <br /> 122002
              </p>
              <p className="mobileFooterAddress-p">support@hekahealth.com</p>
              <p className="mobileFooterAddress-p">
                +91 8010081010, +91 8010091010
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mobileFooterContainerLst">
        <p onClick={() => handleDialog()} className="mobileFooterAddress-p">
          Subscribe
        </p>
        <p onClick={() => handlePolicy(1)} className="mobileFooterAddress-p">
          Privacy Policy
        </p>
        <p onClick={() => handlePolicy(2)} className="mobileFooterAddress-p">
          Terms of Use
        </p>
        <p onClick={() => handlePolicy(3)} className="mobileFooterAddress-p">
          Payment Policy
        </p>
        {showDialog ? (
          <>
            <div className="footerBox">
              <div className="footerBox-h">Subscribe to our Newsletter!</div>
              <p className="footerBox-t">
                From business updates to future plans to upcoming events, HHY
                newsletter <br /> has it all. Subscribe today to access this
                information and more.
              </p>
              <div className="footerBox-alignbtn">
                <input
                  value={subscribeInput.value}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your Email"
                  className="footerBox-input"
                />
                {subscribeInput.error && (
                  <p className="footerBox-inputError">{subscribeInput.error}</p>
                )}
                <button
                  onClick={() => handleSubscribe()}
                  className="footerBox-btn11"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MobileFooter;
