import { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import dayjs from "dayjs";
import img1 from "../../assets/medicalImages/Rectangle 2665.png";
import img2 from "../../assets/medicalImages/Rectangle 2665 (1).png";
import img3 from "../../assets/medicalImages/Rectangle 2665 (2).png";
import img4 from "../../assets/medicalImages/Rectangle 2665 (3).png";
import img5 from "../../assets/medicalImages/Rectangle 2665 (4).png";
import img6 from "../../assets/medicalImages/Rectangle 2665 (5).png";
import img7 from "../../assets/medicalImages/Rectangle 2665 (6).png";
import img8 from "../../assets/medicalImages/Rectangle 2665 (7).png";
import img9 from "../../assets/medicalImages/Frame 427319362.svg";
import img10 from "../../assets/medicalImages/Group 35642.svg";
import img11 from "../../assets/medicalImages/Frame 427319363.svg";
import img12 from "../../assets/medicalImages/Frame 427319366.svg";
import img13 from "../../assets/medicalImages/Frame 427319365.svg";
import img14 from "../../assets/medicalImages/Frame 427319364.svg";
import MedicalEquipmentPage from "../../components/MedicalEquipmentPage/MedicalEquipmentPage";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import testimonialsImage from "../../assets/medical.png";
import Testimonials from "../../components/Testimonials/Testimonials";

const MedicalEquipmentServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "8";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      head: "Respiratory Care",
      text: "Oxygen Concentrator, Nebulizer, Steamer, CPAP, BiPAP Machine, Pulse Oxymeter",
    },
    {
      arrImg: img2,
      head: "Orthopedics Care",
      text: "Heating Pad/Belt, L S Belt, Knee Cap, Shoulder Arm Support, Recliner",
    },

    {
      arrImg: img3,
      head: "Sleep Care",
      text: "CPAP Machines, Positional Sleep Aids, Sleep Pillows, Humidifiers, Oral Appliances",
    },
    {
      arrImg: img4,
      head: "Wellness & Care",
      text: "Digital B P Monitor, Glucometer, Face Mask, Adult Diapers, Executive Bank Reset",
    },
    {
      arrImg: img5,
      head: "Hospital Beds",
      text: "High-quality hospital beds offered by reputed brands",
    },
    {
      arrImg: img6,
      head: "Air mattresses",
      text: "Mattresses designed specially for bedridden patients",
    },
    {
      arrImg: img7,
      head: "Mobility",
      text: "Wheelchair, Monopod Stick, Quadripod Stick, Walkers, Under Arm Crutches",
    },
    {
      arrImg: img8,
      head: "bathroom Accessories",
      text: "Commode Chair, Toilet Raiser, Urinal Pot, Bed Pan, Shower Chair",
    },
  ];

  const arr1 = [
    {
      arrImg: img9,
      head: "Buy Back Guarantee*",
      text: "We ensure investment security, offering reimbursement for returned equipment within specified terms for your purchase.",
    },
    {
      arrImg: img10,
      head: "Hassle Free Delivery",
      text: "Experience hassle-free doorstep delivery with our team handling transportation and setup. Enjoy convenience and peace of mind.",
    },

    {
      arrImg: img11,
      head: "Quality Assured",
      text: "Trust in quality-assured equipment, selected to meet industry standards, ensuring reliability, durability, and optimal performance.",
    },
    {
      arrImg: img12,
      head: "Free installation",
      text: "Complementary installation by trained professionals, ensuring proper setup and functionality. Transportation costs apply.",
    },
    {
      arrImg: img13,
      head: "24/7 Support*",
      text: "Our medical equipment comes with round-the-clock support, ensuring reliability and peace of mind for healthcare professionals.",
    },
    {
      arrImg: img14,
      head: "Cost effective",
      text: "We offer cost-effective solutions without compromising quality, enabling efficient healthcare delivery within budget constraints.",
    },
  ];

  const dropDownArr = [
    {
      label: "Do you rent out equipment or is it only for purchase?",
      text: "We offer both rental and purchase options to cater to your preferences and needs. For longer durations, we provide buy-back options for purchased equipment.",
    },
    {
      label: "How soon can I receive the product after ordering?",
      text: "For your convenience, we offer same-day delivery within Delhi/NCR if orders are confirmed before 2 PM*, ensuring you get the equipment you need promptly.",
    },
    {
      label: "What cleaning standards are in place for rented equipment?",
      text: "Equipment undergoes thorough cleaning and disinfection processes before each rental, accompanied by comprehensive service and safety checks to ensure your safety and satisfaction.",
    },
    {
      label: "Can I extend my rental period?",
      text: "Yes, your rental automatically renews on a monthly basis for convenience. Simply notify us at least two business days before your intended return date to make arrangements.",
    },
    {
      label: "What payment methods do you accept?",
      text: "We accept various payment methods including cash, cheque, NEFT, UPI, and other online banking options for your convenience and preference.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <MedicalEquipmentPage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />

      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default MedicalEquipmentServiceContainer;
