import React from "react";
import img2 from "../../assets/generalWellnessImages/geenral wellness bg.jpg";
import "./GeneralWellnessServicePage.css";
import img3 from "../../assets/generalWellnessImages/general wellness services.svg";
import whyChooseUsImg from "../../assets/generalWellnessImages/whyChooseUsImg.svg";
import whyChooseUsImg1 from "../../assets/generalWellnessImages/whyChooseUsImg1.svg";
import mobileImgMain from "../../assets/generalWellnessImages/Frame 427319566.jpg";
import img4 from "../../assets/generalWellnessImages/our services.svg";
const GeneralWellnessServicePage = (props) => {
  const { isMobile, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              General Wellness
            </h1>
            <p className="consultationPageContainer1Content-text">
              General wellness encompasses the holistic <br /> approach to
              health and well-being, focusing on <br /> maintaining and
              enhancing overall health.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              General Wellness
            </h1>
            <p className="consultationPageContainer1Content-text">
              General wellness encompasses the holistic approach to health and
              well-being, <br /> focusing on maintaining and enhancing overall
              physical, mental, and emotional health.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img src={isMobile ? whyChooseUsImg1 : whyChooseUsImg} />

      <h4
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMain"
      >
        Our Services
      </h4>

      <div className="generalWellnessPageContainer3">
        <img src={isMobile ? img4 : img3} />
      </div>
    </>
  );
};

export default GeneralWellnessServicePage;
