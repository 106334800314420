import React from "react";
import "./BlogPage.css";

const BlogPage = (props) => {
  const { blogs, handleClick, isMobile } = props;
  return (
    <>
      <div className="blogPageContainer">
        <div
          onClick={() => handleClick(blogs[0]?.blogId)}
          className="blogPageContainer-divL"
        >
          <img
            className="blog-img"
            src={blogs[0]?.primaryImage}
            alt="blogImage"
          />
          <div className="blogPageContainer-divL-content">
            <button className="blog-Btn">{blogs[0]?.categoryOfBlog}</button>
            <h4
              style={isMobile ? { color: "#fff" } : {}}
              className="blog-content"
            >
              {blogs[0]?.blogHeading}
            </h4>
            <p
              style={isMobile ? { color: "#fff" } : {}}
              className="blog-content1"
            >
              {blogs[0]?.dateofPosting} | {blogs[0]?.readingTime} min read
            </p>
          </div>
        </div>
        <div className="blogPageContainer-divR">
          <div className="blogPageContainer-divR1">
            <div
              onClick={() => handleClick(blogs[1]?.blogId)}
              className="blogPageContainer-divR-divs"
            >
              <img
                className="blog-img"
                src={blogs[1]?.primaryImage}
                alt="blogImage"
              />
              <div className="blogPageContainer-divR-content">
                <button className="blog-BtnR">
                  {blogs[1]?.categoryOfBlog}
                </button>
                <h4 className="blog-contentR">{blogs[1]?.blogHeading}</h4>
                <p className="blog-content1R">
                  {blogs[1]?.dateofPosting} | {blogs[1]?.readingTime} min read
                </p>
              </div>
            </div>
            <div
              onClick={() => handleClick(blogs[2]?.blogId)}
              className="blogPageContainer-divR-divs"
            >
              <img
                className="blog-img"
                src={blogs[2]?.primaryImage}
                alt="blogImage"
              />
              <div className="blogPageContainer-divR-content">
                <button className="blog-BtnR">
                  {blogs[2]?.categoryOfBlog}
                </button>
                <h4 className="blog-contentR">{blogs[2]?.blogHeading}</h4>
                <p className="blog-content1R">
                  {blogs[2]?.dateofPosting} | {blogs[2]?.readingTime} min read
                </p>
              </div>
            </div>
          </div>
          <div className="blogPageContainer-divR1">
            <div
              onClick={() => handleClick(blogs[3]?.blogId)}
              className="blogPageContainer-divR-divs"
            >
              <img
                className="blog-img"
                src={blogs[3]?.primaryImage}
                alt="blogImage"
              />
              <div className="blogPageContainer-divR-content">
                <button className="blog-BtnR">
                  {blogs[3]?.categoryOfBlog}
                </button>
                <h4 className="blog-contentR">{blogs[3]?.blogHeading}</h4>
                <p className="blog-content1R">
                  {blogs[3]?.dateofPosting} | {blogs[3]?.readingTime} min read
                </p>
              </div>
            </div>
            <div
              onClick={() => handleClick(blogs[4]?.blogId)}
              className="blogPageContainer-divR-divs"
            >
              <img
                className="blog-img"
                src={blogs[4]?.primaryImage}
                alt="blogImage"
              />
              <div className="blogPageContainer-divR-content">
                <button className="blog-BtnR">
                  {blogs[4]?.categoryOfBlog}
                </button>
                <h4 className="blog-contentR">{blogs[4]?.blogHeading}</h4>
                <p className="blog-content1R">
                  {blogs[4]?.dateofPosting} | {blogs[4]?.readingTime} min read
                </p>
              </div>
            </div>
          </div>
        </div>

        {isMobile ? (
          <>
            {blogs.slice(1).map((blog, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClick(blog?.blogId)}
                  className="blogMobile"
                >
                  <div className="blogMobile1">
                    <h4 className="blog-content">{blog?.blogHeading}</h4>
                    <button className="mBtnBlog">{blog?.categoryOfBlog}</button>

                    <p className="blog-content1">
                      {blog?.dateofPosting} | {blog?.readingTime} min read
                    </p>
                  </div>
                  <img src={blog?.primaryImage} className="mblog-img" />
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </div>

      {isMobile ? (
        <></>
      ) : (
        <div className="blogPageContainer2">
          {blogs.slice(5).map((blog, index) => {
            return (
              <div
                key={index}
                onClick={() => handleClick(blog?.blogId)}
                className="blogPageContainer2-div"
              >
                <img
                  className="blog-img"
                  src={blog?.primaryImage}
                  alt="blogImage"
                />
                <div className="blogPageContainer-divR-content">
                  <button className="blog-BtnR">{blog?.categoryOfBlog}</button>
                  <h4 className="blog-contentR">{blog?.blogHeading}</h4>
                  <p className="blog-content1R">
                    {blog?.dateofPosting} | {blog?.readingTime} min read
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default BlogPage;
