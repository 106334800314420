import "./ChatBotComponent.css";
import img1 from "../../assets/chatbot/chat bot.svg";
import img2 from "../../assets/chatbot/Frame.svg";
import img3 from "../../assets/chatbot/SVGRepo_iconCarrier.svg";
import img4 from "../../assets/chatbot/Group.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
const ChatBotComponent = (props) => {
  const {
    isMobile,
    showButton,
    showChat,
    handleShowChat,
    active,
    handleActive,
    arr,
    handleSelect,
    selectedArr,
    setShowChat,
  } = props;

  console.log(arr);

  const chatBoxRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (showChat) {
      if (arr.length === 2) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }

      if (arr.length > 2) {
        chatBoxRef.current.scrollTo({
          top: chatBoxRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [arr, showChat]);

  return (
    <>
      {showChat ? (
        <div className="chatBox">
          <div
            style={{
              position: "absolute",
              right: "8px",
              top: "8px",
              zIndex: 999,
            }}
            onClick={() => setShowChat(false)}
          >
            <CloseIcon htmlColor="#000" />
          </div>
          <div className="chatBox1">
            <div className="chatBox11">
              <img src={img4} style={{ width: "100%" }} />
            </div>
            <div
              className={`chatBox12 ${isScrollable ? "scrollable" : ""}`}
              ref={chatBoxRef}
            >
              <div className="chatBox121">
                <p className="chatBox121-Head">Divya</p>
                <p className="chatBox121-tes">
                  Hello! My name is Divya. <br /> How may I help you today?
                </p>
              </div>

              <div className="chatBox121 chatBox121-align">
                {arr.map((item, index) => {
                  return item.map((it) => {
                    return (
                      <div
                        onClick={() => handleSelect(it)}
                        key={index}
                        className={
                          it.backend === true
                            ? "chatBox121-div"
                            : "chatBox121Send"
                        }
                      >
                        {it.text}
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </div>
          <div className="chatBox2">
            <div className="chatBox21">
              <button
                onClick={handleActive}
                className={!active ? "chatBox211" : "chatBox212"}
              >
                <img src={img2} />
              </button>
              <button
                onClick={handleActive}
                className={active ? "chatBox211" : "chatBox212"}
              >
                <img src={img3} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!isMobile && (
            <div onClick={handleShowChat} className="scrollButton">
              {<img src={img1} />}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ChatBotComponent;
