import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import arrow from "./Vector 205 (Stroke).svg";
import arrow1 from "./Vector 205 (Stroke) (1).svg";
import mobileArrow from "./Vector 205.svg";
import mobileArrow1 from "./Vector 205 (1).svg";
const FaqComponent = (props) => {
  const { arr, isMobile } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleIndex = (i) => {
    setSelectedIndex(i);
  };
  return (
    <>
      <div className="homePageContainer4-headMainCont">
        <h4
          className="homePageContainer4-headMain"
          style={isMobile ? { paddingTop: "32px" } : {}}
        >
          Frequently Asked Questions
        </h4>
      </div>
      <div className="homePageContainer5">
        {arr.map((item, index) => {
          return (
            <div
              key={index}
              className={
                selectedIndex === index
                  ? "homePageContainer5-BoxBorder"
                  : "homePageContainer5-Box"
              }
            >
              <div
                onClick={() => handleIndex(index)}
                className="homePageContainer5-div"
              >
                <div className="homePageContainer5-head">{item.label}</div>
                {selectedIndex === index ? (
                  <div
                    style={
                      isMobile
                        ? {
                            backgroundColor: "#215071",
                            color: "#fff",
                            borderRadius: "100%",
                            padding: isMobile ? "0px" : "9px",
                            boxShadow: "0px 3.95px 12.63px 0px #080F340F",
                            width: "18px",
                            height: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                        : {
                            backgroundColor: "#215071",
                            color: "#fff",
                            borderRadius: "100%",
                            padding: isMobile ? "0px" : "9px",
                            boxShadow: "0px 3.95px 12.63px 0px #080F340F",
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                    }
                  >
                    <img src={isMobile ? mobileArrow1 : arrow1} />
                  </div>
                ) : (
                  <div
                    style={
                      isMobile
                        ? {
                            backgroundColor: "#fff",
                            color: "#000",
                            borderRadius: "100%",
                            padding: isMobile ? "0px" : "9px",
                            boxShadow: "0px 3.95px 12.63px 0px #080F340F",
                            width: "18px",
                            height: "18px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                        : {
                            backgroundColor: "#fff",
                            color: "#000",
                            borderRadius: "100%",
                            padding: isMobile ? "0px" : "9px",
                            boxShadow: "0px 3.95px 12.63px 0px #080F340F",
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                    }
                  >
                    <img src={isMobile ? mobileArrow : arrow} />
                  </div>
                )}
              </div>
              {selectedIndex === index && (
                <p className="homePageContainer5-text">{item.text}</p>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default FaqComponent;
