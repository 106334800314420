import React, { useEffect, useState } from "react";
import ChatBotComponent from "../../../components/ChatBotComponent/ChatBotComponent";
import { APIGetRequest, APIPostRequest } from "../../../config/HttpClient";

const ChatBotContainer = (props) => {
  const {
    handleScrolling,
    handleChatBox,
    showChat,
    setShowChat,
    handleShowChat,
    isMobile,
  } = props;
  
  const [showButton, setShowButton] = useState(false);
  const [arr, setArr] = useState([]);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setShowButton(window.scrollY > 1);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [active, setActive] = useState(false);

  const handleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    var formData = new FormData();
    formData.append("data", `{"questionId": 0}`);
    APIPostRequest(
      "/chatbot/hekaChatbot.php",
      formData,
      (response) => {
        const abcd = response?.chatbotDetails?.map((item) => {
          return { ...item, backend: true };
        });
        setArr([...arr, abcd]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const [curId, setCurId] = useState();
  const [curState, setcurState] = useState(false);

  useEffect(() => {
    var formData = new FormData();
    formData.append("data", `{"questionId": ${curId}}`);
    APIPostRequest(
      "/chatbot/hekaChatbot.php",
      formData,
      (response) => {
        const abcd = response?.chatbotDetails?.map((item) => {
          return { ...item, backend: true };
        });

        const abcdd = [
          ...abcd,
          curId !== 0 && { id: 0, text: "Go to Main Menu", backend: true },
        ];

        const bdchguf = abcdd.filter((item) => {
          if (item !== false || item !== undefined) {
            return item;
          }
        });
        setArr([...arr, bdchguf]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [curId, curState]);

  const handleSelect = (it) => {
    setCurId(it.id);

    if (it.id == "3") {
      setShowChat(false);
      handleScrolling();
    }
    setcurState(!curState);
    const { backend, ...rest } = it;

    setArr((prevArray) => {
      const updatedRest = { ...rest, backend: false };
      return [...prevArray, [updatedRest]];
    });
  };

  console.log("arr", arr);

  const handleContact = () => {};

  return (
    <>
      <ChatBotComponent
      isMobile={isMobile}
        showButton={showButton}
        showChat={showChat}
        handleShowChat={handleShowChat}
        active={active}
        handleActive={handleActive}
        arr={arr}
        handleSelect={handleSelect}
        setShowChat={setShowChat}
        
      />
    </>
  );
};

export default ChatBotContainer;
