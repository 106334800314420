import React, { createRef, useState } from "react";
import "./CareGiverAd.css";
import webImg from "../../assets/caregiverAdImages/Frame 427320456.svg";
import mobImg from "../../assets/caregiverAdImages/our approach.svg";
import img1 from "../../assets/image/Rectangle 2546.svg";
import img3 from "./Rectangle 2659.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgWebImg from "./Rectangle 2663.svg";
import bgMobImg from "./Rectangle 26631.svg";
import EastIcon from "@mui/icons-material/East";
import cardImg from "./Frame 427320609.png";
import checkboxImg from "./Frame.png";

const CareGiverAd = (props) => {
  const { isMobile, arr, bgArr } = props;
  const mySlider1 = createRef();
  const [currentSlide1, setCurrentSlide1] = useState(0);

  console.log(bgArr);

  const imagess = [
    {
      images: img1,
      heading: "Elderly Care",
      author: "Parul Singh",
      text: "All of my parents' needs were covered under one roof. It was convenient and assuring to take services from Heka.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Ajay Kumar",
      text: "Heka Health was quick to act on my needs. I was able to handpick services that were of need to me.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Akash Jain",
      text: "The caregiver was well qualified to take care of my mother. I was happy to take support of Heka Health while I was away from home.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Parul Singh",
      text: "All of my parents' needs were covered under one roof. It was convenient and assuring to take services from Heka.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Ajay Kumar",
      text: "Heka Health was quick to act on my needs. I was able to handpick services that were of need to me.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Akash Jain",
      text: "The caregiver was well qualified to take care of my mother. I was happy to take support of Heka Health while I was away from home.",
    },
  ];

  const alignImages1 = () => {
    return imagess.map((item, index) => {
      const isCenter = index === currentSlide1;
      return (
        <>
          {isMobile ? (
            <>
              <div className="homePageContainer4-mainBox">
                <img src={item.images} className="homePageContainer4-img" />
                <div className="homePageContainer4-boxA">
                  <div className="homePageContainer4-alignHead">
                    <div className="homePageContainer4-head">{item.author}</div>
                  </div>
                  <p className="homePageContainer4-text">{item.text}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              {isCenter ? (
                <>
                  <div className="homePageContainer4-mainBox">
                    <img src={item.images} className="homePageContainer4-img" />
                    <div className="homePageContainer4-boxA">
                      <div className="homePageContainer4-alignHead">
                        <div className="homePageContainer4-head">
                          {item.author}
                        </div>
                      </div>
                      <p className="homePageContainer4-text">{item.text}</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="homePageContainer4-boxU">
                  <div className="homePageContainer4-alignHead">
                    <div className="homePageContainer4-head">{item.author}</div>
                  </div>
                  <p className="homePageContainer4-text">{item.text}</p>
                </div>
              )}
            </>
          )}
        </>
      );
    });
  };

  const setting1 = {
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    // autoplay: true,
    centerMode: true,
    centerPadding: "0",
    beforeChange: (oldIndex, newIndex) => setCurrentSlide1(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext2 = () => {
    mySlider1.current.slickNext();
  };

  const handlePrev2 = () => {
    mySlider1.current.slickPrev();
  };

  return (
    <>
      <h4 className="caregive-ad-heads">Types of Caregivers</h4>

      <div style={{ position: "relative", margin: "32px 0px" }}>
        {isMobile ? (
          <React.Fragment>
            <div className="caregive-ad-box2">
              <img src={bgMobImg} alt="bgImg" style={{ width: "100%" }} />
              <div className="caregive-ad-box21">
                {bgArr.map((item, index) => {
                  return (
                    <div key={index} className="caregive-ad-box2-card">
                      <img
                        src={item.mobImg}
                        alt="card"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <div className="careGiverPageContainer2-div2">
                        <h4 className="caregive-ad-box2-head">{item.head}</h4>
                        <div className="caregive-ad-box2-card1">
                          {item.textArr.map((it) => {
                            return (
                              <div className="careGiverPageContainer2-aligncheckbox">
                                <img
                                  src={checkboxImg}
                                  alt="img"
                                  style={
                                    isMobile
                                      ? { height: "8px", width: "8px" }
                                      : {}
                                  }
                                />
                                <p className="caregive-ad-box2-text">{it}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="caregive-ad-box2">
              <img src={bgWebImg} alt="bgImg" />
              <div className="caregive-ad-box21">
                {bgArr.map((item, index) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "#0000003D",
                        borderRadius: "8px",
                      }}
                      key={index}
                      className="caregive-ad-box2-card"
                    >
                      <img
                        src={item.webImg}
                        alt="card"
                        style={{ width: "100%", height: "100%" }}
                      />
                      <div className="careGiverPageContainer2-div2">
                        <h4 className="caregive-ad-box2-head">{item.head}</h4>
                        <div className="caregive-ad-box2-card1">
                          {item.textArr.map((it) => {
                            return (
                              <div className="careGiverPageContainer2-aligncheckbox">
                                <img src={checkboxImg} alt="img" />
                                <p className="caregive-ad-box2-text">{it}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      <img src={isMobile ? mobImg : webImg} className="caregive-ad-img" />

      <h4 className="caregive-ad-heads">Why hire Caregiver from Heka?</h4>

      <div className="caregive-ad-align1">
        {arr.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className="caregive-ad-box1">
                <img src={item.refImg} className="caregive-ad-box1-image" />
                <p className="caregive-ad-box1-text">{item.label}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <h4 className="caregive-ad-heads1">Testimonials</h4>

      <div className="homePageContainer4 newpadding">
        <Slider ref={mySlider1} arrows={false} {...setting1}>
          {alignImages1()}
        </Slider>
        <div className="newDisplay homePageContainer4-arrow1">
          <EastIcon
            sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
            onClick={handlePrev2}
          />
        </div>
        <div className="newDisplay homePageContainer4-arrow2">
          <EastIcon
            sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
            onClick={handleNext2}
          />
        </div>
      </div>

      <div className="caregive-ad-footer">
        <p className="caregive-ad-box1-text1">About Heka Health</p>
        <p className="caregive-ad-box1-text2">
          At Heka Health, we are dedicated to transforming the future of
          healthcare through a unique blend of preventive and curative
          solutions. Our mission is to not only address your health concerns of
          today but also to cultivate a path towards a healthier and happier
          tomorrow. We believe in the power of mindful living and strive to
          foster this ethos in every aspect of our care.
        </p>

        <p className="caregive-ad-box1-text1">Disclaimer</p>
        <p className="caregive-ad-box1-text2">
          The information on the Heka Health website is for general
          informational purposes only and not a substitute for professional
          medical advice. <br /> Always consult with a qualified healthcare
          provider for personalized advice and treatment. Heka Healthy You is
          not responsible for any <br /> consequences arising from the use of
          the information on this site.
        </p>

        <p className="caregive-ad-box1-text1">Privacy Policy</p>
        <p className="caregive-ad-box1-text2">
          Your contact information (name and phone number) submitted will not be
          sold, shared or rented to others. We use this information to send
          updates about our company and projects and will contact you if
          requested or find it necessary. You may opt out of receiving our
          communication by calling us on any of our contact numbers or by
          sending us WhatsApp message.
        </p>
        <p className="caregive-ad-box1-text3">
          2024@HekaHealthyYouPvt.Ltd. <br />
          All Rights Reserved
        </p>
      </div>
    </>
  );
};

export default CareGiverAd;
