import React, { useContext, useState, useEffect } from "react";
import ElderlyCareServicePage from "../../components/ElderlyCareServicePage/ElderlyCareServicePage";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img1 from "../../assets/elderlyCareImages/icon1.svg";
import img2 from "../../assets/elderlyCareImages/icon2.svg";
import img3 from "../../assets/elderlyCareImages/icon3.svg";
import img4 from "../../assets/elderlyCareImages/icon4.svg";
import img5 from "../../assets/elderlyCareImages/icon5.svg";
import img6 from "../../assets/elderlyCareImages/icon6.svg";
import img7 from "../../assets/elderlyCareImages/icon7.svg";
import img8 from "../../assets/elderlyCareImages/icon8.svg";
import img9 from "../../assets/elderlyCareImages/specialised/Icon 1.svg";
import img10 from "../../assets/elderlyCareImages/specialised/Icon 2.svg";
import img11 from "../../assets/elderlyCareImages/specialised/Icon 3.svg";
import img12 from "../../assets/elderlyCareImages/specialised/Icon 5.svg";
import img13 from "../../assets/elderlyCareImages/specialised/Icon 6.svg";
import img14 from "../../assets/elderlyCareImages/specialised/Icon 7.svg";
import img15 from "../../assets/elderlyCareImages/specialised/Icon 8.svg";
import img16 from "../../assets/elderlyCareImages/specialised/Icon 9.svg";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import CareModel from "../../shared/components/CareModel/CareModel";
import Testimonials from "../../components/Testimonials/Testimonials";
import testimonialsImage from "../../assets/elderly.svg";

const ElderlyCareServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "1";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      head: "Consultations",
      text: "Meetings with healthcare professionals for health assessments and guidance.",
    },
    {
      arrImg: img2,
      head: "Health Manager",
      text: "Highly skilled professionals dedicated to offering compassionate assistance.",
    },
    {
      arrImg: img3,
      head: "Dental, Eye, derma",
      text: "Offering essential medical care for oral, visual, and skin health requirements.",
    },
    {
      arrImg: img4,
      head: "Health Profiling",
      text: "Conducting analysis of the patient's current health status.",
    },
    {
      arrImg: img5,
      head: "Monitoring Services",
      text: "Continuous tracking of health parameters including vitals.",
    },
    {
      arrImg: img6,
      head: "Emergency Services",
      text: "Immediate medical assistance including ambulance services.",
    },
    {
      arrImg: img7,
      head: "Physiotherapy",
      text: "Rehabilitation exercises for physical health improvement.",
    },
    {
      arrImg: img8,
      head: "Trained Caregivers",
      text: "Highly Skilled individuals providing assistance and support.",
    },
  ];

  const arr1 = [
    {
      arrImg: img9,
      head: "Nutrition & Swallow Therapy",
      text: "Specialized dietary plans and assistance with swallowing difficulties.",
    },
    {
      arrImg: img10,
      head: "Gait, balance & mobility training",
      text: "Exercises to enhance movement and prevent falls.",
    },

    {
      arrImg: img11,
      head: "Visual And Speech Therapy",
      text: "Techniques to improve vision and speech abilities.",
    },
    {
      arrImg: img12,
      head: "Bladder & Bowel Movement",
      text: "Management strategies for urinary and fecal incontinence.",
    },
    {
      arrImg: img13,
      head: "Respiratory Management & Training",
      text: "Techniques to improve breathing function.",
    },

    {
      arrImg: img14,
      head: "Cardiovascular Fitness",
      text: "Exercises to strengthen the heart and circulatory system.",
    },
    {
      arrImg: img15,
      head: "Skilled Caregiver",
      text: "Proficient assistance tailored to individual needs.",
    },
    {
      arrImg: img16,
      head: "Pain relief and management",
      text: "Strategies to alleviate and control discomfort.",
    },
  ];

  const handleClick = () => {
    window.scrollTo(2000, 4000);
  };

  const dropDownArr = [
    {
      label: "What are the costs associated with Elderly Care services?",
      text: "Packages for Elderly Care start at INR 299 per day. For personalized care needs, please contact us at 8010-711-010 to discuss your specific requirements.",
    },
    {
      label: "Is your caregiver prepared to handle acute emergencies at home?",
      text: "Our caregivers undergo First Aid and CPR training to manage home emergencies effectively. Your loved one's safety is our utmost priority, supported by comprehensive safety training.",
    },
    {
      label:
        "Can I customize my service plan beyond what is listed on the website?",
      text: "We understand your needs may be unique. Connect with our Health Manager at 8010-711-010 to tailor a personalized plan.",
    },
    {
      label: "How quickly can services commence?",
      text: "We strive to initiate services promptly. With a 12-hour lead time, we ensure a smooth start to prioritize your loved one's well-being and comfort.",
    },
    {
      label: "What happens if my assigned caregiver falls ill?",
      text: "In case of illness, we have trained backup caregivers ready to ensure continuity of care without interruption.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <ElderlyCareServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        handleClick={handleClick}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <CareModel isMobile={selectIsMobile(state)} />

      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />

      <FaqComponent isMobile={selectIsMobile(state)} arr={dropDownArr} />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
    </>
  );
};

export default ElderlyCareServiceContainer;
