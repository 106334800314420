import React, { useContext, useEffect } from "react";
import "./PrivacyPolicy.css";
import MobileHeaderContainer from "../../containers/MobileHeaderContainer/MobileHeaderContainer";
import HeaderContainer from "../../containers/HeaderContainer/HeaderContainer";
import { selectIsMobile } from "../../../store/global/Global.selectors";
import { Context } from "../../../store/Store";
import MobileFooterContainer from "../../containers/MobileFooterContainer/MobileFooterContainer";
import FooterContainer from "../../containers/FooterContainer/FooterContainer";

const PrivacyPolicy = (props) => {
  const { state } = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <div className="privacyPage">
        <h3>HEKA HEALTH PRIVACY POLICY</h3>
        <h5>Policy Overview</h5>
        <div>
          1. Heka Health respects your privacy and recognizes the need to
          protect the personally identifiable information (any information by
          which you can be identified, such as name, gender, email address,
          postal address, age, telephone number, etc.) you share with us.
        </div>
        <div>
          2. We would like to assure you that we follow appropriate standards
          when it comes to protecting your privacy through our different
          Channels.
        </div>
        <div>
          3. Any personally identifiable information you submit while booking
          with Heka Health will ONLY be disclosed to suppliers who are linked to
          your bookings. We will not disclose, sell, share or in any way reveal
          your information to any other third party.
        </div>
        <div>
          4. We want you to feel confident about using Heka Health’s platforms,
          so we are committed to protecting the information we collect.
        </div>
        <div>
          5. While assurance of a fail proof secure system is practically
          impossible, we are endeavouring to have appropriate administrative,
          technical, and physical security procedures to help protect the
          personal information you provide to us
        </div>
        <div>
          6. To prevent unauthorized access, maintain data accuracy, and ensure
          the correct use of information,we are endeavouring to employ
          reasonable and current Internet security methods and technologies.
        </div>
        <div>
          7. This Privacy Policy is applicable to persons who purchase / intend
          to purchase / inquire about any product(s) and / or service(s) made
          available by Heka Health ('User') through any of Heka Health's
          customer interface channels including website, mobile site, mobile app
          & offline channels including call centers.
        </div>
        <div>
          8. By using or accessing the Website or other Sales Channels, the User
          hereby agrees with the terms of this Privacy Policy and the contents
          herein.
        </div>
        <div>
          9. If you disagree with this Privacy Policy, you are requested not to
          use or access our website/platforms, continuance of use of the
          website/platform shall be at your own risk.
        </div>
        <div>
          10. This Privacy Policy does not apply to any website(s), mobile sites
          and mobile apps of third parties, even if their websites/products are
          linked to our Website.
        </div>
        <div className="bottomMargin">
          11. Users should take note that information and privacy practices of
          Heka Health’s business partners, advertisers, sponsors or other sites
          to which Heka Health provides a hyperlink(s), may be materially
          different from this Privacy Policy. Accordingly, it is recommended
          that you review the privacy statements and policies of any such third
          parties.
        </div>
        <h5>Information We Collect</h5>
        <div>
          With specific reference to booking transactions and service delivery,
          Heka Health collects the following personal sensitive information from
          you while transacting through Heka Health, non-exhaustively:
        </div>
        <div>1. Name</div>
        <div>2. Gender</div>
        <div>3. Phone Number</div>
        <div>4. Email Address</div>
        <div>5. Address</div>
        <div>6. Date of birth</div>
        <div>7. Occupation</div>
        <div>8. Designation</div>
        <div>9. Government issued Identity Proof</div>
        <div className="bottomMargin">10. Profile Picture</div>

        <h5>How the Information is used</h5>
        <div>
          Heka Health does not sell or trade upon any of the above foregoing
          information without the consent of the user or customer. The foregoing
          information collected from the users/customers/travelers is put to the
          following use:
        </div>
        <div>
          1. Customer name, address, phone number , and age are shared with
          applicable service providers like RAZORPAY SOFTWARE PRIVATE LIMITED,
          Meta Platforms, Inc., LinkedIn Corporation, X Corp., Google LLC,
          Amazon Web Services, Inc., etc., for the purpose of booking the
          services for the customer.
        </div>
        <div>
          2. Information like Credit Card Details and Net Banking Details are
          usually collected directly by the payment gateways and banks and not
          by Heka Health. These details are also shared with certain third
          parties only for the purpose of processing 'Cash Back & Discounts' and
          Chargebacks, if applicable.
        </div>
        <div>
          3. Information like Mobile no, e-mail address and postal address may
          be used for promotional purposes, unless the customer/user "opt-out"
          of such use.
        </div>
        <div className="bottomMargin">
          4. If you choose not - to share this information, you can still visit
          the Heka Health website/app, but you may be unable to avail of certain
          options & services. Continuance of use is at your own risk.
        </div>

        <h5>Cookies and Session Data</h5>
        <div>
          1. In general, you can visit the Heka Health website without telling
          us who you are or revealing any personal information about yourself.
        </div>
        <div>
          2. We track the Internet address of the domains from which people
          visit us and analyze this data for trends and statistics, but the
          individual user remains anonymous.
        </div>
        <div>
          3. Some of our web pages use "cookies" so that we can better serve you
          with customized information when you return to our site.
        </div>
        <div>
          4. Cookies are identifiers that websites send to the browser on your
          computer to facilitate your next visit to our site.
        </div>
        <div>
          5. You can set your browser to notify you when you are sent a cookie,
          giving you the option to decide whether or not to accept it.
        </div>
        <div className="bottomMargin">
          6. The information we collect and analyze is used to improve our
          service to you.
        </div>

        <h5>Automatic Logging of Session Data:</h5>
        <div>
          1. Each time you access the Website your session data gets logged.
        </div>
        <div>
          2. Session data consists of the User's IP address, operating system,
          and type of browser software being used and the activities conducted
          by the User while on the Website.
        </div>
        <div>
          3. We collect session data because it helps us analyze User's choices,
          browsing patterns including the frequency of visits and duration for
          which a User is logged on.
        </div>
        <div>
          4. It also helps us diagnose problems with our servers and lets us
          better administer our systems.
        </div>
        <div>
          5. The aforesaid information cannot identify any User personally.
        </div>
        <div className="bottomMargin">
          6. However, it is possible to determine a User's Internet Service
          Provider (ISP), and the approximate geographic location of the User's
          point of connectivity from the IP address.
        </div>

        <h5>With whom your Personal Information is Shared</h5>

        <div>
          1. With specific reference to third-party Non-Personally identifiable
          information advertising, we use third-party service providers to serve
          ads on our behalf across the Internet and sometimes on this site.
        </div>
        <div>
          2. They may collect anonymous information about your visits to our
          website, and your interaction with our products and services.
        </div>
        <div>
          3. They may also use information about your visits to this and other
          websites to target advertisements for goods and services.
        </div>
        <div>
          4. This anonymous information is collected through the use of a 'pixel
          tag' / ‘Google Analytics Tag’, which is industry standard technology
          used by most major websites.
        </div>
        <div>
          5. No personally identifiable information is collected or used in this
          process. Such third parties do not know your name, phone number,
          address, email address, or any personally identifying information
        </div>
        <div className="bottomMargin">
          Please note: Even after your account is terminated, we may retain your
          data for as long as we have a legitimate purpose to do so (and in
          accordance with applicable law), including to assist with legal
          obligations, resolve disputes, and enforce our agreements. We may
          retain and disclose such data pursuant to this Privacy Policy after
          your account has been terminated
        </div>

        <h5>Disclosure of information</h5>

        <div>
          We may, in good faith, in our sole discretion and without any notice
          to you , disclose your personal sensitive information to protect
          ourselves from any liability or fraud or similar situation, to respond
          to judicial processes, legitimate requests from any third parties,
          warrants or equivalent by law enforcement trials or authorities, to
          investigate fraud or other wrongdoing or as otherwise required or
          necessary in order to comply with applicable law or to protect our
          legitimate interests or those of users. We may also, disclose personal
          sensitive information if it is reasonably necessary for our opinion to
          protect the rights or property of Heka Health or any of its affiliates
          or any third party or to avoid injury to any person.
          <div>
            The user's personal information may be disclosed for regulatory/
            internal compliance and audit(s).
          </div>
          <div>
            Heka Health takes appropriate steps to protect the information you
            share with us. We endeavour to implement technology and security
            features and strict policy guidelines to safeguard the privacy of
            your personally identifiable information from unauthorized access
            and improper use or disclosure. Please contact us on
            tech@hekahealthyyou.com to obtain names and addresses of the
            specific entities that shall have access to your personal
            information in a given transaction.
          </div>
          <div>
            Heka Health will endeavour to enhance its security procedures as new
            technology becomes available and ensures that its security
            procedures are compliant with current applicable regulations.
          </div>
          <div>
            If our privacy policy changes in the future, it will be posted here,
            and a new effective date will be shown. You should access our
            privacy policy regularly to ensure you understand our current
            policies
          </div>
          <div className="bottomMargin">
            The updated policy stands effective as of 1 st May, 2024
          </div>
          <h5>Android & iOS App Permission</h5>
          <div>
            In order to have a better experience and to use all features in the
            app, we need below permissions to be used in our android app:
          </div>
          <div>
            1. Camera: We will not access your photos or camera without first
            getting your permission and we will never scan or import your photo
            library or camera roll. If you give us permission to access photos
            or your camera, we will only use images that you specifically choose
            to share with us.
          </div>
          <div className="bottomMargin">
            2. Location: We will gather or use the specific location of your
            mobile device (by using, for example, GPS or Bluetooth) after
            getting your explicit permission, especially for emergency services.
            And if you choose to share location information but later change
            your mind, you will always have the ability to stop sharing. Please
            note that this does not include the IP address. The permissions will
            benefit the user location-specific deals and provide you a
            personalized experience with the time zone.
          </div>
          <h5>The other information that we collect</h5>
          <div>
            Analytics Google Analytics, Appsflyer, Facebook Ads conversion
            tracking, Google Tag Manager Omniture- Cookies, Usage Data and
            various types of Data as specified in the privacy policy of the
            service.
          </div>
          <div>Location-based interactions Geographical locations</div>
          <div>
            Unique device identification This Application may track Users by
            storing a unique identifier of their device, for analytics purposes
            or for storing Users' preferences.
          </div>
        </div>
      </div>
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default PrivacyPolicy;
