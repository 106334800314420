import logo from "./Main Logo Right_Tagline.svg";
import "./AdEnquiry.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo1 from "./Frame 427320487.svg";
import bg from "./image 113.svg";
import bg1 from "./image 1131.svg";
import { useLocation } from "react-router-dom";
import { SAFETY_TRAINING_AD_PAGE } from "../../../routes/constants";
import { useState } from "react";
const AdEnquiry = (props) => {
  const {
    head,
    text,
    isMobile,
    inputRefs,
    otp,
    handleChange,
    handleKeyDown,
    formatTime,
    timeLeft,
    handleSendOtp,
    show,
    verifyOTP,
    handleMobile,
    setName,
    handleScroll,
    showButton,
    mobileNumber,
    mobileErr,
    otpErr,
    bgHeight,
    bgImg,
    checked,
    setChecked,
    handlePolicy,
  } = props;

  const location = useLocation();
  const path = location.pathname === SAFETY_TRAINING_AD_PAGE;

  return (
    <>
      <div className="adEnquiryContainer">
        <img src={isMobile ? logo1 : logo} />
        {isMobile ? null : (
          <button
            style={path ? { borderColor: "#A13E3E", color: "#A13E3E" } : {}}
            onClick={() => handleScroll()}
            className="adEnquiryContainer-btn1"
          >
            Enquire now
          </button>
        )}
      </div>

      {isMobile && showButton && (
        <div className="btnAdd">
          <button
            style={path ? { backgroundColor: "#A13E3E" } : {}}
            onClick={() => handleScroll()}
            className="adEnquiryContainer-btnMain"
          >
            Enquire now
          </button>
        </div>
      )}

      {!isMobile && showButton && (
        <div className="btnAdd1">
          <button
            style={path ? { backgroundColor: "#A13E3E" } : {}}
            onClick={() => handleScroll()}
            className="adEnquiryContainer-btnMain"
          >
            Enquire now
          </button>
        </div>
      )}

      {!isMobile ? (
        <div className="adEnquiryContainer-box">
          <div className="adEnquiryContainer-box1">
            <h1 className="adEnquiryContainer-box1Head">{head}</h1>
            <p className="adEnquiryContainer-box1Text">{text}</p>

            <div className="adEnquiryContainer-boxDiv">
              <div style={{ width: "48%" }}>
                <p className="adEnquiryContainer-boxDivHead">Full Name*</p>
                <input
                  type="text"
                  className="adEnquiryContainer-boxDivInput"
                  placeholder="John Doe"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ width: "48%" }}>
                <p className="adEnquiryContainer-boxDivHead">Mobile Number*</p>
                <div className="adEnquiryContainer-boxDivInput1Div">
                  <div className="adEnquiryContainer-boxDivInput1A">
                    <div>+91</div>
                    <ArrowDropDownIcon />
                  </div>
                  <input
                    type="tel"
                    className="adEnquiryContainer-boxDivInput1B"
                    placeholder="8900890089"
                    onChange={handleMobile}
                    maxLength={10}
                    value={mobileNumber}
                  />
                </div>
                {mobileErr && <div style={{ color: "red" }}>{mobileErr}</div>}
              </div>
            </div>

            {show ? (
              <>
                <div className="adEnquiryContainer-box2Main">
                  <p
                    style={{ textAlign: "center" }}
                    className="adEnquiryContainer-boxDivHead"
                  >
                    Submit OTP*
                  </p>

                  <div className="adEnquiryContainer-box2Align">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="tel"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        ref={inputRefs[index]}
                        className="adEnquiryContainer-otpBox"
                      />
                    ))}
                  </div>
                  {otpErr && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        padding: "16px 0px 0px 0px",
                      }}
                    >
                      {otpErr}
                    </div>
                  )}
                </div>

                <div className="adEnquiryContainer-box3Main">
                  <p style={{ margin: 0 }}>{formatTime(timeLeft)} Mins</p>
                  <p style={{ margin: 0 }}>Didn't receive the code?</p>
                  <p
                    onClick={() => handleSendOtp()}
                    style={{
                      margin: 0,
                      fontWeight: 600,
                      color: "#000000",
                      cursor: "pointer",
                    }}
                  >
                    Re-send
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
            <label style={{ marginTop: "32px" }}>
              <input
                type="checkbox"
                style={{ marginRight: "8px" }}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              By Clicking, you agree to our{" "}
              <span>
                <u onClick={() => handlePolicy(2)}>Terms & Conditions</u>
              </span>
            </label>
            <div style={{ margin: "16px 0px", width: "100%" }}>
              <button
                style={path ? { backgroundColor: "#A13E3E" } : {}}
                onClick={() => {
                  show ? verifyOTP() : handleSendOtp();
                }}
                className="adEnquiryContainer-btnMain"
              >
                {show ? <>Submit</> : <>Send OTP</>}
              </button>
            </div>
          </div>
          {isMobile ? (
            <></>
          ) : (
            <div className="adEnquiryContainer-box2">
              <img src={bgImg} />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="adEnquiryContainer-box">
            <div className="adEnquiryContainer-box1">
              <h1 className="adEnquiryContainer-box1Head">{head}</h1>
              <p className="adEnquiryContainer-box1Text">{text}</p>
            </div>
          </div>

          <div
            style={{
              position: "relative",
              zIndex: 1,
              padding: "16px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={path ? bg1 : bg} style={{ width: "100%" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={
                bgHeight
                  ? "adEnquiryContainerM-box"
                  : "adEnquiryContainerM-boxA"
              }
            >
              <div style={{ width: "100%" }}>
                <p
                  style={{ margin: "8px 0px" }}
                  className="adEnquiryContainer-boxDivHead"
                >
                  Full Name*
                </p>
                <input
                  type="text"
                  className="adEnquiryContainer-boxDivInput"
                  placeholder="John Doe"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ width: "100%", margin: "0px 0px 8px 0px" }}>
                <p
                  style={{ margin: "16px 0px 8px 0px" }}
                  className="adEnquiryContainer-boxDivHead"
                >
                  Mobile Number*
                </p>
                <div className="adEnquiryContainer-boxDivInput1Div">
                  <div className="adEnquiryContainer-boxDivInput1A">
                    <div>+91</div>
                    <ArrowDropDownIcon />
                  </div>
                  <input
                    type="tel"
                    className="adEnquiryContainer-boxDivInput1B"
                    placeholder="8900890089"
                    onChange={handleMobile}
                    maxLength={10}
                    keyboardType="numeric"
                    value={mobileNumber}
                  />
                </div>
                {mobileErr && (
                  <div
                    style={{
                      color: "red",
                      padding: "4px 0px",
                      fontSize: "10px",
                    }}
                  >
                    {mobileErr}
                  </div>
                )}
              </div>

              <label style={{ marginTop: "16px", fontSize: "12px" }}>
                <input
                  type="checkbox"
                  style={{ marginRight: "8px" }}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                By Clicking, you agree to our{" "}
                <span>
                  <u onClick={() => handlePolicy(2)}>Terms & Conditions</u>
                </span>
              </label>

              {show ? (
                <>
                  <div
                    style={{ padding: "8px 0px 8px 0px" }}
                    className="adEnquiryContainer-box2Main"
                  >
                    <p className="adEnquiryContainer-boxDivHead">Submit OTP*</p>

                    <div className="adEnquiryContainer-box2Align">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          ref={inputRefs[index]}
                          className="adEnquiryContainer-otpBox"
                        />
                      ))}
                    </div>
                    {otpErr && (
                      <div
                        style={{
                          color: "red",
                          padding: "4px 0px 0px 0px",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        {otpErr}
                      </div>
                    )}
                  </div>

                  <div className="adEnquiryContainer-box3Main">
                    <p style={{ margin: "0px 0px 0px 0px" }}>
                      {formatTime(timeLeft)} Mins
                    </p>
                    <p style={{ margin: 0 }}>Didn't receive the code?</p>
                    <p
                      onClick={() => handleSendOtp()}
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        color: "#000000",
                        cursor: "pointer",
                      }}
                    >
                      Re-send
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div style={{ margin: "16px 0px", width: "100%" }}>
                <button
                  onClick={() => {
                    show ? verifyOTP() : handleSendOtp();
                  }}
                  style={path ? { backgroundColor: "#A13E3E" } : {}}
                  className="adEnquiryContainer-btnMain"
                >
                  {show ? <>Submit</> : <>Send OTP</>}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdEnquiry;
