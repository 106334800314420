import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const FailureSnackbar = (props) => {
  const { message, showFailureSnackbar, setShowFailureSnackbar } = props;

  const handleClose = (event, reason) => {
    setShowFailureSnackbar(false);
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <>
      <Snackbar open={showFailureSnackbar} autoHideDuration={1000}>
        <MuiAlert
          onClose={handleClose}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default FailureSnackbar;
