import React from "react";
import images from "../../../commons/ImagesExport";
import OurPartners from "../../../shared/components/Parteners/OurPartners";
import NewsRoom from "../../../shared/components/NewsRoom/NewsRoom";
import Testimonials from "../../../shared/components/Testimonials/Testimonials";
import Footer from "../../../shared/components/Footer/Footer";
import "./RecoveryProgram.css";
import HeaderContainer from "../../../shared/containers/HeaderContainer/HeaderContainer";
import Slider from "react-slick";
import { APIPostRequest } from "../../../config/HttpClient";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

function RecoveryProgram() {
  const [isFetching, setIsFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const classes = useStyles();
  const handleSubmit = () => {
    setIsFetching(true);
    const data = {
      name: document.getElementById("callPopUp").querySelectorAll("input")[0]
        .value,
      email: document.getElementById("callPopUp").querySelectorAll("input")[1]
        .value,
      mobileNumber: document
        .getElementById("callPopUp")
        .querySelectorAll("input")[2].value,
      categoryName: "Elderly Care",
      subCategoryName: "Recovery Program",
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    APIPostRequest(
      "/addCallSchedule.php",
      formdata,
      (res) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Your request has been submitted successfully");
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
      }
    );
  };

  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: false,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    ...settings,
    slidesToShow: 4,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <HeaderContainer />
      {isFetching && (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader} />
        </Backdrop>
      )}
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {window.scrollTo(0, 0)}
      <section className="parentalcontent">
        <div className="container">
          <div className="row paren-row">
            <div className="col-md-6">
              <div className="left-parental">
                <h4 className="parental-heading">Recovery Program</h4>
                <ul className="parental-list">
                  {[
                    "Neuro Rehabilitation program",
                    "Cardiovascular rehabilitation program",
                    "Improvise strength, balance, flexibility, cognitive function.",
                    "Recovery program - post replacement surgery, arthritis & injuries.",
                    "Strength and balance training.",
                  ].map((item, index) => {
                    return (
                      <li key={index} className="flex items-center">
                        <span>
                          <img
                            className="sign-img"
                            src={images.checkMark}
                            alt=""
                          />
                        </span>{" "}
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-parental">
                <div className="req-call-div" id="callPopUp">
                  <h6 className="call-back-heading">Let's Talk</h6>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        required
                        type="text"
                        placeholder="Name"
                        className="call-input"
                        minLength={3}
                        maxLength={26}
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                        placeholder="Email"
                        className="call-input"
                      />
                    </div>
                    <div>
                      <input
                        required
                        type="tel"
                        pattern="[0-9]{10}"
                        placeholder="Input Phone Number"
                        minLength={10}
                        maxLength={10}
                        className="call-input"
                      />
                    </div>
                    <button type="submit" className="callbackbtn">
                      Request a Call Back
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pagesupport">
        <div className="container">
          <div className=" items-center row">
            <div className="col-md-3">
              <div className="w-full left-spport">
                <img
                  src={images.recoverySupport}
                  className="xs:m-auto md:m-0 w-[60%] h-full"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="right-spport">
                <h4 class="elderphysio">Importance of Recovery</h4>
                <p>
                  Following hospital discharge, it takes a considerable time for
                  an aged person to recover and adapt to their usual routine. We
                  customize healthcare plans for long and short-term care to all
                  sorts of conditions like Stroke, spinal cord injuries, cardiac
                  issues, traumatic brain injuries, mental health disorders, and
                  hip and knee replacement issues. We track all health
                  management aspects such as vital checking, post-surgery care,
                  24/7 nursing services and emergency care, daily/routine
                  monitoring, rehabilitation/physiotherapy services, through
                  personalized nursing, medical, and psychological care, and
                  medical expert consultation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" bg-[#3f6b68] enroll-h-pragnancy">
        <div className="container" style={{ padding: "15px 0px" }}>
          <div className="flex-wrap main-health">
            <div className="xs:w-full md:w-auto">
              <div className="health-1 w-full">
                <h5>Enroll For Recovery Plan</h5>
              </div>
            </div>
            <div className="flex flex-wrap justify-around flex-1">
              {[
                {
                  image: images.reenPlan1,
                  name: "Behavioural Therapy",
                },
                {
                  image: images.reenPlan2,
                  name: "Balance Training",
                },
                {
                  image: images.reenPlan3,
                  name: "Strength Training",
                },
                {
                  image: images.reenPlan4,
                  name: "Physiotherapy",
                },
              ].map((item, index) => {
                return (
                  <div className="xs:w-full md:w-1/4" key={index}>
                    <div className="flex flex-col items-center">
                      <div className="health-preg-image">
                        <img src={item.image} alt="" />
                      </div>
                      <p className="healthdes-para">{item.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {/*planns section*/}
      <div className="my-20 flex justify-center items-center">
        <h3 className="text-3xl font-bold font-[Poppins]">OUR Plans</h3>
      </div>
      <Slider {...settings2} className="flex justify-center items-center">
        <div>
          <img src={images.replan1} className="m-auto" alt="" />
        </div>
        <div>
          <img src={images.replan2} className="m-auto" alt="" />
        </div>
        <div>
          <img src={images.replan3} className="m-auto" alt="" />
        </div>
        <div>
          <img src={images.replan4} className="m-auto" alt="" />
        </div>
        <div>
          <img src={images.replan5} className="m-auto" alt="" />
        </div>
        <div>
          <img src={images.replan6} className="m-auto" alt="" />
        </div>
      </Slider>
      <OurPartners />
      <NewsRoom
        images={[
          images.ElderNews1,
          images.ElderNews2,
          images.ElderNews3,
          images.ElderNews4,
        ]}
      />
      <Testimonials />
      <Footer />
    </>
  );
}

export default RecoveryProgram;
