import React from "react";
import FeatureBasePage from "../../shared/components/FeatureBasePage/FeatureBasePage";
import images from "../../commons/ImagesExport";
import Slider from "react-slick";
import Link from "react-router-dom/Link";

function Card(props) {
  return (
    <div className="plan-slider">
      <div className="plan-heading">
        <h4>{props.title}</h4>
      </div>
      <div className="plan-detail">
        <div className="left-detail">
          {props.bullets.map((bullet, index) => (
            <p>
              <span className="bullet" />
              {bullet}
            </p>
          ))}
        </div>
      </div>
      <Link to={`/medicalAstrology/medicalAstrologyConsultant`}>
        <button className="selecpra-button">Select Plan</button>
      </Link>
    </div>
  );
}

function ServiceInformation() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: false,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    ...settings,
    slidesToShow: 2,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings2} className="flex justify-center items-center">
      <div>
        <Card
          title="Optima Care"
          bullets={[
            "House Health Significations",
            "Planet Health Significations",
            "Sign Health Significations",
            "Prime Determinants and Stressors (afflicters) for Health",
            "Stress Factors affecting Health and longevity",
            "Weak Organs & Organ Systems",
            "Prakriti, Vikriti & Dosha Imbalances affecting health",
            "Remedies: Healthy with herbs",
          ]}
        />
      </div>
      <div>
        <Card
          title="Premium Care"
          bullets={[
            "Optima Plan inclusion",
            "Disease Timing, Intensity & Healing Process",
            "Timing to Start Medication and Surgery",
            "Mental Issues",
            "Autoimmune Issues",
            "Cancers",
            "Cardiovascular Issues",
            "Musculoskeletal Issues",
            "Remedies : Healthy with herbs & Colour therapy",
          ]}
        />
      </div>
    </Slider>
  );
}

function MedicalAstrology() {
  return (
    <FeatureBasePage
      featureImage={images.AstrologyVideo}
      name="Medical Astrology"
      intro1={images.AstrologyIntro1}
      intro2={images.AstrologyIntro2}
      intro3={images.AstrologyIntro3}
      intro4={images.AstrologyIntro4}
      intro5={images.AstrologyIntro5}
      intro6={images.AstrologyIntro6}
      intro7={images.AstrologyIntro7}
      intro8={images.AstrologyIntro8}
      introName1="Vedic medical astrology reading"
      introName2="Identifying health and longevity issues"
      introName3="Planetary influences on health"
      introName4="Harmonization functioning with color therapy"
      introName5="Experienced consultants with more than 30 + years expeience"
      introName6="Proficient forecasting of possible diseases and remedies"
      introName7="Guidance to tackle health - related situation"
      introName8="Private and confidential consultation"
      ServiceInformation={ServiceInformation()}
      support={images.AstroSupport}
      supportText="Medical astrology refers to the branch of medicine that relates the positions of planets and zodiac signs
    to body parts, body functions and diseases. It is a complex system of observation which is governed by a
    system of given rulerships. Modern medicine finds logic in the comparisons and assumptions that this ancient
    form of medicine is based on. The symbolisms used provide direct or indirect reference to the underlying
    diseases and their cures. For those wanting to consult medical astrologists, Heka Healthy You unlocks a wide
    array of trained doctors to choose from!"
      faqs={[
        {
          question: "Is medical astrology accurate ?",
          answer:
            "According to vedas, yes. Medical astrology is the study of how different Houses in a birth chart affect health. Every House has a ruling planet, and planets, as we know, have a major impact on health.",
        },
        {
          question: "Will my details and discussion be private?",
          answer:
            "Absolutely, be assured of privacy and security from our end.",
        },
        {
          question:
            "What is “ healthy with herbs’’ prediction?",
          answer:
            "This comprehensive and thought-provoking Medical Astrology explores the link between planets, human anatomy, the signs of the zodiac, and the various healing properties of plants, to provide a complete healing modality that works with the body constitution and use this information to fine-tune your herb choices to best fit their constitution. These similarities and patterns are used to understand our health and emotions and guide us in the herbs for healing.",
        },
        {
          question: "How does medical astrology works for anyone?",
          answer:
            "Principles of medical astrology are demonstrated through genuine client-charts and informative historical examples. Explanations of philosophical principles and traditional medical techniques is also explored and demonstrated in detail during the client interaction.",
        },
        {
          question: "How does color therapy help in achieving health and harmony ?",
          answer:
            "The practice has long been a component of Vedic astrology, which originates from India. With the help of the charts,one determines which colours adjust to body vibrations and frequencies, resulting in health and harmony, to heal, progress into their life’s purpose and navigate through challenges. This also implies that each zodiac sign has its own lucky colour and ratna (gemstone) as well as unfavourable colours.",
        },
      ]}
      newsRoomImages={[
        images.AstroNews1,
        images.AstroNews2,
        images.AstroNews3,
        images.AstroNews4,
        images.AstroNews5,
      ]}
    />
  );
}

export default MedicalAstrology;
