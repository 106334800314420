import React, { useEffect } from "react";
import images from "../../../commons/ImagesExport";
import HeaderContainer from '../../../shared/containers/HeaderContainer/HeaderContainer'
import "./FeatureBasePage.css";
import Footer from "../Footer/Footer";
import OurPartners from "../Parteners/OurPartners";
import NewsRoom from "../NewsRoom/NewsRoom";
import Testimonials from "../Testimonials/Testimonials";
import FAQ from "../FAQ/FAQ";
import Link from "react-router-dom/Link";
import ReactPlayer from "react-player";
import EnquiryForm from "../EnquiryForm/EnquiryForm";

function OurPlans(props) {
  return (
    <div className="col-md-4 my-6 md:my-0">
      <Link to={props.link} className=" no-underline">
        <div className="plan-main  text-black">
          <img src={props.plan} alt="" className="oplan-image" />
          <h5 className="plan-main-h5 no-underline">{props.planName}</h5>
          <p className="plan-main-p no-underline">{props.planText}</p>
        </div>
      </Link>
    </div>
  );
}

function FeatureBasePage(props) {
  const [currTab, setCurrTab] = React.useState("Introduction");
  const [newHeight, setNewHeight] = React.useState();
  const [enquiryModal, setEnquiryModal] = React.useState(false);
  useEffect(() => {
    const galleryImage = document.getElementById("video");
    if (galleryImage) {
      const width = galleryImage.offsetWidth;
      console.log(width);
      let newHeight;
      if (width >= 768) {
        newHeight = Math.round((width - width * 0.3) * (9 / 16));
      } else newHeight = Math.round(width * (9 / 16));
      setNewHeight(newHeight);
    }
  }, []);
  return (
    <>
      <HeaderContainer />
      <div className="container video-container">
        <div className="row cst-video">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="bls-video-sec">
              <div className="video-image">
                {
                  props.featureVideo ? <ReactPlayer
                  id="video"
                  url={props.featureVideo}
                  stopOnUnmount
                  playing={true}
                  autoPlay={true}
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload noplaybackrate",
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                  playIcon={false}
                  pip={false}
                  muted={true}
                  width={null}
                  height={`${newHeight}px`}
                  className={`transition_class [&>video]:rounded-2xl my-10 !h-[${newHeight}px]` }
                /> : <img src={props.featureImage} alt="" />
                }
              </div>
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
      {/*tab section of bls*/}
      <div className="container h-screen md:h-full">
        <div className="row cst-paregnacy-row">
          <div className="col-md-6">
            <div className="cst-tabmain">
              <div className="blstraing-enqi">
                <div className=" bls-sb-heading">{props.name}</div>
                <div>
                  <button onClick={()=>setEnquiryModal(true)} className="blsenquirys">Enquiry</button>
                </div>
              </div>
              <div className="tab-bar">
                <ul className="med-astroul">
                  <li
                    onClick={() => {
                      setCurrTab("Introduction");
                    }}
                    style={
                      currTab == "Introduction"
                        ? {
                            backgroundColor: "#3f6b68",
                            border: "1px solid #3f6b68",
                            color: "#fff",
                            marginTop: "0px",
                          }
                        : { marginTop: "0px" }
                    }
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      setCurrTab("Why Choose Us");
                    }}
                    style={
                      currTab == "Why Choose Us"
                        ? {
                            backgroundColor: "#3f6b68",
                            border: "1px solid #3f6b68",
                            color: "#fff",
                          }
                        : {}
                    }
                  >
                    Why Choose Us
                  </li>
                  <li
                    onClick={() => {
                      setCurrTab("Service Information");
                    }}
                    style={
                      currTab == "Service Information"
                        ? {
                            backgroundColor: "#3f6b68",
                            border: "1px solid #3f6b68",
                            color: "#fff",
                          }
                        : {}
                    }
                  >
                    Service Information
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="tab-content-right">
              <div className="show-hide">
                {currTab == "Introduction" && (
                  <div id="introductionList" className="container-val">
                    <ul className="introlist">
                      <li>
                        <div>
                          <img
                            src={props.intro1}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName1}</p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src={props.intro2}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName2}</p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src={props.intro3}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName3}</p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src={props.intro4}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName4}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {currTab == "Why Choose Us" && !props.chooseUs ? (
                  <div id="chooseUsList" className="container-val">
                    <ul className="introlist">
                      <li>
                        <div>
                          <img
                            src={props.intro5}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName5}</p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src={props.intro6}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName6}</p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <img
                            src={props.intro7}
                            alt=""
                            className="introsimage"
                          />
                        </div>
                        <div>
                          <p className="introcontents">{props.introName7} </p>
                        </div>
                      </li>
                      {props.intro8 && (
                        <li>
                          <div>
                            <img
                              src={props.intro8}
                              alt=""
                              className="introsimage"
                            />
                          </div>
                          <div>
                            <p className="introcontents">{props.introName8}</p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : (
                  currTab == "Why Choose Us" && props.chooseUs
                )}
                {currTab == "Service Information" && props.ServiceInformation}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pagesupport">
        <div className="container">
          <div className=" items-center row">
            <div className="col-md-3">
              <div className="w-full ">
                <img
                  src={props.support}
                  className="xs:m-auto md:m-0 w-[60%] h-full"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="right-spport">
                <p>{props.supportText}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {props.plan1 && (
        <section className="ourplan">
          <div className="container">
            <h4 className="op-main-heading">OUR PLANS</h4>
            <div className="row">
              <OurPlans
                plan={props.plan1}
                planName={props.planName1}
                planText={props.planText1}
                link={props.planLink1}
              />
              <OurPlans
                plan={props.plan2}
                planName={props.planName2}
                planText={props.planText2}
                link={props.planLink2}
              />
              <OurPlans
                plan={props.plan3}
                planName={props.planName3}
                planText={props.planText3}
                link={props.planLink3}
              />
            </div>
          </div>
        </section>
      )}
      {props.serviceBannerName && (
        <section className="enroll-h-pragnancy">
          <div className="container" style={{ padding: "15px 0px" }}>
            <div className="flex-wrap main-health">
              <div className="xs:w-full md:w-auto">
                <div className="health-1 w-full">
                  <h5>{props.serviceBannerName}</h5>
                </div>
              </div>
              <div className="flex flex-wrap justify-around flex-1">
                <div className="health-2">
                  <div className="health-preg-image">
                    <img src={props.serviceBannerImage1} alt="" />
                  </div>
                  <p className="healthdes-para">{props.serviceBannerText1}</p>
                </div>
                <div className="health-3">
                  <div className="health-preg-image">
                    <img src={props.serviceBannerImage2} alt="" />
                  </div>
                  <p className="healthdes-para">{props.serviceBannerText2}</p>
                </div>
                <div className="health-4">
                  <div className="health-preg-image">
                    <img src={props.serviceBannerImage3} alt="" />
                  </div>
                  <p className="healthdes-para">{props.serviceBannerText3}</p>
                </div>
                <div className="health-5">
                  <div className="health-preg-image">
                    <img src={props.serviceBannerImage4} alt="" />
                  </div>
                  <p className="healthdes-para">{props.serviceBannerText4}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {
        enquiryModal && (
          <EnquiryForm setEnquiryModal={setEnquiryModal} enquiryModal={enquiryModal} />
        )
      }
      <FAQ faqs={props.faqs} />

      <div className="container">
        <OurPartners />
      </div>
      <NewsRoom images={props.newsRoomImages} />
      <Testimonials />
      <Footer />
    </>
  );
}

export default FeatureBasePage;
