export const HOME_PAGE = "/home";
export const BLOG_PAGE = "/blogs";
export const BLOG_DETAILS_PAGE = "/blogs-details";
export const ABOUT_US_PAGE = "/about-us";
export const ELDERLY_CARE_SERVICE_PAGE = "/services-elderly-care";
export const SAFETY_TRAINING_SERVICE_PAGE = "/services-safety-training";
export const PHYSIO_THERAPY_SERVICE_PAGE = "/services-physio-therapy";
export const PREGNANCY_CARE_SERVICE_PAGE = "/services-pregnancy-care";
export const CARE_GIVER_SERVICE_PAGE = "/services-care-giver";
export const OPERATIVE_CARE_SERVICE_PAGE = "/services-operative-care";
export const MEDICAL_EQUIPMENT_SERVICE_PAGE = "/services-medical-equipments";
export const GENERAL_WELLNESS_SERVICE_PAGE = "/services-general-wellness";
export const CONSULTATION_PAGE = "/consultation-page";
export const PHYSIO_THERAPY_AD_PAGE = "/ad/physioTherapy";
export const PHYSIO_THERAPY_THANK_YOU = "/physioTherapy/thankyou";
export const SAFETY_TRAINING_AD_PAGE = "/ad/safetyTraining";
export const SAFETY_TRAINING_THANK_YOU = "/safetyTraining/thankyou";
export const CAREGIVER_AD_PAGE = "/ad/careGiver";
export const CARE_GIVER_THANK_YOU = "/careGiver/thankyou";
export const ELDERLY_CARE_PAGE = "/elderlyCare";
export const PREGNANCY_CARE_PAGE = "/pregnancyCare";
export const OPERATIVE_CARE_PAGE = "/operativeCare";
export const MEDICAL_ASTROLOGY_PAGE = "/medicalAstrology";
export const SAFETY_TRANING_PAGE = "/safetyTraining";
export const COMING_SOON_PAGE = "/comingSoon";
export const DOCTOR_CONSULTATION_PAGE = "/doctorConsultation";
export const LAB_TEST_SERVICE_PAGE = "/lab-test-page";
export const LAB_TEST_PAGE = "/labTest";
export const Comprensive_Care_Page = "/elderlyCare/comprehensiveCare";
export const CAREGIVER_PAGE = "/elderlyCare/caregiver";
export const COMPANION_CARE_PAGE = "/elderlyCare/companionCare";
export const VIRTUAL_CARE_PAGE = "/elderlyCare/virtualCare";
export const CARE_AT_HOME_PAGE = "/elderlyCare/careAtHome";
export const PHYSIO_THERAPY_PAGE = "/elderlyCare/physiotherapy";
export const RECOVERY_PROGRAM_PAGE = "/elderlyCare/recoveryProgram";
export const ROAD_SAFETY_PAGE = "/safetyTraining/roadSafety";
export const FIRST_AID_PAGE = "/safetyTraining/firstAid";
export const ELECTRICAL_SAFETY_PAGE = "/safetyTraining/electricalSafety";
export const THREE_MONTHS_PLAN_PAGE = "/pregnancyCare/threeMonthsPlan";
export const SIX_MONTHS_PLAN_PAGE = "/pregnancyCare/sixMonthsPlan";
export const NINE_MONTHS_PLAN_PAGE = "/pregnancyCare/nineMonthsPlan";
export const PARENTAL_CARE_PAGE = "/pregnancyCare/parentalCare";
export const POST_PREGNANCY_CARE_PAGE = "/pregnancyCare/postPregnancyCare";
export const CONTINUUM_CARE_PAGE = "/pregnancyCare/continuumCare";
export const MEDICAL_ASTROLOGY_CONSULTANT_PAGE =
  "/medicalAstrology/medicalAstrologyConsultant";
export const STROKE_REHABILITATION_PAGE = "/operativeCare/strokeRehabilitation";
export const CONTACT_US_PAGE = "/contactUs";
export const GALLERY_PAGE = "/gallery";
export const EVENTS_PAGE = "/events";
export const OUR_TEAM_PAGE = "/ourTeam";
export const PRIVACY_POLICY = "/privacyPolicy";
export const DELETE_ACCOUNT = "/requestAccountDeletion";
export const CAREER_PAGE = "/careers";
