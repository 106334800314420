import React from "react";
import { APIPostRequest } from "../../../config/HttpClient";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";
import images from "../../../commons/ImagesExport";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));
function CallBackPopUp(props) {
  const classes = useStyles();
  const { displayPopUp, setDisplayPopUp } = props;
  const [isFetching, setIsFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const mapping = {
    operativeCare: "Operative Care",
    pregnancyCare: "Pregnancy Care",
    elderlyCare: "Elderly Care",
    medicalAstrology: "Medical Astrology",
    safetyTraining: "Safety Training",
    doctorConsultation: "Doctor Consultation",
    labTest: "Lab Test",
    threeMonthsPlan: "Three Months Plan",
    sixMonthsPlan: "Six Months Plan",
    nineMonthsPlan: "Nine Months Plan",
    parentalCare: "Parental Care",
    postPregnancyCare: "Post Pregnancy Care",
    continuumCare: "Continuum Care",
    medicalAstrologyConsultant: "Medical Astrology Consultant",
    strokeRehabilitation: "Stroke Rehabilitation",
    roadSafety: "Road Safety",
    firstAid: "First Aid",
    electricalSafety: "Electrical Safety",
    comprehensiveCare: "Comprehensive Care",
    caregiver: "Caregiver",
    companionCare: "Companion Care",
    virtualCare: "Virtual Care",
    careAtHome: "Care At Home",
    physiotherapy: "Physiotherapy",
    recoveryProgram: "Recovery Program",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: document
        .getElementById("showcallpopup")
        .querySelectorAll("input")[0].value,
      email: document
        .getElementById("showcallpopup")
        .querySelectorAll("input")[1].value,
      mobileNumber: document
        .getElementById("showcallpopup")
        .querySelectorAll("input")[2].value,
      categoryName: mapping[window.location.href.split("/")[4]],
      subCategoryName: mapping[window.location.href.split("/")[5]],
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(data));
    APIPostRequest(
      "/addCallSchedule.php",
      formdata,
      (res) => {
        setIsFetching(false);
        setSuccess(true);
        setMessage("Your request has been submitted successfully");
        // alert("Your request has been submitted successfully");
        // setDisplayPopUp(false);
      },
      (err) => {
        setIsFetching(false);
        setError(true);
        setMessage("Something went wrong");
        // alert("Something went wrong");
      }
    );
  };

  function SlideTransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }
  function SlideTransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <div
      style={{ display: displayPopUp ? "block" : "none" }}
      className="request-call"
      id="showcallpopup"
    >
      {isFetching && (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader}  />
        </Backdrop>
      )}
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          TransitionComponent={
            success ? SlideTransitionDown : SlideTransitionUp
          }
          autoHideDuration={3000}
          onClose={() => {
            setSuccess(false);
            setDisplayPopUp(false);
            //clear all the input fields
            document
              .getElementById("showcallpopup")
              .querySelectorAll("input")[0].value = "";
            document
              .getElementById("showcallpopup")
              .querySelectorAll("input")[1].value = "";
            document
              .getElementById("showcallpopup")
              .querySelectorAll("input")[2].value = "";
          }}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          TransitionComponent={error ? SlideTransitionDown : SlideTransitionUp}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="success"
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
      <div className="sub-req-contetn">
        <div className="req-call-content">
          <div className="lets-talk">
            <h6>Let's Talk</h6>
            <button
              onClick={() => setDisplayPopUp(false)}
              className="close-bnt"
              id="showcallpopup"
            >
              X
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                required
                type="text"
                placeholder="Name"
                className="reqcall-input"
                minLength={3}
                maxLength={26}
              />
            </div>
            <div>
              <input
                required
                type="tel"
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$"
                placeholder="Email"
                className="reqcall-input"
              />
            </div>
            <div>
              <input
                required
                type="tel"
                pattern="[0-9]{10}"
                placeholder="Enter your Number"
                className="reqcall-input"
                minLength={10}
                maxLength={10}
              />
            </div>
            <div>
              <button type="submit" className="reqcall-btn">
                Request a call back{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CallBackPopUp;
