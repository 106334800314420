import get from "lodash/get";

export const selectIsMobile = (state) => {
  return get(state, "global.isMobile", false);
};
export const selectFetchingLabels = (state) => {
  return get(state, "fetchingLabels", true);
};

export const selectCity = (state) => {
  return get(state, "fetchingLabels", true);
};
