import React, { useState } from "react";
import img2 from "./caregiver Image.png";
import "./CareGiverServicePage.css";
import howItWorks from "../../assets/physiotherapyImages/How IS work.png";
import howItWorksMobile from "../../assets/physiotherapyImages/How Instant Services work mobile.png";
import img1 from "./Rectangle 2663.png";
import bg2 from "../../assets/careGiverImages/Rectangle 2663.png";
import whiteImg1 from "../../assets/physiotherapyImages/Rectangle 2586.png";
import whiteImg from "../../assets/physiotherapyImages/Rectangle 2586 (1).png";
import mobileImgMain from "./Image & BG.svg";
import checkboxImg from "./Frame.png";
const CareGiverServicePage = (props) => {
  const { isMobile, arr1, bgArr, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Caregiver At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our empathetic experts offer tailored aid, <br />
              empowering independence and nurturing well-being <br /> through
              personalised support and care.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Caregiver At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Health, our empathetic experts offer tailored aid,
              empowering <br />
              independence and nurturing well-being through personalised support
              and care.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      {/* <div
        style={{ padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
      </div> */}
      <img
        src={isMobile ? howItWorksMobile : howItWorks}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2rem auto" }
            : {
                width: "78%",
                display: "block",
                margin: " 3rem auto",
                marginTop: "5rem",
              }
        }
      />

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Types of Caregivers</h4>
      </div>
      <div className="careGiverPageContainer2">
        {isMobile ? (
          <img
            alt="images"
            src={bg2}
            style={{ width: "100%", position: "absolute", zIndex: 0 }}
          />
        ) : (
          <img
            alt="images"
            src={img1}
            style={{ width: "100%", position: "absolute", zIndex: 0 }}
          />
        )}
        <div className="careGiverPageContainer2-div">
          {bgArr.map((item) => {
            return (
              <div className="careGiverPageContainer2-div1">
                {isMobile ? (
                  <img
                    alt="images"
                    src={item.bgImage1}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <img
                    alt="images"
                    src={item.bgImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}

                <div className="careGiverPageContainer2-div2">
                  <h4 className="careGiverPageContainer2-div-h">{item.head}</h4>
                  <div className="careGiverPageContainer2-div2-div">
                    {item.textArr.map((it) => {
                      return (
                        <div className="careGiverPageContainer2-aligncheckbox">
                          <img
                            src={checkboxImg}
                            className="careGiverPageContainer2-checkbox"
                          />
                          <p className="careGiverPageContainer2-div-t2">{it}</p>
                        </div>
                      );
                    })}
                  </div>

                  <div className="careGiverPageContainer2-btn">
                    <button className="careGiverPageContainer2-btn1">
                      Starting at INR {item.price}/-
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Why hire Caregiver from us?
        </h4>
      </div>

      <div className="careGiverPageContainer3">
        {arr1.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img src={item.arrImg} className="physioPageContainer3-cardImg" />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={whiteImg}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}

                <div className="careGiverPageContainer3-card-div1">
                  <h5 className="careGiverPageContainer3-cardHead">
                    {item.head}
                  </h5>
                  <p className="careGiverPageContainer3-cardText2">
                    {item.text}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CareGiverServicePage;
