import React from "react";
import Slider from "react-slick";
import images from "../../../commons/ImagesExport";

function OurPartners() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: false,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    ...settings,
    slidesToShow: 4,
    autoplaySpeed: 2000,
  };
  return (
    <>
      <div className="my-20 flex justify-center items-center">
        <h3 className="text-3xl font-bold font-[Poppins]">OUR Collaborators</h3>
      </div>
      <Slider {...settings2} className="flex justify-center items-center">
        <div>
          <img src={images.Partners1} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners2} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners3} className="m-auto h-20" alt="" />
        </div>
        <div>
          <img src={images.Partners4} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners5} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners6} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners7} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners8} className="m-auto h-30" alt="" />
        </div>
        <div>
          <img src={images.Partners9} className="m-auto h-30" alt="" />
        </div>
      </Slider>
    </>
  );
}

export default OurPartners;
