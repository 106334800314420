import React, { useEffect, useState } from "react";
import "./MobileHeader.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import icon from "../../../assets/SVGRepo_iconCarrier.svg";
import menu from "../../../assets/homePageImages/Frame.svg";

const MobileHeader = (props) => {
  const { showCities, cityArr, handleShowCity, path, handleDrawer } = props;
  return (
    <>
      <div className="mobileheaderContainer">
        <img
          onClick={() => handleDrawer()}
          src={menu}
          className="homePageMEnuImg"
        />
        {/* {path === "/" && (
          <div onClick={handleShowCity} className="mobileHeaderBtn">
            <div>Choose your city</div>
            <ArrowDropDownIcon sx={{ fontSize: "14px" }} />
          </div>
        )} */}

        {showCities ? (
          <>
            <div className="header-cityDialog">
              {cityArr.map((item) => {
                return (
                  <div className="header-alignCity">
                    <div>{item}</div>
                    <img src={icon} style={{ height: "1rem", width: "1rem" }} />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {/* <div className="optionMHeaderContainer">
        <div className="optionMHeader">
          {options.map((option, index) => (
            <p key={index} className="optionMHeaderLabel">
              {option}
            </p>
          ))}
        </div>
      </div> */}
    </>
  );
};

export default MobileHeader;
