import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../store/Store";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import SafetyTrainingServicePage from "../../components/SafetyTrainingServicePage/SafetyTrainingServicePage";
import { selectIsMobile } from "../../store/global/Global.selectors";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import img1 from "../../assets/safetyImages/Frame 427319035.svg";
import img3 from "../../assets/safetyImages/image 146.svg";
import img4 from "../../assets/safetyImages/image 148.svg";
import img5 from "../../assets/safetyImages/image 147.svg";
import img6 from "../../assets/safetyImages/image 145.svg";
import img7 from "../../assets/safetyImages/image 149.svg";
import img8 from "../../assets/safetyImages/Group 35581.svg";
import img9 from "../../assets/safetyImages/Group 35585.svg";
import img10 from "../../assets/safetyImages/Group 35589.svg";
import img11 from "../../assets/safetyImages/icon1.svg";
import img12 from "../../assets/safetyImages/icon2.svg";
import img13 from "../../assets/safetyImages/icon3.svg";
import img14 from "../../assets/safetyImages/icon4.svg";
import img15 from "../../assets/safetyImages/icon5.svg";
import img16 from "../../assets/safetyImages/icon6.svg";
import img17 from "../../assets/safetyImages/icon7.svg";
import img18 from "../../assets/safetyImages/icon8.svg";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import testimonialsImage from "../../assets/safety.svg";
import Testimonials from "../../components/Testimonials/Testimonials";

const SafetyTrainingServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "2";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img11,
      head: "Simulated Exercises",
      text: "Engage in lifelike scenarios for immersive and effective skill enhancement and development.",
    },
    {
      arrImg: img12,
      head: "Trainer: Trainee",
      text: "The trainer to trainee ratio adhering to the standards set by the Indian Resuscitation Council (IRC).",
    },

    {
      arrImg: img13,
      head: "Quality Kits",
      text: "Elevate your training with premium-grade equipment, supplies like AED kits and mannequins.",
    },
    {
      arrImg: img14,
      head: "Certification",
      text: "Authenticate your expertise with a recognized certification that is valid for a period of 2 years.",
    },
    {
      arrImg: img15,
      head: "Multilingual",
      text: "Embrace inclusivity with training and manuals available in multiple languages.",
    },
    {
      arrImg: img16,
      head: "Information Manual",
      text: "Access a comprehensive handbook for learning support in both soft/hard copy.",
    },
    {
      arrImg: img17,
      head: "Experienced Trainers",
      text: "Learn from seasoned and certified professionals dedicated to your wellbeing & success.",
    },
    {
      arrImg: img18,
      head: "Customised Trainings",
      text: "Tailor-made programs specifically designed to meet your unique objectives and goals.",
    },
  ];

  const arr1 = [
    {
      arrImg: img1,
      text: "The bystander CPR rate in India is reported to be only 1.3%. This is far below the target goal of 62% set globally. With an estimated survival of less than 10%, OHCA (Out of Hospital Cardiac Arrests) remains one of India's leading causes of death.",
    },
    {
      arrImg: img8,
      text: "The number of road accidents in 2022 increased by 11.9% compared to 2021. Similarly, the number of deaths and injuries on account of road accidents also increased by 9.4% and 15.3% respectively.",
    },
    {
      arrImg: img9,
      text: "From 2001 to 2016, there were a total of 2347 choking deaths in children from 0 to 19 years. Children under five years of age accounted for 75% of choking fatalities.There was a decrease in the choking fatalities rate in all children (0.18/100,000 versus 0.16/100,000, respectively) but no change in fatalities rate for children under five.",
    },
    {
      arrImg: img10,
      text: "Up to 59% of pre-hospital injury deaths could be prevented with simple first aid interventions, yet despite 93% of people calling for an ambulance upon encountering an injured individual, actual first aid interventions remain infrequent.",
    },
  ];

  const arr3 = [img3, img4, img5, img6, img7];

  const dropDownArr = [
    {
      label: "Why is this training important?",
      text: "This training is crucial as it equips individuals with essential skills to potentially save lives during emergencies, irrespective of their background or profession.",
    },
    {
      label:
        "I'm not from a medical background. Why should I undergo this training?",
      text: "Regardless of your medical knowledge, this training provides life-saving skills vital in emergencies, ensuring you can confidently assist those in need.",
    },
    {
      label: "How long is the certificate valid?",
      text: "Certificates remain valid for 2 years from the training date, ensuring ongoing competency and adherence to current life-saving standards.",
    },
    {
      label: "How quickly can I learn BLS and First Aid?",
      text: "Learning Basic Life Support and First Aid is an ongoing process, but our interactive and practical training programs enable you to acquire these skills within 8 hours, empowering you to respond effectively according to standardised guidelines.",
    },
    {
      label: "What topics are covered in the training sessions?",
      text: "Training includes CPR, AED operation, relief for choking and drowning victims, and essential First Aid, equipping participants with comprehensive skills to handle common emergencies.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <SafetyTrainingServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        arr3={arr3}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />

      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default SafetyTrainingServiceContainer;
