import React from "react";
import images from "../../commons/ImagesExport";
import Footer from "../../shared/components/Footer/Footer";
import "./FirstAid.css";
import CallBackPopUp from "../../shared/components/CallBackPopUpPage/CallBackPopUp";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
function FirstAid() {
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  return (
    <div>
      <HeaderContainer />
      {window.scrollTo(0, 0)}
      <div className="container">
        <div className="spacing-firstaid">
          <h2 className="main-heading-firstaid">
            BASIC LIFE SUPPORT TRAINING &amp; FIRST AID
          </h2>
          <p className="safety-para-firstaid">
            Safety, Health and Welfare at Work, is important, as the employee’s
            and even the employer’s safety and occupational health are important
            for effective and efficient output in the organisation.
          </p>
          <p className="safety-para-firstaid">
            When an employee collapses with severe pain or is bleeding severely,
            urgent care, to preserve life, or prevent further serious injury, is
            required until a nurse, doctor, or another person, such as a trained
            emergency medical technician, can take over the situation.
          </p>
        </div>
      </div>
      <section className="aidkit-1-firstaid">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="left-spport-firstaid">
                <img src={images.lifesupport} alt="" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="right-spport-firstaid">
                <p className="safety-para-firstaid">
                  Incidents of SCA (Sudden Cardiac Arrest) are rising due to
                  Post COVID Complications, Work pressure, Sedentary Lifestyle,
                  Diabetes, Hypertension, Increased Consumption of Alcohol and
                  Smoking within the population Acc to reports, without
                  immediate treatment only 5% of people survive SCA. Survival
                  rates above 50% have been achieved in places those have
                  successfully implemented BLS (Basic Life Support) +AED
                  (Automated external Defibrillator) programs. Survival rate can
                  climb even higher when the person is treated within first few
                  minutes of cardiac arrest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aidkit-2-firstaid">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="right-spport-firstaid">
                <p className="safety-para-firstaid">
                  Thus, to provide a safer and healthier environment, the
                  knowledge and proper training of first aid is a must. The
                  Government of India has started several initiatives towards
                  making workplaces safer for workers and employees, making them
                  more employee friendly.
                </p>
                <p className="safety-para-firstaid">
                  We have aligned our vision to build a community of first
                  responders, which can access you at the time of emergency
                  through our mobile app and existing partner network, so that
                  suitable help can be made available within minutes of
                  reporting the incidence.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="left-spport-firstaid">
                <img src={images.firstAidDoc} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <h2 className="main-heading-firstaid">COMMON WORKPLACE INJURIES</h2>
        <div className="row">
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety1} alt="" />
              </div>
              <h6>Sudden Cardiac Arrests</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety2} alt="" />
              </div>
              <h6>Choking</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety3} alt="" />
              </div>
              <h6>Drowning</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety4} alt="" />
              </div>
              <h6>Burns</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety5} alt="" />
              </div>
              <h6>Slips &amp; Strains</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety6} alt="" />
              </div>
              <h6>Fractures</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety7} alt="" />
              </div>
              <h6>Heat Exhaustion</h6>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="engage-main-firstaid">
              <div className="engageimg-div-firstaid">
                <img src={images.roadSafety8} alt="" />
              </div>
              <h6>Allergic Reactions</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="main-heading-firstaid">WHY TRAINING IS IMPORTANT</h2>
        <div className="spacing-firstaid">
          <img
            src={images.firstAidRoadMap}
            alt=""
            className="roadmap-fristkit-firstaid"
          />
        </div>
      </div>
      <div className="container">
        <div className="spacing-firstaid">
          <h2 className="main-heading-firstaid">WE TRAIN FOR THE FOLLOWING</h2>
          <h6>Emergency Care &amp; First Aid</h6>
          <ul className="roadsafety-list-firstaid">
            <li>Hands on simulation Training for First Aid</li>
            <li>Basic Life Support and Trauma Care training</li>
            <li>
              Differentiate between Sudden Cardiac Arrest v/s Heart Attack
            </li>
            <li>How to handle Sudden Cardiac arrests</li>
            <li>Brain Stroke</li>
            <li>Choking and Drowning</li>
            <li>Corrective techniques of use of AED’s</li>
            <li>Use of Barrier Devices</li>
          </ul>
        </div>
        <div className="spacing-firstaid">
          <h6>Health Check-up &amp; Monitoring</h6>
          <ul className="roadsafety-list-firstaid">
            <li>Periodic occupational health screenings for the employees</li>
            <li>Health hazards assessment to job functions</li>
            <li>
              Personal health factors for execution safe job functions
              deliverables at workplace
            </li>
            <li>
              Consultation with doctors to reduce health risks and promote job
              safety, health, and fitness.
            </li>
          </ul>
        </div>
      </div>
      <section className="booknewawssion-firstaid">
        <div className="container">
          <p className="sessioncall-para-firstaid">What are you waiting for?</p>
          <h4 className="sessioncall-heading-firstaid">Book A Session Now</h4>
          <button
            onClick={() => setDisplayPopUp(true)}
            className="sessioncall-btn-firstaid"
          >
            Schedule A Call
          </button>
        </div>
      </section>
      <Footer />
      <CallBackPopUp
        displayPopUp={displayPopUp}
        setDisplayPopUp={setDisplayPopUp}
      />
    </div>
  );
}

export default FirstAid;
