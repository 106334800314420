import {
  HOME_PAGE,
  ABOUT_US_PAGE,
  PREGNANCY_CARE_PAGE,
  OPERATIVE_CARE_PAGE,
  MEDICAL_ASTROLOGY_PAGE,
  SAFETY_TRANING_PAGE,
  COMING_SOON_PAGE,
  DOCTOR_CONSULTATION_PAGE,
  LAB_TEST_PAGE,
  Comprensive_Care_Page,
  CAREGIVER_PAGE,
  COMPANION_CARE_PAGE,
  VIRTUAL_CARE_PAGE,
  CARE_AT_HOME_PAGE,
  PHYSIO_THERAPY_PAGE,
  RECOVERY_PROGRAM_PAGE,
  ROAD_SAFETY_PAGE,
  FIRST_AID_PAGE,
  ELECTRICAL_SAFETY_PAGE,
  PARENTAL_CARE_PAGE,
  POST_PREGNANCY_CARE_PAGE,
  CONTINUUM_CARE_PAGE,
  THREE_MONTHS_PLAN_PAGE,
  SIX_MONTHS_PLAN_PAGE,
  NINE_MONTHS_PLAN_PAGE,
  MEDICAL_ASTROLOGY_CONSULTANT_PAGE,
  STROKE_REHABILITATION_PAGE,
  CONTACT_US_PAGE,
  GALLERY_PAGE,
  EVENTS_PAGE,
  OUR_TEAM_PAGE,
  BLOG_PAGE,
  BLOG_DETAILS_PAGE,
  ELDERLY_CARE_SERVICE_PAGE,
  PHYSIO_THERAPY_SERVICE_PAGE,
  PREGNANCY_CARE_SERVICE_PAGE,
  CARE_GIVER_SERVICE_PAGE,
  OPERATIVE_CARE_SERVICE_PAGE,
  MEDICAL_EQUIPMENT_SERVICE_PAGE,
  GENERAL_WELLNESS_SERVICE_PAGE,
  CONSULTATION_PAGE,
  LAB_TEST_SERVICE_PAGE,
  SAFETY_TRAINING_SERVICE_PAGE,
  CAREGIVER_AD_PAGE,
  PHYSIO_THERAPY_AD_PAGE,
  SAFETY_TRAINING_AD_PAGE,
  CARE_GIVER_THANK_YOU,
  PHYSIO_THERAPY_THANK_YOU,
  SAFETY_TRAINING_THANK_YOU,
  PRIVACY_POLICY,
  DELETE_ACCOUNT,
  CAREER_PAGE,
} from "./constants";
import PregnancyCare from "../components/PregnancyCarePage/PregnancyCare";
import OperativeCare from "../components/OperativeCarePage/OperativeCare";
import MedicalAstrology from "../components/MedicalAstrologyPage/MedicalAstrology";
import HomePageContainer from "../containers/HomePageContainer/HomePageContainer";
import SafetyTraining from "../components/SafetyTrainingPage/SafetyTraining";
import ComingSoon from "../shared/components/ComingSoonPage/ComingSoon";
import DoctorConsultation from "../components/DoctorConsultationPage/DoctorConsultation";
import LabTest from "../components/LabTestPage/LabTest";
import ComprehensiveCare from "../components/ElderlyCarePage/ComprehensiveCare";
import Caregiver from "../components/ElderlyCarePage/Caregiver";
import CompanionCare from "../components/ElderlyCarePage/CompanionCare";
import VirtualCare from "../components/ElderlyCarePage/VirtualCare";
import CareAtHome from "../components/ElderlyCarePage/CareAtHomePage/CareAtHome";
import Physiotherapy from "../components/ElderlyCarePage/PhysiotherapyPage/Physiotherapy";
import RecoveryProgram from "../components/ElderlyCarePage/RecoveryProgramPage/RecoveryProgram";
import RoadSafety from "../components/SafetyTrainingPage/RoadSafety";
import FirstAid from "../components/SafetyTrainingPage/FirstAid";
import ElectricitySafety from "../components/SafetyTrainingPage/ElectricitySafety";
import ParentalCare from "../components/PregnancyCarePage/ParentalCare";
import PostPregnancy from "../components/PregnancyCarePage/PostPregnancy";
import ContinuumCare from "../components/PregnancyCarePage/Continuum Care";
import ThreeMonthsPlan from "../components/PregnancyCarePage/ThreeMonthsPlan";
import SixMonthsPlan from "../components/PregnancyCarePage/SixMonthsPlan";
import NineMonthsPlan from "../components/PregnancyCarePage/NineMonthsPlan";
import ConsultantPage from "../components/MedicalAstrologyPage/ConsultantPage";
import StrokeRehabilitation from "../components/OperativeCarePage/StrokeRehabilitation";
import GalleryPageContainer from "../containers/GalleryPageContainer/GalleryPageContainer";
import OurTeamPageContainer from "../containers/OurTeamPageContainer";
import ContactUsPageContainer from "../containers/ContactUsPageContainer";
import EventPageContainer from "../containers/EventPageContainer";
import BlogPageContainer from "../containers/BlogPageContainer/BlogPageContainer.jsx";
import BlogDetailContainer from "../containers/BlogDetailContainer/BlogDetailContainer.jsx";
import AboutUsContainer from "../containers/AboutUsContainer/AboutUsContainer.jsx";
import ElderlyCareServiceContainer from "../containers/ElderlyCareServiceContainer/ElderlyCareServiceContainer.jsx";
import PhysiotherapyServiceContainer from "../containers/PhysiotherapyServiceContainer/PhysiotherapyServiceContainer.jsx";
import PregnancyServiceContainer from "../containers/PregnancyServiceContainer/PregnancyServiceContainer.jsx";
import CareGiverServiceContainer from "../containers/CareGiverServiceContainer/CareGiverServiceContainer.jsx";
import OperativeCareServiceContainer from "../containers/OperativeCareServiceContainer/OperativeCareServiceContainer.jsx";
import MedicalEquipmentServiceContainer from "../containers/MedicalEquipmentServiceContainer/MedicalEquipmentServiceContainer.jsx";
import GeneralWellnessServiceContainer from "../containers/GeneralWellnessServiceContainer/GeneralWellnessServiceContainer.jsx";
import ConsultationContainer from "../containers/ConsultationContainer/ConsultationContainer.jsx";
import LabTestContainer from "../containers/LabTestContainer/LabTestContainer.jsx";
import SafetyTrainingServiceContainer from "../containers/SafetyTrainingServiceContainer/SafetyTrainingServiceContainer.jsx";
import CareGiverAdContainer from "../containers/CareGiverAdContainer/CareGiverAdContainer.jsx";
import PhysiotherapyAdContainer from "../containers/PhysiotherapyAdContainer/PhysiotherapyAdContainer.jsx";
import SafetyTrainingAdContainer from "../containers/SafetyTrainingAdContainer/SafetyTrainingAdContainer.jsx";
import CareGiverThankYou from "../shared/components/CareGiverThankYou/CareGiverThankYou.jsx";
import PhysioTherapyThankYou from "../shared/components/PhysioTherapyThankYou/PhysioTherapyThankYou.jsx";
import SafetyTrainingThankYou from "../shared/components/SafetyTrainingThankYou/SafetyTrainingThankYou.jsx";
import PrivacyPolicy from "../shared/components/Policies/PrivacyPolicy.jsx";
import DeleteAccountContainer from "../containers/DeleteAccountContainer/DeleteAccountContainer.jsx";
import CareerContainer from "../containers/CareerContainer/CareerContainer.jsx";

const routeConfig = {
  routes: [
    {
      path: HOME_PAGE,
      exact: true,
      name: "Home Page",
      component: HomePageContainer,
    },
    {
      path: BLOG_PAGE,
      exact: true,
      name: "Blog Page",
      component: BlogPageContainer,
    },
    {
      path: BLOG_DETAILS_PAGE,
      exact: true,
      name: "Blog Details Page",
      component: BlogDetailContainer,
    },
    {
      path: ABOUT_US_PAGE,
      exact: true,
      name: "About Page",
      component: AboutUsContainer,
    },
    {
      path: ELDERLY_CARE_SERVICE_PAGE,
      exact: true,
      name: "Elderly Care Page",
      component: ElderlyCareServiceContainer,
    },
    {
      path: PHYSIO_THERAPY_SERVICE_PAGE,
      exact: true,
      name: "Elderly Care Page",
      component: PhysiotherapyServiceContainer,
    },
    {
      path: PREGNANCY_CARE_SERVICE_PAGE,
      exact: true,
      name: "Pregnancy Care Page",
      component: PregnancyServiceContainer,
    },
    {
      path: SAFETY_TRAINING_SERVICE_PAGE,
      exact: true,
      name: "Safety Training Page",
      component: SafetyTrainingServiceContainer,
    },
    {
      path: CARE_GIVER_SERVICE_PAGE,
      exact: true,
      name: "Pregnancy Care Page",
      component: CareGiverServiceContainer,
    },
    {
      path: OPERATIVE_CARE_SERVICE_PAGE,
      exact: true,
      name: "Operative Care Page",
      component: OperativeCareServiceContainer,
    },
    {
      path: MEDICAL_EQUIPMENT_SERVICE_PAGE,
      exact: true,
      name: "Medical Astrology Page",
      component: MedicalEquipmentServiceContainer,
    },

    {
      path: GENERAL_WELLNESS_SERVICE_PAGE,
      exact: true,
      name: "Medical Astrology Page",
      component: GeneralWellnessServiceContainer,
    },
    {
      path: CONSULTATION_PAGE,
      exact: true,
      name: "Consultation Page",
      component: ConsultationContainer,
    },
    {
      path: LAB_TEST_SERVICE_PAGE,
      exact: true,
      name: "Lab Service Test Page",
      component: LabTestContainer,
    },
    {
      path: SAFETY_TRANING_PAGE,
      exact: true,
      name: "Safety Training Page",
      component: SafetyTraining,
    },
    {
      path: COMING_SOON_PAGE,
      exact: true,
      name: "Coming Soon Page",
      component: ComingSoon,
    },
    {
      path: DOCTOR_CONSULTATION_PAGE,
      exact: true,
      name: "Doctor Consultation Page",
      component: DoctorConsultation,
    },
    {
      path: LAB_TEST_PAGE,
      exact: true,
      name: "Lab Test Page",
      component: LabTest,
    },
    {
      path: Comprensive_Care_Page,
      exact: true,
      name: "Comprensive Care Page",
      component: ComprehensiveCare,
    },
    {
      path: CAREGIVER_PAGE,
      exact: true,
      name: "Caregiver Page",
      component: Caregiver,
    },
    {
      path: COMPANION_CARE_PAGE,
      exact: true,
      name: "Companion Care Page",
      component: CompanionCare,
    },
    {
      path: VIRTUAL_CARE_PAGE,
      exact: true,
      name: "Virtual Care Page",
      component: VirtualCare,
    },
    {
      path: CARE_AT_HOME_PAGE,
      exact: true,
      name: "Care At Home Page",
      component: CareAtHome,
    },
    {
      path: PHYSIO_THERAPY_PAGE,
      exact: true,
      name: "Physio Therapy Page",
      component: Physiotherapy,
    },
    {
      path: RECOVERY_PROGRAM_PAGE,
      exact: true,
      name: "Recovery Program Page",
      component: RecoveryProgram,
    },
    {
      path: ROAD_SAFETY_PAGE,
      exact: true,
      name: "Road Safety Page",
      component: RoadSafety,
    },
    {
      path: FIRST_AID_PAGE,
      exact: true,
      name: "First Aid Page",
      component: FirstAid,
    },
    {
      path: ELECTRICAL_SAFETY_PAGE,
      exact: true,
      name: "Electrical Safety Page",
      component: ElectricitySafety,
    },
    {
      path: PARENTAL_CARE_PAGE,
      exact: true,
      name: "Parental Care Page",
      component: ParentalCare,
    },
    {
      path: POST_PREGNANCY_CARE_PAGE,
      exact: true,
      name: "Home Page",
      component: PostPregnancy,
    },
    {
      path: CONTINUUM_CARE_PAGE,
      exact: true,
      name: "Continuum Care Page",
      component: ContinuumCare,
    },
    {
      path: THREE_MONTHS_PLAN_PAGE,
      exact: true,
      name: "Three Months Plan Page",
      component: ThreeMonthsPlan,
    },
    {
      path: SIX_MONTHS_PLAN_PAGE,
      exact: true,
      name: "Six Months Plan Page",
      component: SixMonthsPlan,
    },
    {
      path: NINE_MONTHS_PLAN_PAGE,
      exact: true,
      name: "Nine Months Plan Page",
      component: NineMonthsPlan,
    },
    {
      path: MEDICAL_ASTROLOGY_CONSULTANT_PAGE,
      exact: true,
      name: "Medical Astrology Consultant Page",
      component: ConsultantPage,
    },
    {
      path: STROKE_REHABILITATION_PAGE,
      exact: true,
      name: "Stroke Rehabilitation Page",
      component: StrokeRehabilitation,
    },
    {
      path: CONTACT_US_PAGE,
      exact: true,
      name: "Contact Us Page",
      component: ContactUsPageContainer,
    },
    {
      path: GALLERY_PAGE,
      exact: true,
      name: "Gallery Page",
      component: GalleryPageContainer,
    },
    {
      path: EVENTS_PAGE,
      exact: true,
      name: "Events Page",
      component: EventPageContainer,
    },
    {
      path: OUR_TEAM_PAGE,
      exact: true,
      name: "Our Team Page",
      component: OurTeamPageContainer,
    },

    {
      path: CAREGIVER_AD_PAGE,
      exact: true,
      name: "Care Giver Ad Page",
      component: CareGiverAdContainer,
    },

    {
      path: CARE_GIVER_THANK_YOU,
      exact: true,
      name: "Care Giver Ad Page",
      component: CareGiverThankYou,
    },

    {
      path: PHYSIO_THERAPY_AD_PAGE,
      exact: true,
      name: "Physio Therapy Page",
      component: PhysiotherapyAdContainer,
    },

    {
      path: PHYSIO_THERAPY_THANK_YOU,
      exact: true,
      name: "Physio Therapy Page",
      component: PhysioTherapyThankYou,
    },

    {
      path: SAFETY_TRAINING_AD_PAGE,
      exact: true,
      name: "Safety Training Page",
      component: SafetyTrainingAdContainer,
    },

    {
      path: SAFETY_TRAINING_THANK_YOU,
      exact: true,
      name: "Safety Training Page",
      component: SafetyTrainingThankYou,
    },
    {
      path: PRIVACY_POLICY,
      exact: true,
      name: "Privacy Policy Page",
      component: PrivacyPolicy,
    },
    {
      path: DELETE_ACCOUNT,
      exact: true,
      name: "Delete Account Page",
      component: DeleteAccountContainer,
    },
    {
      path: CAREER_PAGE,
      exact: true,
      name: "Career Page",
      component: CareerContainer,
    },
  ],
};

export default routeConfig;
