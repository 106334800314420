import React from "react";
import "./Consultancy.css";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import OurPatners from "../../shared/components/Parteners/OurPartners";
import NewsRoom from "../../shared/components/NewsRoom/NewsRoom";
import Testimonials from "../../shared/components/Testimonials/Testimonials";
import images from "../../commons/ImagesExport";
import CallBackPopUp from "../../shared/components/CallBackPopUpPage/CallBackPopUp";

function ConsultantPage() {
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  return (
    <>
      <HeaderContainer />
      {window.scrollTo(0, 0)}
      <div className="container">
        <div className="spacing-astrology">
          <h2 className="main-heading-astrology">MEDICAL ASTROLOGY</h2>
          <p className="safety-para-astrology">
            The ancient science of Vedic Astrology, medical astrology is focused
            on the Physical and Mental Health related wisdom, wellbeing,
            longevity, duration of diseases and predict fatality of any
            diseases.
          </p>
        </div>
      </div>
      <section className="aidkit-2-astrology">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="right-spport-astrology">
                <p className="safety-para-astrology">
                  Each person's individual astrological birth chart has many
                  layers—from the planets and signs to the houses and
                  aspects—that helps to understand personality and life path.
                </p>
                <p className="safety-para-astrology">
                  Planets and Horoscope will help identify Chakras energy,
                  physical, mental, emotional strength, and diseases in the
                  human body. Every planet relates to respective chakras in our
                  astral body.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="left-spport-astrology">
                <img src={images.MedicalConst1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <p className="safety-para-astrology">
          Chakras indicate qualities of a person through ruling planets. If any
          planet is weak, respective chakra associated with that planet would
          also be weak. This also means that the specific chakra energy is
          debilitated; hence health issues related to that chakra are caused.
        </p>
      </div>
      <div className="container">
        <h2 className="main-heading-astrology">HOW IT HELPS</h2>
        <ul className="safety-paraul-astrology">
          <li>Tool to understand any ones physical status; </li>
          <li>
            Gain perspective on personality traits, health, external environment
            and outlook on life;{" "}
          </li>
          <li>
            Recognise the health problem areas in your body and ways to overcome
            them;
          </li>
          <li>
            This reading can help patients to understand their needs and define
            the path they need to follow to being healthy.
          </li>
        </ul>
      </div>
      <div className="container">
        <div className="spacing-astrology">
          <img
            src={images.MedicalConst2}
            alt=""
            className="roadmap-fristkitastr-astrology"
          />
        </div>
        <h2 className="main-heading-astrology">HOW IT WORKS</h2>
        <p className="safety-para-astrology">
          A natal or birth chart is mathematically calculated to determine the
          precise position of the planets at the moment of birth. While the
          planets and stars act as our guides, they are also symbols and systems
          of energy that can help us discover deeper insights about which we
          are, and how we can change and transform our lives.
        </p>
        <div className="spacing-astrology">
          <img
            src={images.MedicalConst3}
            alt=""
            className="roadmap-fristkit-astrology"
          />
        </div>
        <p className="safety-para-astrology">
          Colour is another element in our natal chart that can help bring us
          closer to wholeness. This therapy is also known as Colour Therapy. The
          astrologer determines which colour and their meanings a person may
          work with to heal, step more into their purpose and wholeness, and
          mitigate difficulties in their life. According to Vedic astrology,
          colours are assigned to the navgrahas (nine planets) in the horoscope,
          which are said to govern the entire life of an individual from birth
          till death and beyond. These planets are also reflectors of rays of
          different hues, and since each zodiac sign is ruled by a different
          planet, they work with to heal, progress into their life’s purpose and
          navigate through challenges.
        </p>
      </div>
      <section className="booknewawssion">
        <div className="container">
          <p className="sessioncall-para">What are you waiting for?</p>
          <h4 className="sessioncall-heading-astrology">
            Get to know about your health
          </h4>
          <button
            onClick={() => setDisplayPopUp(true)}
            className="sessioncall-btn"
          >
            Schedule A Call
          </button>
        </div>
      </section>
      <OurPatners />
      <NewsRoom
        images={[
          images.AstroNews1,
          images.AstroNews2,
          images.AstroNews3,
          images.AstroNews4,
          images.AstroNews5,
        ]}
      />
      <Testimonials />
      <Footer />
      <CallBackPopUp
        displayPopUp={displayPopUp}
        setDisplayPopUp={setDisplayPopUp}
      />
    </>
  );
}

export default ConsultantPage;
