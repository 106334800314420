import React from "react";
import FeatureBasePage from "../../shared/components/FeatureBasePage/FeatureBasePage";
import images from "../../commons/ImagesExport";
import Slider from "react-slick";
import "./LabTest.css";

function Sliders(images, openPopUp) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000,
    centerPadding: "15px",
    cssEase: "linear",
    arrows: true,

    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    ...settings,
    slidesToShow: 3,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings2} className="flex justify-center items-center">
      {images.map((image, index) => (
        <div key={index}>
          <img
            onClick={() => openPopUp(index)}
            src={image}
            alt="image"
            className="m-auto cursor-pointer"
          />
        </div>
      ))}
    </Slider>
  );
}

function LabTest() {
  const openPopUp = (index) => {
    document.getElementById(`showcallpopup${index}`).style.display = "block";
  };
  return (
    <>
      <FeatureBasePage
        featureImage={images.LabTestVideo}
        name="Lab Test"
        intro1={images.LabTestIntro1}
        intro2={images.LabTestIntro2}
        intro3={images.LabTestIntro3}
        intro4={images.LabTestIntro4}
        intro5={images.LabTestIntro5}
        intro6={images.LabTestIntro6}
        intro7={images.LabTestIntro7}
        intro8={images.LabTestIntro8}
        introName1="Affordable pricing across multiple diagnostics partners"
        introName2="Free sample collection"
        introName3="Timely & accurate report"
        introName4="Digital prescription & EHR available"
        introName5="Single platform for all your healthcare needs"
        introName6="NABL certified diagnostics partners"
        introName7="Trained phlebotomists, assured quality service"
        introName8="Access to largest network & multiple touchpoints"
        ServiceInformation={Sliders(
          [
            images.LabTestIntro9,
            images.LabTestIntro10,
            images.LabTestIntro11,
            images.LabTestIntro12,
          ],
          openPopUp
        )}
        support={images.LabSupport}
        supportText="Any consultation is meaningless without quick and efficient laboratory tests and results. Post
    consultation, it is important to verify the doctor's thesis and narrow down the exact cause of the ailment.
    In order to provide the most accurate diagnosis, we use state of the art equipment and techniques, along
    with proper sanitary measures to give you the most accurate results. Book a test with Heka Healthy You
    online or call us to schedule your appointment!"
        serviceBannerName="Why Us?"
        serviceBannerImage1={images.LabPlan1}
        serviceBannerImage2={images.LabPlan2}
        serviceBannerImage3={images.LabPlan3}
        serviceBannerImage4={images.LabPlan4}
        serviceBannerText1="Certified Labs"
        serviceBannerText2="Assured Quality"
        serviceBannerText3="Online Reports"
        serviceBannerText4="Safety"
        faqs={[
          {
            question:
              "What covid precautions does Heka Healthy You take for testing?",
            answer:
              "Heka Healthy You is concerned about the safety of our patients and employees. As a result, since the inception of COVID-19, we have developed a 5-step safety program to provide complete protection to all stakeholders. NABL guidelines are also followed to ensure that samples do not become contaminated during collection, transportation, or storage. Here are the safety precautions to which we adhere: Be cautious when using face masks. Hand hygiene, including hand sanitization and glove use.",
          },
          {
            question: "Are the phlebotomists adequately trained?",
            answer:
              "Heka Healthy You have a dedicated training team for technicians, and they are constantly monitored for parameters such as on-time arrival, painless collection, adherence to NABL guidelines for sample storage and transport, and customer support. We have a very strict hiring process in place to ensure that every sample collection experience is top-of-the-line.",
          },
          {
            question: "How should a person prepare for a lab test?",
            answer:
              "The requirements for various tests vary. Some tests may require you to be on an empty stomach, whereas others may require you to consume food before the sample is collected. Consult your doctor about what you should do to prepare for a lab test. Inform your doctor if you are currently taking any medications.",
          },
          {
            question: "How long does it take to receive the report?",
            answer:
              "It takes the lab 24-48 hours to prepare your report and deliver it to you.",
          },
          {
            question: "How effective is the sampling and analysis procedure?",
            answer:
              "Samples are collected with extreme caution while maintaining complete hygiene. Only completely fresh needles and collection kits are used. The sample is immediately placed in an icebox after collection to prevent degradation and bacterial buildup, which could lead to an incorrect diagnosis. The samples are barcode tagged to prevent cross-contamination, and technicians in the laboratories analyze the samples using high-end, cutting-edge technology.",
          },
          {
            question: "How are lab test results interpreted?",
            answer:
              "Our labs will provide you with detailed reports. There will be charts of reference ranges and a statement from a qualified doctor to help you understand the report. However, once you receive your report, you should consult with your doctor and ask him or her to explain it to you.",
          },
          {
            question:
              "Is it correct to assume that if lab test results are within the normal range, no illness has been detected?",
            answer:
              "It is certainly good news if your reports are within the normal range. Normal results, however, do not always provide a clean bill of health. If your symptoms persist, your doctor will recommend additional tests to determine what is causing the unusual symptoms.",
          },
        ]}
        newsRoomImages={[
          images.LabNews1,
          images.LabNews2,
          images.LabNews3,
          images.LabNews4,
          images.LabNews1,
        ]}
      />
      <div className="request-call-lab" id="showcallpopup0">
        <div className="sub-req-contetn-lab">
          <div className="req-call-content-lab">
            <div className="lets-talk-lab">
              <button
                className="close-bnt-lab"
                onClick={() =>
                  (document.getElementById("showcallpopup0").style.display =
                    "none")
                }
              >
                X
              </button>
            </div>
            <img src={images.cbcPopUp} alt="" className="popupbanner-lab" />
            <h2 className="popup-heading-lab">CBC TESTING</h2>
            <p className="popup-para-lab">
              A complete blood count (CBC) is a blood test that is used to
              assess your overall health and detect a variety of disorders such
              as anaemia, infection, and leukaemia.
            </p>
            <p className="popup-para-lab">
              A complete blood count test assesses several aspects of your
              blood, including:
            </p>
            <ul className="popup-list-lab">
              <li>Red blood cells transport oxygen.</li>
              <li>White blood cells fight infections.</li>
              <li>
                The oxygen-carrying protein in red blood cells, haemoglobin
              </li>
              <li>
                Hematocrits is the ratio of red blood cells to plasma, the fluid
                component of your blood.
              </li>
              <li>Platelets are blood clotting factors.</li>
              <li>
                A complete blood count that reveals abnormal increases or
                decreases in cell counts may indicate that you have an
                underlying medical condition that requires further evaluation.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="request-call-lab" id="showcallpopup1">
        <div className="sub-req-contetn-lab">
          <div className="req-call-content-lab">
            <div className="lets-talk-lab">
              <button
                className="close-bnt-lab"
                onClick={() =>
                  (document.getElementById("showcallpopup1").style.display =
                    "none")
                }
              >
                X
              </button>
            </div>
            <img
              src={images.bloddTestPopUp}
              alt=""
              className="popupbanner-lab"
            />
            <h2 className="popup-heading-lab">BLOOD SUGAR TESTING</h2>
            <p className="popup-para-lab">
              A blood glucose test determines the concentration of glucose
              (sugar) in your blood. A finger prick or a blood draw from your
              vein may be required for the test. Blood glucose tests are most
              commonly used by healthcare providers to screen for Type 2
              diabetes, which is a common condition.
            </p>
            <p className="popup-para-lab">
              Blood glucose tests are classified into two types:
            </p>
            <ul className="popup-list-lab">
              <li>
                A healthcare professional collects a drop of blood — usually
                from a fingertip prick — for a capillary blood glucose test.
                These tests use a test strip and a glucose metre (glucometer) to
                determine your blood sugar level in seconds.
              </li>
              <li>
                Venous (plasma) blood glucose test: A phlebotomist draws blood
                from a vein (venipuncture). Glucose tests are typically included
                as part of a blood panel, such as a basic metabolic panel. The
                samples will be sent to a lab by the provider. A medical
                laboratory scientist will prepare your samples and run the test
                on analyser machines.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="request-call-lab" id="showcallpopup2">
        <div className="sub-req-contetn-lab">
          <div className="req-call-content-lab">
            <div className="lets-talk-lab">
              <button
                className="close-bnt-lab"
                onClick={() =>
                  (document.getElementById("showcallpopup2").style.display =
                    "none")
                }
              >
                X
              </button>
            </div>
            <img
              src={images.electrolytePopUp}
              alt=""
              className="popupbanner-lab"
            />
            <h2 className="popup-heading-lab">ELECTROLYTE TESTING</h2>
            <p className="popup-para-lab">
              Electrolytes are electrically charged minerals that help regulate
              the amount of fluid in your body as well as the acid-base balance.
              They also aid in the regulation of muscle and nerve activity,
              heart rhythm, and other vital functions. A serum electrolyte test,
              also known as an electrolyte panel, is a blood test that measures
              levels of the body's main electrolytes:
            </p>
            <ul className="popup-list-lab">
              <li>
                Sodium regulates the amount of fluid in the body. It also aids
                in the proper functioning of your nerves and muscles.
              </li>
              <li>
                Chloride, which also aids in fluid control in the body. It also
                aids in the maintenance of normal blood volume and blood
                pressure.
              </li>
              <li>
                Potassium, which aids in the proper functioning of your heart
                and muscles.
              </li>
              <li>
                Bicarbonate, which aids in the maintenance of the body's
                acid-base balance. It also aids in the transport of carbon
                dioxide through the bloodstream.
              </li>
            </ul>
            <p className="popup-para-lab">
              Abnormal electrolyte levels can indicate a serious health problem,
              such as kidney disease, high blood pressure, or a life-threatening
              irregularity in heart rhythm.
            </p>
          </div>
        </div>
      </div>

      <div className="request-call-lab" id="showcallpopup3">
        <div className="sub-req-contetn-lab">
          <div className="req-call-content-lab">
            <div className="lets-talk-lab">
              <button className="close-bnt-lab" onClick={()=>document.getElementById("showcallpopup3").style.display="none"}>
                X
              </button>
            </div>
            <img
              src={images.creatinePopUp}
              alt=""
              className="popupbanner-lab"
            />
            <h2 className="popup-heading-lab">CREATINE</h2>
            <p className="popup-para-lab">
              Creatine is a molecule that the body produces from amino acids. It
              is primarily produced in the liver, with minor contributions from
              the kidneys and pancreas. Creatine, in the form of
              phosphocreatine, stores high-energy phosphate groups. These
              phosphate groups are given to ADP in order to regenerate it into
              ATP, the body's primary energy carrier. This role in energy
              production is especially important when there is a high demand for
              energy, such as intense physical or mental activity.
            </p>
            <p className="popup-para-lab">
              Creatine is found in some animal-based foods, most notably meat
              and fish. Athletes typically take it as a powder or in capsule
              form.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LabTest;
