import React, { useState } from "react";
import { Backdrop } from "@material-ui/core";
import { Oval } from "react-loader-spinner";

export const Loader = (props) => {
  const { loading } = props;
  return (
    <>
      <Backdrop open={loading} style={{ backgroundColor: "#f1f2f6" }}>
        <Oval
          height={100}
          width={100}
          color="#F26828"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#a1a1a1"
          strokeWidth={1}
          strokeWidthSecondary={1}
        />
      </Backdrop>
    </>
  );
};
