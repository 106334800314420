import React, { createRef, useContext, useEffect, useState } from "react";
import ConsultationComponent from "../../components/ConsultationComponent/ConsultationComponent";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img1 from "../../assets/consultationImages/Frame 427319441.svg";
import img2 from "../../assets/consultationImages/Frame 427319440.svg";
import img3 from "../../assets/consultationImages/Frame 427319443.svg";
import img4 from "../../assets/consultationImages/Frame 427319442.svg";
import img5 from "../../assets/consultationImages/Frame 427319444.svg";
import img6 from "../../assets/consultationImages/Frame 427319445.svg";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import testimonialsImage from "../../assets/consultationImages/Rectangle 2660.svg";
import Testimonials from "../../components/Testimonials/Testimonials";
const ConsultationContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "10";

  const arr = [
    {
      arrImg: img1,
      head: "Expertise",
      text: "Specialists provide top-notch, personalized medical care, ensuring your unique needs are met.",
    },
    {
      arrImg: img2,
      head: "Convenience",
      text: "Flexible scheduling minimizes disruption to your routine, making healthcare accessible.",
    },
    {
      arrImg: img3,
      head: "Comfort",
      text: "Consultations in familiar settings promoting open communication and peace.",
    },
    {
      arrImg: img4,
      head: "Personalized approach",
      text: "Understanding unique medical history, concerns, and preferences.",
    },
    {
      arrImg: img5,
      head: "Comprehensive Care",
      text: "Multidisciplinary collaboration ensures holistic, tailored treatment, addressing all aspects of health.",
    },
    {
      arrImg: img6,
      head: "Family-centered care",
      text: "Inclusive care involving families, fostering support, and active involvement in healing.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);

  const dropDownArr = [
    {
      label: "Why choose online consultation?",
      text: "Opt for online consultations for efficient, private, time-saving, and affordable personalised solutions provided by highly qualified doctors within 15 minutes, ensuring your healthcare needs are met conveniently.",
    },
    {
      label: "Can I get a refund if I cancel my online consultation?",
      text: "Yes, refunds are available for cancelled consultations based on the following terms: full refund if cancelled 48 hours before, half refund if cancelled between 24 to 48 hours before, and no refund if cancelled less than 24 hours before.",
    },
    {
      label: "What should I do if I miss my consultation?",
      text: "If you miss your consultation, contact us at 8010-711-010 to schedule the next available slot. Easily choose, pay, and confirm your booking without hassle.",
    },
    {
      label: "How do I schedule a video consultation appointment?",
      text: "To schedule a video consultation, simply call 8010-711-010. Our team will assist you in finding a suitable time slot, making the right choice, and promptly confirming your booking.",
    },
    {
      label: "What types of consultations are available through Heka Health?",
      text: "HH offers consultations with a range of specialists including general physicians, cardiologists, nutritionists, counsellors, and other healthcare professionals. We're dedicated to ensuring you receive personalised care tailored to your unique health concerns.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <ConsultationComponent
        isMobile={selectIsMobile(state)}
        arr={arr}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />

      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default ConsultationContainer;
