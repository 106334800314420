import {
  FETCH_LABELS,
  RESET_STORE,
  SET_CITY,
  SET_LABELS,
  SET_MOBILE_DEVICE,
} from "./Global.constants";

export const initialState = {
  labels: {},
  fetchingLabels: true,
};

export const rootReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_STORE:
      return { global: state.global };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LABELS:
      return { ...state, fetchingLabels: true };
    case SET_LABELS:
      return { ...state, labels: action.payload.labels, fetchingLabels: false };
    case SET_MOBILE_DEVICE:
      return { ...state, isMobile: action.payload };
    case SET_CITY:
      return { ...state, selectedCity: action.payload };
    default:
      return state;
  }
};
