import React, { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import LabTestComponent from "../../components/LabTestComponent/LabTestComponent";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img1 from "../../assets/labtestImages/Frame 427319446.svg";
import img2 from "../../assets/labtestImages/Frame 427319447.svg";
import img5 from "../../assets/labtestImages/Frame 427319448.svg";
import img6 from "../../assets/labtestImages/Frame 427319449.svg";
import labTestImg1 from "../../assets/labtestImages/Ellipse 498.svg";
import labTestImg2 from "../../assets/labtestImages/Ellipse 497.svg";
import labTestImg3 from "../../assets/labtestImages/Ellipse 493.svg";
import labTestImg4 from "../../assets/labtestImages/Ellipse 499.svg";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import Testimonials from "../../components/Testimonials/Testimonials";
import testimonialsImage from "../../assets/labtestImages/Rectangle 2660.svg";

const LabTestContainer = () => {
  const { state } = useContext(Context);
  const pageIndex = "9";

  const arr = [
    {
      arrImg: labTestImg3,
      head: "Advance Check-up",
      text: "Total Cholesterol, HDL, VLDL, LDL Direct, Triglycerides",
      price: "499/-",
    },
    {
      arrImg: labTestImg1,
      head: "Platinum Check-up",
      text: "Total Cholesterol, HDL, VLDL, LDL Direct, Triglycerides",
      price: "899/-",
    },
    {
      arrImg: labTestImg2,
      head: "Mini Check-up",
      text: "BSF/BSR, Total Cholesterol, SGOT, SGPT, AST/ALT Ratio, TSH",
      price: "1,449/-",
    },
    {
      arrImg: labTestImg3,
      head: "Screen Check-up",
      text: "HB, PCV,TLC,RBC, MCV, MPV, MCH, MCHC, RDW, Platelet",
      price: "1,599/-",
    },

    {
      arrImg: labTestImg3,
      head: "Diabetes Active",
      text: "BSF, HBA1C,Lipid Screen ,KFT Screen ,Liver Function Test",
      price: "1,949/-",
    },

    {
      arrImg: labTestImg1,
      head: "Active Check-up",
      text: "GGT, Urea, BUN, Creatinine, BUN/Creatinine Ratio",
      price: "3,199/-",
    },
    {
      arrImg: labTestImg2,
      head: "Total Check-up",
      text: "GGT, Calcium, Phosphorus, T3, T4, Electrolytes, VitaminD 25 -Hydroxy",
      price: "3,999/-",
    },
  ];

  const arr1 = [
    {
      arrImg: img1,
      head: "Expert Guidance",
      text: "Personalised advice from experienced professionals for your health journey.",
    },
    {
      arrImg: img2,
      head: "Comfortable Experience",
      text: "Accurate testing from home for utmost convenience for all our clients.",
    },
    {
      arrImg: img1,
      head: "Timely Results",
      text: "Reliable results empowering informed health decisions for you and your family.",
    },
    {
      arrImg: img1,
      head: "Confidentiality Assured",
      text: "Priority on handling data with utmost confidentiality.",
    },
    {
      arrImg: img5,
      head: "Comprehensive Testing",
      text: "Wide range of tests for thorough understanding of well-being.",
    },
    {
      arrImg: img6,
      head: "Customer Centric Approach",
      text: "Personalised care and support for your health journey.",
    },
  ];

  const dropDownArr = [
    {
      label: "How can I book lab tests with Heka Health?",
      text: "You may fill out our enquiry form or call us at 8010-711-010 for prompt assistance.",
    },
    {
      label: "Is a doctor's prescription required?",
      text: "While not mandatory for all tests, consulting a doctor beforehand ensures optimal guidance for your health journey. For pregnancy-related tests, a valid prescription is mandatory.",
    },
    {
      label: "How will my sample be collected?",
      text: "A trained phlebotomist will collect your venous blood sample at your location with care, ensuring proper handling and accurate assessment.",
    },
    {
      label: "Can I cancel or reschedule my appointment?",
      text: "You can cancel your appointment with us. Our refund policy applies: full refund if cancelled 48 hours prior, half refund if cancelled between 24 to 48 hours prior, and no refund if cancelled less than 24 hours prior. We understand schedules can change.",
    },
    {
      label: "Is fasting required before tests?",
      text: "Fasting requirements vary depending on the specific test. Contact our team for tailored instructions based on your needs, making the process more convenient for you.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <LabTestComponent
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <Testimonials isMobile={selectIsMobile(state)} pageIndex={pageIndex} />
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default LabTestContainer;
