import React, { useContext, useEffect, useRef, useState } from "react";
import HomePage from "../../components/HomePage/template";
import { selectHomePageLabels } from "./HomePageContainer.selector";
import { makeStyles } from "@material-ui/core";
import { Context } from "../../store/Store";
import elderlyCareImg from "../../assets/homePageImages/elderly.png";
import operativeCareImg from "../../assets/homePageImages/operative.png";
import pregnancyCareImg from "../../assets/homePageImages/pregnancy.png";
import safetyImg from "../../assets/homePageImages/safety.png";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import {
  ELDERLY_CARE_SERVICE_PAGE,
  OPERATIVE_CARE_SERVICE_PAGE,
  PREGNANCY_CARE_SERVICE_PAGE,
  SAFETY_TRAINING_SERVICE_PAGE,
} from "../../routes/constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  progressbar: {
    color: theme.palette.secondary.light,
  },
}));

const HomePageContainer = () => {
  const history = useHistory();
  const { state } = useContext(Context);
  console.log(selectIsMobile(state), "selectIsMobile");
  const pageIndex = "0";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("ON HOME PAGE");

  const serviceCardsData = [
    {
      heading: "Elderly Care",
      images: elderlyCareImg,
      text: "Compassionate support tailored to the unique needs of seniors, ensuring comfort and well-being.",
      url: ELDERLY_CARE_SERVICE_PAGE,
    },
    {
      heading: "Pregnancy Care",
      images: pregnancyCareImg,
      text: "Empowering everyone with the basic knowledge and skill to respond in health emergencies.",
      url: PREGNANCY_CARE_SERVICE_PAGE,
    },
    {
      heading: "Operative Care",
      images: operativeCareImg,
      text: "Advanced treatment plans for optimal surgical outcomes and recovery.",
      url: OPERATIVE_CARE_SERVICE_PAGE,
    },
    {
      heading: "Safety Trainings",
      images: safetyImg,
      text: "Empowering everyone with the basic knowledge and skill to respond in health emergencies.",
      url: SAFETY_TRAINING_SERVICE_PAGE,
    },
  ];

  const [mobileNumber, setMobileNumber] = useState("");

  const [mobileErr, setMobileError] = useState("");

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const dropDownArr = [
    {
      label: "What does Heka Health provide?",
      text: "We offer professional home healthcare services delivered by trained experts, catering to the elderly, post-operative patients, those requiring pregnancy care, and more.",
    },
    {
      label: "How does Heka Health operate?",
      text: "We deliver patient-centered, customized, and technology-driven care, emphasizing comfort, empathy, and comprehensive medical expertise to achieve optimal outcomes.",
    },
    {
      label: "How can I access your services?",
      text: "To embark on your journey to better health, simply contact us at 8010-711-010. Our team will assist you through the entire process.",
    },
    {
      label: "Why opt for home health care?",
      text: "Home health care focuses on convenience, comfort, and personalized assistance, ensuring that your individual needs are met with compassion and proficiency.",
    },
    {
      label: "What information does Heka Health retain? Is it secure?",
      text: "We securely store essential medical information, strictly following safety protocols to guarantee confidentiality and peace of mind for all our clients.",
    },
  ];

  const handleReadMore = (item) => {
    console.log("READ MORE", item);
    history.push(item.url);
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}

      {/* {isFetching ? (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <CircularProgress className={classes.progressbar} color="primary" />
        </Backdrop>
      ) : (
       
      )} */}

      <HomePage
        isMobile={selectIsMobile(state)}
        homePageLabels={selectHomePageLabels(state)}
        serviceCardsData={serviceCardsData}
        mobileNumber={mobileNumber}
        handleMobile={handleMobile}
        mobileErr={mobileErr}
        handleReadMore={handleReadMore}
        pageIndex={pageIndex}
      />

      <FaqComponent isMobile={selectIsMobile(state)} arr={dropDownArr} />

      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />

      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
    </>
  );
};

export default HomePageContainer;
