import React from "react";
import images from "../../commons/ImagesExport";
import "./ElectricalSafety.css";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import CallBackPopUp from "../../shared/components/CallBackPopUpPage/CallBackPopUp";

function ElectricitySafety() {
  const [displayPopUp, setDisplayPopUp] = React.useState(false);
  return (
    <div>
      <HeaderContainer />
      {window.scrollTo(0, 0)}
      <div className="container">
        <div className="spacing-electrical">
          <h2 className="main-heading-electrical">ELECTRICAL SAFETY</h2>
          <p className="safety-para-electrical">
            In India, faulty electric wiring and appliances are one of the most
            common causes of fires in industrial and commercial premises,
            accounting for 5,000-6,000 (20%) of the 25,000-30,000
            non-residential accidental fires. The Central Electricity Authority
            (CIA) data attributes nearly 40% of deaths at workplaces to
            electrical issues.
          </p>
        </div>
      </div>
      <section className="aidkit-2-electrical">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="right-spport-electrical">
                <p className="safety-para-electrical">
                  Workplace accidents report nearly 13 electrocution deaths on
                  an average day in India. This is the highest in the world. The
                  annual average of electric-related deaths in the UK and the
                  United States is 8 and 82 respectively.
                </p>
                <p className="safety-para-electrical">
                  The CEA says 42% of the fires are due to faulty electrical
                  systems. Majority of the short-circuit fires are triggered by
                  improper wiring, poor electrical fittings and bad maintenance
                  of wires. Unaware practices in offices or homes can lead to
                  electric shocks, fires, burns to personnel, and damage to
                  property.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="left-spport-electrical">
                <img src={images.electricalSafety} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="spacing-electrical">
          <h2 className="main-heading-electrical">
            ELECTRICAL NEGLIGENCE CAN RESULT IN
          </h2>
          <ul className="roadsafety-list-electrical">
            <li>
              {" "}
              Electrical shock injuries occur when electric current passes
              through the body. The person will receive a shock, which can
              result in tingling sensations, muscle and nerve damage, and even
              cardiac arrest.
            </li>
            <li>
              {" "}
              Electrical burns can result in injuries that vary in severity,
              including flash burns, flame burns, as well as low and high
              voltage burns.
            </li>
            <li>
              {" "}
              Electrocution is used to describe a fatal electrical injury and
              always means that the victim passed away as a result.
            </li>
          </ul>
        </div>
      </div>
      <div className="container">
        <h2 className="main-heading-electrical">ELECTRICAL RESPONSIBILITIES</h2>
        <p className="safety-para-electrical">
          This course outlines understanding of the OSHA electrical safety
          requirements to guarantee that employees are able to carry out their
          responsibilities.
        </p>
        {/* <div class="row">
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-1.png" alt=""></div>
  <h6>Sudden Cardiac Arrests</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-2.png" alt=""></div>
  <h6>Choking</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-3.png" alt=""></div>
  <h6>Drowning</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-4.png" alt=""></div>
  <h6>Burns</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-5.png" alt=""></div>
  <h6>Slips & Strains</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-6.png" alt=""></div>
  <h6>Fractures</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-7.png" alt=""></div>
  <h6>Heat Exhaustion</h6>
</div>
    </div>
    <div class="col-sm-3">
<div class="engage-main">
  <div class="engageimg-div"><img src="../../assets/image/road-saf-8.png" alt=""></div>
  <h6>Allergic Reactions</h6>
</div>
    </div>
  </div> */}
        <div className=" electrical-photo-electrical">
          <div className=" w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe1} alt="" />
              </div>
              <h6>Basics of Electrical Safety</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe2} alt="" />
              </div>
              <h6>Electrical Shock Hazards &amp; Protection Measures</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe3} alt="" />
              </div>
              <h6>Electric Fires &amp; Prevention</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe4} alt="" />
              </div>
              <h6>Earthing &amp; Bonding</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe5} alt="" />
              </div>
              <h6>Arc Flash Hazards &amp; Mitigation Measures</h6>
            </div>
          </div>
        </div>
        <div className="electrical-photo-electrical">
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe6} alt="" />
              </div>
              <h6>Electrical PPE (Personal Protection Equipment)</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe7} alt="" />
              </div>
              <h6>Electrical Equipment in Hazardous Areas</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe8} alt="" />
              </div>
              <h6>Lightning &amp; Surge Protection</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe9} alt="" />
              </div>
              <h6>Electrical Safety Regulations in India</h6>
            </div>
          </div>
          <div className="w-full md:w-[18%] elctriccontent-electrical">
            <div className="engage-main-electrical">
              <div className="engageimg-div-electrical">
                <img src={images.electricalSafe10} alt="" />
              </div>
              <h6>Implementing Electrical Safety Program in Industries</h6>
            </div>
          </div>
        </div>
      </div>
      <section className="booknewawssion">
        <div className="container">
          <p className="sessioncall-para">What are you waiting for?</p>
          <h4 className="sessioncall-heading">Book A Session Now</h4>
          <button onClick={()=>setDisplayPopUp(true)} className="sessioncall-btn">Schedule A Call</button>
        </div>
      </section>
      <Footer />
      <CallBackPopUp displayPopUp={displayPopUp} setDisplayPopUp={setDisplayPopUp} />
    </div>
  );
}

export default ElectricitySafety;
