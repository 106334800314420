import React, { createRef } from "react";
import "./CareModel.css";
import img1 from "./Frame 427321022.png";
import img2 from "./Frame 427321021.png";
import img3 from "./Frame 427321023.png";
import img4 from "./Frame 427321024.png";
import img5 from "./Frame 427321031.png";
import img6 from "./Frame 427321030.png";
import img7 from "./Frame 427321029.png";
import img8 from "./Frame 427321028.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const CareModel = (props) => {
  const { isMobile } = props;
  const mySlider = createRef();
  const arr = [
    {
      refImg: img4,
      refMImg: img7,
      head: "Security",
      text: "Ensuring safety and an environment conducive to prevention and recovery is our goal.",
    },
    {
      refImg: img3,
      refMImg: img8,
      head: "Service",
      text: "Our best-trained caregivers meet all patient needs with expertise & compassion.",
    },
    {
      refImg: img2,
      refMImg: img6,
      head: "Engagement",
      text: "Surrounding you with like-minded individuals for sharing experiences and advice to feel heard.",
    },
    {
      refImg: img1,
      refMImg: img5,
      head: "Technology",
      text: "Integrated features and devices at HHY keep you informed about your health constantly.",
    },
  ];

  const setting = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 3000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext1 = () => {
    mySlider.current.slickNext();
  };

  const handlePrev1 = () => {
    mySlider.current.slickPrev();
  };

  return (
    <>
      {isMobile ? (
        <div className="careModelMain">
          <h1 className="careModelMainHead">Our Care Model</h1>
          <p className="careModelMainText">
            We offer all answers to home health care <br /> within one platform,{" "}
            <br />
            eliminating the need to go elsewhere.
          </p>
        </div>
      ) : (
        <div className="careModelMain">
          <h1 className="careModelMainHead">Our Care Model</h1>
          <p className="careModelMainText">
            We offer all answers to home health care within one platform, <br />
            eliminating the need to go elsewhere.
          </p>
        </div>
      )}
      <div className="careModelContainer">
        <Slider ref={mySlider} arrows={false} {...setting}>
          {arr.map((item) => {
            return (
              <div className="careModelContainer1">
                <img src={isMobile ? item.refMImg : item.refImg} />
                <div className="careModelAlign">
                  <div className="careModelHead">{item.head}</div>
                  <div className="careModelText">{item.text}</div>
                </div>
              </div>
            );
          })}
        </Slider>
        {isMobile ? (
          <></>
        ) : (
          <>
            <div style={{ left: "3rem" }} className="homePageContainer2-arrow1">
              <ArrowRightAltIcon onClick={handlePrev1} />
            </div>
            <div
              style={{ right: "3rem" }}
              className="homePageContainer2-arrow2"
            >
              <ArrowRightAltIcon onClick={handleNext1} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CareModel;
